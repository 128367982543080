import React from "react";
import LeftBar from "./LeftBar";
import EditProfile from "./EditProfile";
import { Route, Routes } from "react-router-dom";
import { LeftBarArray } from "../../../../ExportFiles";

const Account = () => {
  return (
    <div class="container dashboard_container" id="dashboard">
      <div class="row">
        <div class="col-xs-12 no-outer-gutter-xs">
          <div class="nav_container nav_stacked">
            <div class="row">
              <LeftBar />

              <Routes>
                {LeftBarArray.map((val) => (
                  <Route path={val.path} element={val.component} />
                ))}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
