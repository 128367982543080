import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import "./reservation.css";
const Cancelation = () => {
  const { setSelectDataCaseThree, selectDataCaseThree, rentYourCar } =
    useContext(DataContext);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Cancellations conditions</div>
        <div className="">
          <div
            className="cobalt-CheckmarkField"
            data-form-checkmark-method="is_pro"
          >
            <div>
              <div className="discount-option">
                <input
                  type="radio"
                  name="cancelation"
                  checked={selectDataCaseThree.cancelation?.includes("1")}
                  value="1"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setSelectDataCaseThree,
                      selectDataCaseThree,
                      "caseThree"
                    )
                  }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">Flexible</span> */}
                  <div className="discount-info">
                    <strong>Flexible</strong>
                    <p>
                      Vehicle renters will receive a full refund if cancelled up
                      to one day before the reservation.
                    </p>
                  </div>
                </div>
              </div>

              <div className="discount-option">
                <input
                  type="radio"
                  name="cancelation"
                  checked={selectDataCaseThree.cancelation?.includes("2")}
                  value="2"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setSelectDataCaseThree,
                      selectDataCaseThree,
                      "caseThree"
                    )
                  }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">10%</span> */}
                  <div className="discount-info">
                    <strong>Moderate</strong>
                    <p>
                      Vehicle renters will receive a full refund if cancelled up
                      to five days before the reservation.
                    </p>
                  </div>
                </div>
              </div>

              <div className="discount-option">
                <input
                  type="radio"
                  name="cancelation"
                  checked={selectDataCaseThree.cancelation?.includes("3")}
                  value="3"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setSelectDataCaseThree,
                      selectDataCaseThree,
                      "caseThree"
                    )
                  }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">19%</span> */}
                  <div className="discount-info">
                    <strong>Firm</strong>
                    <p>
                      Vehicle renters will receive a full refund if cancelled up
                      to 30 days before the reservation.
                    </p>
                  </div>
                </div>
              </div>

              <div className="discount-option">
                <input
                  type="radio"
                  name="cancelation"
                  checked={selectDataCaseThree.cancelation?.includes("4")}
                  value="4"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setSelectDataCaseThree,
                      selectDataCaseThree,
                      "caseThree"
                    )
                  }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">19%</span> */}
                  <div className="discount-info">
                    <strong>Strict</strong>
                    <p>
                      This reservation is non-refundable. (But we if some one
                      reserve 3 months we will keep only the first month and
                      refund him the 2 months)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cobalt-Note cobalt-Note--fullWidth">
          We'll only contact you with important info about your rentals (no spam
          ✌️).
        </div>
      </div>
    </>
  );
};

export default Cancelation;
