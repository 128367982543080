import React from "react";

import "./home.css";
import AfterHeader from "../component/AfterHeader";
import DiscoverNew from "../component/DiscoverNew";

export default function Home() {
  return (

   
    <div class="js_site_content site_content">
      
<AfterHeader/>
<DiscoverNew/>
</div>

  );
}
