import React from "react";

const WrongRoute = () => {
  return (
    <div style={{fontWeight:"600",textAlign:"center"}} className="container dashboard_container" id="dashboard">
    You are not Authorized to access this.
</div>

  );
};

export default WrongRoute;
