import React, { useContext } from "react";
import "./Instantbooking.css";
import { Switch,withStyles } from "@material-ui/core";
import { DataContext } from "../ContextApi";

const CustomSwitch = withStyles({
  switchBase: {
    color: '#FFB400',
    '&$checked': {
      color: '#FFB400',
    },
    '&$checked + $track': {
      backgroundColor: '#FFB400',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#FFB400',
  },
})(Switch);

export default function Instantbooking({
  icon,
  text,
  detail,
  margin,
  newCar,
  acceptRate,
}) {
  const { filterValue, setFilterValue } = useContext(DataContext);
  const handleChange = (event) => {
    if (newCar) {
      setFilterValue({ ...filterValue, newCarOnly: event.target.checked });
    } else if (acceptRate) {
      setFilterValue({ ...filterValue, acceptRate: event.target.checked });
    } else {
      setFilterValue({ ...filterValue, instantBooking: event.target.checked });
    }
  };
  // Determine the checked state based on the props
  const isChecked = newCar ? filterValue.newCarOnly :
                      acceptRate ? filterValue.acceptRate :
                      filterValue.instantBooking;
  return (
    <div style={{ marginTop: margin }} className="Model_main_div">
      <div className="Model_first_div">
        {icon && (
          <div className="Icon_div image-border">
            <img height="32px" width="32px" src={icon} />
          </div>
        )}
        <div className="Second_div">
          <div>{text}</div>
          <div>{detail}</div>
        </div>
      </div>

      <div>
        <CustomSwitch           checked={isChecked}
 onChange={handleChange} />
      </div>
    </div>
  );
}
