import React from "react";

const Review = ({review}) => {
  return(
    <>
         <div class="cobalt-layout-section">
                      <div
                        class="c-flex c-items-center c-gap-2xs text_muted"
                        data-global-car-review=""
                      >
                        <div class="inline-block">
                          <div class="rentals_count">
                            <div class="statistics_value">{review?.length}</div>
                            <div class="statistics_label">rentals</div>
                          </div>
                        </div>
                        -
                        <div class="c-h-sm">
                          <div>
                            <div class="rating_for_container ">
                              <div
                                class="rating_for"
                                data-tooltip="2 reviews<br>60% positive"
                              >
                                <div class="cobalt-rating">
                                  <div class="cobalt-rating-icons">
                                    <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                      <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                        ></path>
                                      </svg>
                                    </span>
                                    <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                      <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                        ></path>
                                      </svg>
                                    </span>
                                    <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                      <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                        ></path>
                                      </svg>
                                    </span>
                                    <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                      <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                        ></path>
                                      </svg>
                                    </span>
                                    <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                      <svg
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                        ></path>
                                      </svg>
                                    </span>
                                  </div>
                                  <div class="cobalt-rating__label">
                                    <span itemprop="">3.0</span>(
                                    <span itemprop="">2</span>)
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cobalt-layout-card">
                      <div class="cobalt-layout-card__item">
                        <div
                          class="js_reviews_infinite_pagination"
                          data-infinite-pagination-added=""
                        >
                          <div class="js_infinite_pagination_content">
      {review?.map((val)=>(          
                            <div
                              class="rental_review"
                              itemprop="review"
                              itemscope=""
                              itemtype="https://schema.org/Review"
                            >
                              <img
                                alt="Brittany Giroux"
                                class="cobalt-avatar rental_review__thumb"
                                srcset={val?.profile_image||"https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=1&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60 1x, https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=2&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60 2x"}
                                src={val?.profile_image||"https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=1&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60"}
                              />
                              <div class="rental_review__content">
                                <div class="rental_review__info">
                                  <div
                                    class="c-text-title-sm"
                                    itemprop="author"
                                    itemscope=""
                                    itemtype="http://schema.org/Person"
                                  >
                                    <span itemprop="name">
                                      {val?.first_name}
                                    </span>
                                  </div>
                                  <div class="rental_review__date">
                                    Jul 14 at 07:44 PM
                                  </div>
                                </div>
                                <div class="rental_review__text">
                                  <p itemprop="reviewBody">
                                   {val?.remarks}
                                  </p>
                                </div>
                                <div style={{color:"#7b728e"}} class="c-text-subdued c-text-body-md">
                                  4-hour rental in July 2024
                                </div>
                                <div class="dashboard_rental_rating_and_report c-mb-sm">
                                  <div class="star_ratings">
                                    <div class="cobalt-rating-icons">
                                      <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span>{val?.rating}</span>

                                    </div>
                                  </div>
                                  <div
                                    class="c-mb-sm"
                                    itemprop="reviewRating"
                                    itemscope=""
                                    itemtype="http://schema.org/Rating"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          ))}          
                          </div>
                        </div>
                        
                        <div class="row c-text-left">
                          <div class="c-p-sm">
                            Reviews are listed from newest to oldest. All
                            reviews are written by drivers and moderated by
                            Overlo.{" "}
                            <a
                              // href="/legal/2021-07-07/20210707_transparency_policy_FR.pdf"
                              target="_blank"
                            >
                              Learn more.
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
    </>



  );
};

export default Review;
