import React, { useState, useEffect, useContext, useRef } from "react";
import ImageSection from "./ImageSection";
import OverlowConnect from "./OverlowConnect";
import PickupAndReturn from "./PickupAndReturn";
import MillageAllownce from "./MillageAllownce";
import DurationCondition from "./DurationCondition";
import OwnerRules from "./OwnerRules";
import TechnicalFeatures from "./TechnicalFeatures";
import Option from "./Option";
import Insurance from "./Insurance";
import Review from "./Review";
import { Modal } from "@material-ui/core";
import "./overallselection.css";
import { Booking, getCarDetail, getCarDetailAdmin } from "../../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../../ContextApi";
import { AuthContext } from "../../../ContextApi/AuthContext";
import Spinner from "../../../ReusableComponent/Spinner";
import Calender from "./Calender";
import { formatDate } from "../../../ExportFiles";
import ModalReusable from "../../../ReusableComponent/ModalReusable";
import EditProfile from "../MenuPages/Accounts/EditProfile";

const OverAllSection = ({ open, setOpen, id, admin, handleAcceptReject,request }) => {
  const [carDetail, setCarDetail] = useState([]);
  const { filterValue, inputValue, openDialog } =
    useContext(DataContext)
      const {isLoggedIn}=useContext(AuthContext)
  const [callFunction,setCallFunction]=useState(false)
const [openModal,setOpenModal]=useState(false)
  // Create a ref for the reviews section
  const reviewsRef = useRef(null);

  // Function to scroll to the reviews section
  const scrollToReviews = () => {
    if (reviewsRef.current) {
      reviewsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  const [handle, setHandle] = useState(true);
  const handleClose = () => {
    console.log("modalcLOSING");
    setOpen(false);
    // navigate("/search")
  };
  // const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  console.log("carDetail", carDetail);
  const handleBook = (id) => {
    const profileStatus = localStorage.getItem('profile_status');

    if (filterValue?.drop_off_date_time && filterValue?.pick_up_date_time) {
      if (isLoggedIn) {
        if (profileStatus == '1') {

        let payload = {
          vehicle_id: id,
          pickup_datetime: formatDate(filterValue?.pick_up_date_time),
          dropoff_datetime: formatDate(filterValue?.drop_off_date_time),
          user_location: inputValue,
        };
        Booking(payload, setOpen, openDialog);
      }
      else {
openDialog("Please complete the profile before booking");
setOpenModal(true)
}
      }
       else {
        sessionStorage.setItem("lastRoute", location.pathname);
        navigate("/login");
      }
    } else {
      // navigate("/search");
      setOpen(false);
      openDialog("Please Pick Date and Time");
    }
  };
  useEffect(() => {
    if (id) {
      if (admin) {
        let payload = {
          vehicle_id: id,
        };

        getCarDetailAdmin(payload, setCarDetail, setHandle);
      } else {
        let payload = {
          vehicle_id: id,
          period: sessionStorage.getItem("period"),
        };

        getCarDetail(payload, setCarDetail, setHandle);
      }
    }
  }, [id]);

  return (
    <>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      // BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Set the background color
        timeout: 500,
      }}
    >
      <div
        // style={{ width: handle && "95%" }}
        className="modal-containers modal-con"
      >
        <div className="modal-contents">
          <p
            class="close cross-icon"
            onClick={handleClose}
            id="modal-description"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.51672 15.8995C1.12447 15.9223 0.738678 15.7919 0.440711 15.5358C-0.146904 14.9447 -0.146904 13.99 0.440711 13.3989L13.3075 0.532C13.9187 -0.0398937 14.8777 -0.00810248 15.4496 0.603069C15.9668 1.15575 15.9969 2.00523 15.5202 2.59314L2.57756 15.5358C2.28347 15.7882 1.90384 15.9184 1.51672 15.8995Z"
                fill="white"
              />
              <path
                d="M14.3684 15.8996C13.9708 15.8979 13.5898 15.74 13.3075 15.4601L0.440589 2.59313C-0.103806 1.9574 -0.0297925 1.00067 0.605933 0.456224C1.17334 -0.0296788 2.01014 -0.0296788 2.57749 0.456224L15.5201 13.3231C16.1312 13.8952 16.1628 14.8542 15.5907 15.4653C15.568 15.4896 15.5444 15.5131 15.5201 15.5358C15.3633 15.6722 15.1802 15.7748 14.9821 15.8374C14.7839 15.9 14.575 15.9211 14.3684 15.8996Z"
                fill="white"
              />
            </svg>
          </p>
          <body id="search_show">
            <div class="js_site_content site_content">
              {handle ? (
                <Spinner />
              ) : (
                <div class="container side_panel_container- js_side_panel">
                  <div class="side_panel_loader js_side_panel_loader hidden_content"></div>
                  <div class="js_side_panel_content">
                    <div class="js_car_sticky_header car_sticky_header">
                      <div class="js_preview_panel_close">
                        <div class="cobalt-Icon cobalt-Icon--colorBase">
                          <svg>
                            <use href="#cobalt/icon/arrowLeft"></use>
                          </svg>
                        </div>
                      </div>
                      <div class="car_sticky_header__car_info">
                        <div class="car_sticky_header__car_title">
                          Nissan Altima 2.50
                        </div>
                        <button
                          class="unstyled car_card__ratings car_card__ratings--clickable js_car_card__ratings"
                          type="button"
                        >
                          <div class="cobalt-rating">
                            <div class="cobalt-rating-icons">
                              <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                  ></path>
                                </svg>
                              </span>
                              <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                  ></path>
                                </svg>
                              </span>
                              <div class="cobalt-rating-icons__icon-wrapper">
                                <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                  <svg
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                    ></path>
                                  </svg>
                                </span>
                                <div class="cobalt-rating-icons__icon-half-container">
                                  <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                    <svg
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                  ></path>
                                </svg>
                              </span>
                              <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                            <div class="cobalt-rating__label">
                              <span itemprop="">2.75</span>(
                              <span itemprop="">2</span>)
                            </div>
                          </div>
                        </button>
                      </div>
                      <div class="car_sticky_header__price_value js_price_value">
                        $112
                      </div>
                    </div>

                    <div
                      class="row car_preview_row js_car_preview_row"
                      data-car-id="1475829"
                    >
                      <div
                        style={{ background: "#fff" }}
                        class="col-md-8 col-sm-7 car_preview_content_xs car_preview_content_xs--to_the_top js_car_preview_content_xs"
                      >
                        <ImageSection
                          carDetail={carDetail}
                          scrollToReviews={scrollToReviews}
                        />
                        <div class="c-flex c-flex-col sm:c--mx-md sm:c-pb-lg">
                          {/* <div class="cobalt-layout-stack">
                            <OverlowConnect />
                          </div> */}
                          <h2 class="cobalt-layout-section-title">
                            Pickup &amp; return location
                          </h2>
                          <PickupAndReturn carDetail={carDetail} />

                          <div class="js_mileage_allowance_section">
                            <div
                              id="js_modal_mileage_included"
                              class="c-hidden"
                              data-closeable="true"
                            >
                              <div class="js_cobalt-inline-modal__title">
                                Mileage included
                              </div>
                              <div
                                class="js_cobalt-inline-modal__body"
                                style={{ minHeight: "inherit" }}
                              >
                                <div class="c-text-body-md">
                                  <p class="js_mileage_package_details_dynamic">
                                    For 3 days, 30 min,{" "}
                                    <b>800 mi are included in the price</b>. You
                                    can increase your allowance by adding a{" "}
                                    <b>mileage package</b> during booking at
                                    +$0.35/mi. If you exceed your allowance
                                    during your rental, you’ll pay $0.50/mi when
                                    you return the car.
                                  </p>
                                </div>
                              </div>
                              <div class="js_cobalt-inline-modal__footer">
                                <div class="c-flex-no-wrap cobalt-buttons-group cobalt-buttons-group--right">
                                  <a
                                    class="cobalt-buttons-group__item cob-Button cob-Button__ghost"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/help/articles/c9f21d567dce"
                                  >
                                    Learn more
                                  </a>
                                  <button class="cobalt-buttons-group__item cob-Button cob-Button__default cob-Button__default--primary js_cobalt-inline-modal__close">
                                    OK
                                  </button>
                                </div>
                              </div>
                            </div>
                            <h2 class="cobalt-layout-section-title">
                              Car Description
                            </h2>
                            <MillageAllownce
                              description={carDetail?.description}
                            />
                          </div>

                          <h2 class="cobalt-layout-section-title">Owner</h2>
                          <div class="cobalt-layout-card">
                            <div class="cobalt-layout-card__item">
                              <a
                                class="car_owner_section link-on-hover"
                                href={`/owner_detail/${carDetail?.vehicle_id}`}
                                rel="nofollow"
                                target="_blank"
                              >
                                <div class="c-mr-sm c-w-xl c-h-xl">
                                  <img
                                    alt="Carshare"
                                    width="48"
                                    height="48"
                                    class="cobalt-avatar"
                                    srcset="https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=1&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60 1x, https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=2&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60 2x"
                                    src="https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=1&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60"
                                  />
                                </div>
                                <div class="c-flex c-flex-col c-gap-2xs">
                                  <div class="c-text-title-sm c-text-base">
                                    <div class="c-flex c-items-center c-gap-xs">
                                      <span
                                        // onClick={}
                                        data-value="1f1d1h1h1e1i1h1b37362t37391b"
                                        data-noblank="false"
                                        class=" js_drk_lnk"
                                      >
                                        {carDetail?.owner_name}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    itemprop="aggregateRating"
                                    itemscope="itemscope"
                                    itemtype="http://schema.org/AggregateRating"
                                  >
                                    <div class="rating_for_container ">
                                      <div
                                        class="rating_for"
                                        data-tooltip="15 reviews<br>90% positive"
                                      >
                                        <div class="cobalt-rating">
                                          <div class="cobalt-rating-icons">
                                            <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                              <svg
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                                ></path>
                                              </svg>
                                            </span>
                                            <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                              <svg
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                                ></path>
                                              </svg>
                                            </span>
                                            <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                              <svg
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                                ></path>
                                              </svg>
                                            </span>
                                            <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                              <svg
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                                ></path>
                                              </svg>
                                            </span>
                                            <div class="cobalt-rating-icons__icon-wrapper">
                                              <span class="cobalt-Icon cobalt-Icon--colorDisabled cobalt-Icon--size16">
                                                <svg
                                                  viewBox="0 0 24 24"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                                  ></path>
                                                </svg>
                                              </span>
                                              <div class="cobalt-rating-icons__icon-half-container">
                                                <span class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                                  <svg
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      clip-rule="evenodd"
                                                      d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                                    ></path>
                                                  </svg>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="cobalt-rating__label">
                                            <span itemprop="ratingValue">
                                              {carDetail?.rating}
                                            </span>
                                            (
                                            <span itemprop="ratingCount">
                                              15
                                            </span>
                                            )
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>

                          <h2 class="cobalt-layout-section-title">
                            Duration conditions
                          </h2>
                          <DurationCondition carDetail={carDetail} />
                          <h2 class="cobalt-layout-section-title">
                            Owner rules
                          </h2>
                          <OwnerRules rules={carDetail?.owner_rules} />

                          <div class="c-grid">
                            <h2 class="cobalt-layout-section-title">
                              Technical features
                            </h2>
                            <TechnicalFeatures carDetail={carDetail} />

                            <h2 class="cobalt-layout-section-title">
                              Options &amp; accessories
                            </h2>
                            <Option features={carDetail?.feature} />
                          </div>
                          <h2 class="cobalt-layout-section-title">Insurance</h2>
                          <Insurance />
                          <div
                            id="reviews"
                            ref={reviewsRef}
                            class="js_car_reviews"
                          >
                            <h2 class="cobalt-layout-section-title">Reviews</h2>

                            <Review review={carDetail?.reviews} />
                          </div>
                          <div
                            // style={{ display: "none" }}
                            id="js_preview_calendar"
                          >
                            <h2 class="cobalt-layout-section-title">
                              Availability
                            </h2>
                            <div class="cobalt-layout-section">
                              <div class="c-flex c-flex-col c-gap-sm c-mb-sm c-justify-between sm:c-flex-row sm:c-flex-row-reverse">
                                {/* <div class="calendar_updated_at">
                                  Updated: <strong>2 days ago</strong>
                                </div> */}
                                <div class="">
                                  <div class="calendar_state">
                                    <div class="calendar_state_color partially_unavailable"></div>
                                    <div class="calendar_state_title">
                                      Partially unavailable
                                    </div>
                                  </div>
                                  <div class="calendar_state">
                                    <div class="calendar_state_color available"></div>
                                    <div class="calendar_state_title">
                                      Available
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="c-pb-md">
                                <Calender unavailableSlots={carDetail?.unavailable_period} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ position: "sticky", top: "10px" }}
                        class="col-md-4 col-sm-5 no-outer-gutter-xs"
                      >
                        <div class="js_side_preview_form side_preview_form toggle_pointer_events">
                          <div class="preview_price_and_pick js_preview_price_and_pick">
                            <div class="cobalt-Card c-overflow-hidden c-shadow">
                              <div class="cobalt-Card__Section">
                                <div
                                  class="hidden-xs centered c-mb-sm js_price_block"
                                  data-original-title=""
                                  title=""
                                >
                                  <span class="c-text-title-lg">
                                    <span class="js_price_value">
                                      {" "}
                                      $
                                      {carDetail?.total_price
                                        ? carDetail?.high_price
                                        : carDetail?.low_price}
                                    </span>
                                  </span>
                                  <span class="price_subtitle">
                                    {!carDetail?.total_price ? (
                                      <span class="rental_price_subtitle c-text-section-heading js_price_day_label">
                                        Average price per hour
                                      </span>
                                    ) : (
                                      <div class="js_price_total_label">
                                        <div class="rental_price_subtitle c-text-section-heading">
                                          <span class="c-mr-2xs">
                                            {carDetail?.period}
                                          </span>
                                          <button
                                            class="js_toggle_car_price_details toggle_car_price_details js_popup_trigger mfp-inline"
                                            data-mfp-src=".js_car_rental_price_details"
                                            type="button"
                                          >
                                            <div class="cobalt-Icon cobalt-Icon--size20 cobalt-Icon--colorSubduedInteractive">
                                              <svg>
                                                <use href="#cobalt/icon/info"></use>
                                              </svg>
                                            </div>
                                          </button>
                                        </div>
                                        <div class="c-text-body-sm c-text-subdued">
                                          {carDetail?.milage} mi for free
                                        </div>
                                      </div>
                                    )}
                                  </span>
                                </div>
                                {/* <div
                          class="cobalt-Note cobalt-Note--fullWidth c-mb-sm js_delivery-car-already-picked"
                          hidden=""
                        >
                          You've already selected this car for delivery
                        </div>
                        <div
                          class="cobalt-Note cobalt-Note--fullWidth c-mb-sm js_car_already_picked"
                          hidden=""
                        >
                          <div class="cobalt-Icon cobalt-Icon--colorSuccess c-mr-xs">
                            <svg>
                              <use href="#cobalt/icon/checkCircle"></use>
                            </svg>
                          </div>
                          Vehicle already selected.
                        </div> */}
                        
                                {admin ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      className="modal-container-footer"
                                      style={{ borderTop: "0px", padding: "0" }}
                                    >
                                      <button
                                        className="button-color"
                                        style={{
                                          width: "100px",
                                          cursor:
                                            (carDetail?.is_approved === 1 ||
                                              carDetail?.is_cancelled === 1) &&
                                            !(
                                              carDetail?.is_approved === 0 &&
                                              carDetail?.is_cancelled === 0
                                            )
                                              ? "not-allowed"
                                              : "pointer",
                                          opacity:
                                            (carDetail?.is_approved === 1 ||
                                              carDetail?.is_cancelled === 1) &&
                                            !(
                                              carDetail?.is_approved === 0 &&
                                              carDetail?.is_cancelled === 0
                                            )
                                              ? 0.5
                                              : 1,
                                        }}
                                        onClick={() =>
                                          handleAcceptReject(
                                            1,
                                            carDetail?.vehicle_id
                                          )
                                        }
                                        disabled={
                                          !(
                                            carDetail?.is_approved === 0 &&
                                            carDetail?.is_cancelled === 0
                                          ) &&
                                          (carDetail?.is_approved === 1 ||
                                            carDetail?.is_cancelled === 1)
                                        }
                                      >
                                        Accept
                                      </button>
                                    </div>

                                    <div
                                      className="modal-container-footer"
                                      style={{ borderTop: "0px", padding: "0" }}
                                    >
                                      <button
                                        className="button-color"
                                        style={{
                                          width: "100px",
                                          backgroundColor: "#c71414",
                                          cursor:
                                            (carDetail?.is_approved === 0 ||
                                              carDetail?.is_cancelled === 0) &&
                                            !(
                                              carDetail?.is_approved === 0 &&
                                              carDetail?.is_cancelled === 0
                                            )
                                              ? "not-allowed"
                                              : "pointer",
                                          opacity:
                                            (carDetail?.is_approved === 0 ||
                                              carDetail?.is_cancelled === 0) &&
                                            !(
                                              carDetail?.is_approved === 0 &&
                                              carDetail?.is_cancelled === 0
                                            )
                                              ? 0.5
                                              : 1,
                                        }}
                                        onClick={() =>
                                          handleAcceptReject(
                                            0,
                                            carDetail?.vehicle_id
                                          )
                                        }
                                        disabled={
                                          !(
                                            carDetail?.is_approved === 0 &&
                                            carDetail?.is_cancelled === 0
                                          ) &&
                                          (carDetail?.is_approved === 0 ||
                                            carDetail?.is_cancelled === 1)
                                        }
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                ) : request?
                                ""
                                :
                                  <button
                                    class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                                    data-car-id="1475829"
                                    style={{
                                      opacity:
                                        localStorage.getItem("company_id") == -1
                                          ? 0.5
                                          : 1,
                                    }}
                                    disabled={
                                      localStorage.getItem("company_id") == -1
                                    }
                                    onClick={() =>
                                      handleBook(carDetail?.vehicle_id)
                                    }
                                  >
                                    <span class="cob-Button__Icon cob-Button__Icon--left">
                                      <span class="cobalt-Icon cobalt-Icon--colorInversed">
                                        <svg>
                                          <use href="#cobalt/icon/instant"></use>
                                        </svg>
                                      </span>
                                    </span>
                                    <span>Book</span>
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="js_carousel_small_screen">
                      <div class="car_carousel_small_screen_photos">
                        <div class="close_preview_photo_carousel side_panel_close_button side_panel_close_button--light js_close_preview_photo_carousel"></div>
                        <div class="car_carousel_small_screen_photo owl_centered_img_carousel js_carousel_small_screen_photo">
                          <div class="car_photo js_car_photo">
                            <img
                              alt="Nissan Altima 2.5, 2009, Regular (87), automatic"
                              itemprop="image"
                              data-index="0"
                              srcset="https://drivy.gumlet.io/uploads/originals/d33bbc721e9ede1ae24a5a093aa6ed30.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608 1x, https://drivy.gumlet.io/uploads/originals/d33bbc721e9ede1ae24a5a093aa6ed30.jpg?compress=true&amp;dpr=2&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608 2x"
                              src="https://drivy.gumlet.io/uploads/originals/d33bbc721e9ede1ae24a5a093aa6ed30.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608"
                            />
                          </div>
                          <div class="car_photo js_car_photo">
                            <img
                              alt="Nissan Altima 2.5 with Air conditioning"
                              itemprop="image"
                              data-index="1"
                              srcset="https://drivy.gumlet.io/uploads/originals/9cf9cc756929ea38824c4b3e7e417d81.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608 1x, https://drivy.gumlet.io/uploads/originals/9cf9cc756929ea38824c4b3e7e417d81.jpg?compress=true&amp;dpr=2&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608 2x"
                              src="https://drivy.gumlet.io/uploads/originals/9cf9cc756929ea38824c4b3e7e417d81.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608"
                            />
                          </div>
                          <div class="car_photo js_car_photo">
                            <img
                              alt="Nissan Altima 2.5 with Cruise control"
                              itemprop="image"
                              data-index="2"
                              srcset="https://drivy.gumlet.io/uploads/originals/5c120b390ecad93b54efa9f14ab7f928.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608 1x, https://drivy.gumlet.io/uploads/originals/5c120b390ecad93b54efa9f14ab7f928.jpg?compress=true&amp;dpr=2&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608 2x"
                              src="https://drivy.gumlet.io/uploads/originals/5c120b390ecad93b54efa9f14ab7f928.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=342&amp;mode=crop&amp;w=608"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="popin js_popin mfp-hide js_car_rental_price_details cobalt-Card"
                      data-toggle_more_info_loaded="yes"
                    >
                      <button
                        name="button"
                        type="button"
                        title="Close"
                        class="mfp-close"
                      ></button>
                      <div class="cobalt-Card__Section">
                        <div class="c-text-section-heading c-mb-xs">
                          Total price
                        </div>
                        <div class="car_rental_price_details__pricing_detail">
                          <div class="flex">
                            <div class="c-text-body-md c-text-base c-font-bold c-mr-2xs">
                              Booking price
                            </div>
                          </div>
                          <div class="c-text-body-md c-text-base c-font-bold">
                            $112
                          </div>
                        </div>
                      </div>
                      <div class="cobalt-Card__Section cobalt-Card__Section--divided">
                        <div class="c-text-section-heading c-mb-xs">
                          Included
                        </div>
                        <ul>
                          <li class="cobalt-BulletListItem">
                            <span class="cobalt-BulletListItem__Icon">
                              <div class="cobalt-Icon cobalt-Icon--colorSuccess">
                                <svg>
                                  <use href="#cobalt/icon/checkCircle"></use>
                                </svg>
                              </div>
                            </span>
                            <div class="cobalt-BulletListItem__Content">
                              <p class="c-text-body-md c-text-base">
                                800 mi for free
                              </p>
                            </div>
                          </li>
                          <li class="cobalt-BulletListItem">
                            <span class="cobalt-BulletListItem__Icon">
                              <div class="cobalt-Icon cobalt-Icon--colorSuccess">
                                <svg>
                                  <use href="#cobalt/icon/checkCircle"></use>
                                </svg>
                              </div>
                            </span>
                            <div class="cobalt-BulletListItem__Content">
                              <p class="c-text-body-md c-text-base">
                                Free additional drivers
                              </p>
                            </div>
                          </li>
                          <li class="cobalt-BulletListItem">
                            <span class="cobalt-BulletListItem__Icon">
                              <div class="cobalt-Icon cobalt-Icon--colorSuccess">
                                <svg>
                                  <use href="#cobalt/icon/checkCircle"></use>
                                </svg>
                              </div>
                            </span>
                            <div class="cobalt-BulletListItem__Content">
                              <p class="c-text-body-md c-text-base">
                                Free cancelation up to 24hrs before the rental
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="cobalt-Card__Section cobalt-Card__Section--divided">
                        <div class="c-text-section-heading c-mb-xs">
                          Not included
                        </div>
                        <ul>
                          <li class="cobalt-BulletListItem">
                            <span class="cobalt-BulletListItem__Icon">
                              <div class="cobalt-Icon cobalt-Icon--colorBase">
                                <svg>
                                  <use href="#cobalt/icon/serviceFuel"></use>
                                </svg>
                              </div>
                            </span>
                            <div class="car_rental_price_details__with_more_info">
                              <div class="cobalt-BulletListItem__Content">
                                <p class="c-text-body-md c-text-base">Fuel</p>
                              </div>
                              <div>
                                <button
                                  class="unstyled car_rental_price_details__toggle_more_info js_car_rental_price_details__toggle_more_info"
                                  data-identifier="fuel"
                                  type="button"
                                >
                                  <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                                    <svg>
                                      <use href="#cobalt/icon/info"></use>
                                    </svg>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </li>
                          <div
                            class="c-mb-md car_bullet_list_below_content js_car_rental_price_details__more_info"
                            data-identifier="fuel"
                            hidden=""
                          >
                            Fuel isn’t included in the rental. You’ll get a
                            refund of $4.76/gal for returning the car with extra
                            fuel. Missing fuel costs $7.16/gal.
                          </div>
                          <li class="cobalt-BulletListItem">
                            <span class="cobalt-BulletListItem__Icon">
                              <div class="cobalt-Icon cobalt-Icon--colorBase">
                                <svg>
                                  <use href="#cobalt/icon/mileage"></use>
                                </svg>
                              </div>
                            </span>
                            <div class="car_rental_price_details__with_more_info">
                              <div class="cobalt-BulletListItem__Content">
                                <p class="c-text-body-md c-text-base">
                                  Distance over 800 mi
                                </p>
                              </div>
                              <div>
                                <button
                                  class="unstyled car_rental_price_details__toggle_more_info"
                                  data-custommodalid="js_modal_mileage_included"
                                  type="button"
                                >
                                  <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                                    <svg>
                                      <use href="#cobalt/icon/info"></use>
                                    </svg>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="cobalt-Card__Section cobalt-Card__Section--divided">
                        <div class="c-text-section-heading c-mb-xs">
                          After the rental
                        </div>
                        <ul>
                          <li class="cobalt-BulletListItem">
                            <span class="cobalt-BulletListItem__Icon">
                              <div class="cobalt-Icon cobalt-Icon--colorBase">
                                <svg>
                                  <use href="#cobalt/icon/contextualWarningCircleFilled"></use>
                                </svg>
                              </div>
                            </span>
                            <div class="car_rental_price_details__with_more_info">
                              <div class="cobalt-BulletListItem__Content">
                                <p class="c-text-body-md c-text-base">
                                  Fees can apply if the car is dirty, returned
                                  late, parked illegally, or damaged
                                </p>
                              </div>
                              <div class="c-flex c-flex-col c-justify-center">
                                <button
                                  class="unstyled car_rental_price_details__toggle_more_info js_car_rental_price_details__toggle_more_info"
                                  data-identifier="fees_education"
                                  type="button"
                                >
                                  <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                                    <svg>
                                      <use href="#cobalt/icon/info"></use>
                                    </svg>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </li>
                          <div
                            class="c-mb-md car_bullet_list_below_content js_car_rental_price_details__more_info"
                            data-identifier="fees_education"
                            hidden=""
                          >
                            You should return the car in the same condition you
                            pick it up in, on time, and to a parking spot where
                            there’s no risk of a fine for at least 48 hours.
                            Otherwise, the owner can request compensation.
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </body>
        </div>
      </div>
    </Modal>
    <ModalReusable
                  open={openModal}
                  handleClose={()=>setOpenModal(false)}
                   handleOperation={()=>setCallFunction(true)}
                  buttonText={"Update my profile"}
                  modalHeader={"Edit Profile"}
                  // setFilterSelect={setFilterSelect}
                >
                 <div
                    style={{
                      height: "50vh",
                      overflow: "auto",
                      paddingTop: "20px",
                    }}
                  >
                <EditProfile inModalEdit={true} callFunction={callFunction} setCallFunction={setCallFunction} setOpenModal={setOpenModal} />
                </div>
                </ModalReusable>
                    </>
  );
};

export default OverAllSection;
