import React, { useContext, useState, useEffect } from "react";
import {
  getUserDetail,
  lisenceImageUpload,
  profileImageUpload,
  profileUpload,
} from "../../../../api";
import { DataContext } from "../../../../ContextApi";
import { useNavigate } from "react-router-dom";

const EditProfile = ({
  inModalEdit,
  callFunction,
  setCallFunction,
  setOpenModal,
}) => {
  const { openDialog } = useContext(DataContext);
  const navigate = useNavigate();
  useEffect(() => {
    getUserDetail(
      setUserData,
      setPreviewUrl,
      openDialog,
      navigate,
      setPreviewLisenceUrl,
      setPreviewLisenceUrl2
    );
  }, []);
  useEffect(() => {
    if (callFunction) {
      document.getElementById("hiddenSubmitButton").click(); // Trigger the hidden button
      setCallFunction(false);
    }
  }, [callFunction]);

  console.log("inModalEdit", inModalEdit);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    birth_date: "",
    birth_place: "",
    license_number: "",
    license_first_issue_date: "",
    license_country: "",
    license_document_variation: "",
    address: "",
    phone: "",
    about_me: "",
  });
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewLisenceUrl, setPreviewLisenceUrl] = useState("");
  const [previewLisenceUrl2, setPreviewLisenceUrl2] = useState("");

  console.log("previewUrl", previewUrl);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "license_first_issue_date_year" ||
      name === "license_first_issue_date_month" ||
      name === "license_first_issue_date_day"
    ) {
      const [year, month, day] = [
        name === "license_first_issue_date_year"
          ? value
          : userData.license_first_issue_date.split("-")[0],
        name === "license_first_issue_date_month"
          ? value
          : userData.license_first_issue_date.split("-")[1],
        name === "license_first_issue_date_day"
          ? value
          : userData.license_first_issue_date.split("-")[2],
      ];

      setUserData((prevState) => ({
        ...prevState,
        license_first_issue_date: `${year}-${month?.padStart(
          2,
          "0"
        )}-${day?.padStart(2, "0")}`,
      }));
    } else {
      setUserData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const [year, month, day] = userData.birth_date.split("-");

    let newDate;
    switch (name) {
      case "year":
        newDate = `${value}-${month}-${day}`;
        break;
      case "month":
        newDate = `${year}-${value}-${day}`;
        break;
      case "day":
        newDate = `${year}-${month}-${value}`;
        break;
      default:
        newDate = userData.birth_date;
    }

    setUserData((prevState) => ({
      ...prevState,
      birth_date: newDate,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      user_date_of_birth: userData?.birth_date,
      user_place_of_birth: userData?.birth_place,
      user_driver_license_number: userData?.license_number,
      user_issue_date: userData?.license_first_issue_date,
      user_license_country: userData?.license_country,
      user_license_state: userData?.license_state,
      user_phone: userData?.phone,
      user_address: userData?.address,
      about_me: userData?.about_me,
      cover_photo: previewUrl,
      license_photo: previewLisenceUrl,
    };
    profileUpload(payload, openDialog, setOpenModal);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("file_name", file);
      profileImageUpload(formData, setPreviewUrl);
    }
  };
  const handleLisenceImageChange = (e) => {
    const file = e.target.files[0];

    if (e.target.name == "file1") {
      if (file) {
        const formData = new FormData();

        formData.append("file_name", file);
        formData.append("is_front", 1); 
        lisenceImageUpload(formData, setPreviewLisenceUrl);

      }    }

       else {
        const formData = new FormData();

        formData.append("file_name", file);
        formData.append("is_front", 0); 
        lisenceImageUpload(formData, setPreviewLisenceUrl2);

         }


  };

  const handleImageRemove = () => {
    setPreviewUrl("");
  };
  const handleLisenceImageRemove = (id) => {
    if(id===1)
    {
      setPreviewLisenceUrl("");

    }
    else{
      setPreviewLisenceUrl2("");

    }
  };
  return (
    <div class={inModalEdit ? "col-sm-12 col-xs-12" : "col-sm-9 col-xs-12"}>
      <div
        style={{ border: inModalEdit ? "none" : "" }}
        class="cobalt-Card cobalt-Card--responsive c-mb-sm"
      >
        <div
          style={{ border: inModalEdit ? "none" : "" }}
          class="cobalt-Card__Section"
        >
          <div class="tab_panes_container">
            <div class="tab_pane active tab_profile_edit">
              <div class="cobalt-Callout cobalt-Callout--info">
                Some of this information appear on your{" "}
                <a href="/users/5970957">profile</a>. Owners will access your
                profile when you send them a booking request. If you publish a
                car listing, your profile becomes public so that drivers can
                access a recap of your reviews.
              </div>
              <form class="js_profile_form" onSubmit={handleSubmit}>
                <fieldset class="cobalt-Fieldset">
                  <legend>Your photo</legend>
                  <div class="cobalt-FormField">
                    <label class="cobalt-FormField__Label">Picture</label>
                    <div
                      class="js_photo_uploader_wrapper photo_uploader_wrapper form_middle avatar_uploader_wrapper"
                      data-uploader="avatar"
                    >
                      <div class="photo_container js_photo_container">
                        {previewUrl ? (
                          <div class="photo_actions">
                            <div
                              onClick={handleImageRemove}
                              class="photo_delete js_photo_delete"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <path
                                    d="M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z M6,19 C6,20.1045695 6.8954305,21 8,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,7 L6,7 L6,19 Z"
                                    fill="#b4bbc6"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        ) : null}
                        {previewUrl ? (
                          <div class="picture_wrapper_bordered_thin">
                            <img
                              style={{ width: "20px" }}
                              alt="Elhadji Ibrahima"
                              class="cobalt-avatar js_picture picture_photo"
                              src={previewUrl}
                            />
                          </div>
                        ) : (
                          <label
                            class="photo_uploader js_photo_uploader"
                            data-uploader="avatar"
                          >
                            <input
                              type="file"
                              name="file"
                              id="file"
                              className="photo_file_input"
                              onChange={handleImageChange}
                            />
                            <div class="photo_uploader_text">
                              <svg
                                width="72"
                                height="72"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  fill="none"
                                  fill-rule="evenodd"
                                  stroke="none"
                                  stroke-width="1"
                                >
                                  <path
                                    d="M7.00000003,5 L3.99079514,5 C2.89821238,5 2,5.89179693 2,6.99188419 L2,18.0081158 C2,19.1066027 2.89130934,20 3.99079514,20 L20.0092049,20 C21.1017876,20 22,19.1082031 22,18.0081158 L22,6.99188419 C22,5.89339733 21.1086907,5 20.0092049,5 L17.0000001,5 L15.0000001,3 L9.00000008,3 L7.00000003,5 L7.00000003,5 Z M12,17.5 C14.7614237,17.5 17,15.2614237 17,12.5 C17,9.73857625 14.7614237,7.5 12,7.5 C9.23857625,7.5 7,9.73857625 7,12.5 C7,15.2614237 9.23857625,17.5 12,17.5 L12,17.5 Z M12,16 C13.9329966,16 15.5,14.4329966 15.5,12.5 C15.5,10.5670034 13.9329966,9 12,9 C10.0670034,9 8.5,10.5670034 8.5,12.5 C8.5,14.4329966 10.0670034,16 12,16 L12,16 Z"
                                    fill="#b4bbc6"
                                  ></path>
                                </g>
                              </svg>
                              Choose a photo
                            </div>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="cobalt-Fieldset">
                  <legend>Personal Information</legend>
                  <div class="cobalt-FormField">
                    <label class="cobalt-FormField__Label" for="user_last_name">
                      Last name
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        required="required"
                        class="cobalt-TextField__Input"
                        type="text"
                        value={userData.last_name}
                        name="last_name"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="cobalt-FormField">
                    <label
                      class="cobalt-FormField__Label"
                      for="user_first_name"
                    >
                      First name
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        required="required"
                        class="cobalt-TextField__Input"
                        type="text"
                        value={userData.first_name}
                        name="first_name"
                        id="user_first_name"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="cobalt-FormField">
                    <label
                      class="cobalt-FormField__Label"
                      for="user_birth_date"
                    >
                      Birth date
                    </label>
                    <div class="cobalt-SelectField cobalt-flexAlign">
                      <select
                        id="user_birth_date_1i"
                        name="year"
                        className="cobalt-SelectField__Input c-mr-xs"
                        value={userData.birth_date.split("-")[0]}
                        onChange={handleDateChange}
                        required
                      >
                        <option value="" label=" "></option>
                        {[...Array(100).keys()].map((i) => {
                          const year = 2024 - i;
                          return (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        id="user_birth_date_2i"
                        name="month"
                        className="cobalt-SelectField__Input c-mr-xs"
                        value={userData.birth_date.split("-")[1]}
                        onChange={handleDateChange}
                        required
                      >
                        <option value="" label=" "></option>
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month, index) => (
                          <option key={index + 1} value={index + 1}>
                            {month}
                          </option>
                        ))}
                      </select>
                      <select
                        id="user_birth_date_3i"
                        name="day"
                        className="cobalt-SelectField__Input c-mr-xs"
                        value={userData.birth_date.split("-")[2]}
                        onChange={handleDateChange}
                        required
                      >
                        <option value="" label=" "></option>
                        {[...Array(31).keys()].map((i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="cobalt-FormField">
                    <label
                      class="cobalt-FormField__Label"
                      for="user_birth_place"
                    >
                      Place of birth
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        className="cobalt-TextField__Input"
                        type="text"
                        value={userData.birth_place}
                        required
                        name="birth_place"
                        id="user_birth_place"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset class="cobalt-Fieldset">
                  <legend>Driving license</legend>
                  <fieldset class="cobalt-Fieldset">
                    <legend>Your license photo</legend>
                    <div class="cobalt-FormField">
                      <label class="cobalt-FormField__Label">Front Picture</label>
                      <div
                        class="js_photo_uploader_wrapper photo_uploader_wrapper form_middle avatar_uploader_wrapper"
                        data-uploader="avatar"
                      >
                        <div class="photo_container js_photo_container">
                          {previewLisenceUrl ? (
                            <div class="photo_actions">
                              <div
                                onClick={()=>handleLisenceImageRemove(1)}
                                class="photo_delete js_photo_delete"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke="none"
                                    stroke-width="1"
                                  >
                                    <path
                                      d="M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z M6,19 C6,20.1045695 6.8954305,21 8,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,7 L6,7 L6,19 Z"
                                      fill="#b4bbc6"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          ) : null}
                          {previewLisenceUrl ? (
                            <div class="picture_wrapper_bordered_thin">
                              <img
                                style={{ width: "20px" }}
                                alt="Elhadji Ibrahima"
                                class="cobalt-avatar js_picture picture_photo"
                                src={previewLisenceUrl}
                              />
                            </div>
                          ) : (
                            <label
                              class="photo_uploader js_photo_uploader"
                              data-uploader="avatar"
                            >
                              <input
                                type="file"
                                name="file1"
                                id="file"
                                className="photo_file_input"
                                onChange={handleLisenceImageChange}
                              />
                              <div class="photo_uploader_text">
                                <svg
                                  width="72"
                                  height="72"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke="none"
                                    stroke-width="1"
                                  >
                                    <path
                                      d="M7.00000003,5 L3.99079514,5 C2.89821238,5 2,5.89179693 2,6.99188419 L2,18.0081158 C2,19.1066027 2.89130934,20 3.99079514,20 L20.0092049,20 C21.1017876,20 22,19.1082031 22,18.0081158 L22,6.99188419 C22,5.89339733 21.1086907,5 20.0092049,5 L17.0000001,5 L15.0000001,3 L9.00000008,3 L7.00000003,5 L7.00000003,5 Z M12,17.5 C14.7614237,17.5 17,15.2614237 17,12.5 C17,9.73857625 14.7614237,7.5 12,7.5 C9.23857625,7.5 7,9.73857625 7,12.5 C7,15.2614237 9.23857625,17.5 12,17.5 L12,17.5 Z M12,16 C13.9329966,16 15.5,14.4329966 15.5,12.5 C15.5,10.5670034 13.9329966,9 12,9 C10.0670034,9 8.5,10.5670034 8.5,12.5 C8.5,14.4329966 10.0670034,16 12,16 L12,16 Z"
                                      fill="#b4bbc6"
                                    ></path>
                                  </g>
                                </svg>
                                Choose a photo
                              </div>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="cobalt-FormField">
                      <label class="cobalt-FormField__Label">Back Picture</label>
                      <div
                        class="js_photo_uploader_wrapper photo_uploader_wrapper form_middle avatar_uploader_wrapper"
                        data-uploader="avatar"
                      >
                        <div class="photo_container js_photo_container">
                          {previewLisenceUrl2 ? (
                            <div class="photo_actions">
                              <div
                                onClick={()=>handleLisenceImageRemove(2)}
                                class="photo_delete js_photo_delete"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke="none"
                                    stroke-width="1"
                                  >
                                    <path
                                      d="M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z M6,19 C6,20.1045695 6.8954305,21 8,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,7 L6,7 L6,19 Z"
                                      fill="#b4bbc6"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          ) : null}
                          {previewLisenceUrl2 ? (
                            <div class="picture_wrapper_bordered_thin">
                              <img
                                style={{ width: "20px" }}
                                alt="Elhadji Ibrahima"
                                class="cobalt-avatar js_picture picture_photo"
                                src={previewLisenceUrl2}
                              />
                            </div>
                          ) : (
                            <label
                              class="photo_uploader js_photo_uploader"
                              data-uploader="avatar"
                            >
                              <input
                                type="file"
                                name="file2"
                                id="file"
                                className="photo_file_input"
                                onChange={handleLisenceImageChange}
                              />
                              <div class="photo_uploader_text">
                                <svg
                                  width="72"
                                  height="72"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g
                                    fill="none"
                                    fill-rule="evenodd"
                                    stroke="none"
                                    stroke-width="1"
                                  >
                                    <path
                                      d="M7.00000003,5 L3.99079514,5 C2.89821238,5 2,5.89179693 2,6.99188419 L2,18.0081158 C2,19.1066027 2.89130934,20 3.99079514,20 L20.0092049,20 C21.1017876,20 22,19.1082031 22,18.0081158 L22,6.99188419 C22,5.89339733 21.1086907,5 20.0092049,5 L17.0000001,5 L15.0000001,3 L9.00000008,3 L7.00000003,5 L7.00000003,5 Z M12,17.5 C14.7614237,17.5 17,15.2614237 17,12.5 C17,9.73857625 14.7614237,7.5 12,7.5 C9.23857625,7.5 7,9.73857625 7,12.5 C7,15.2614237 9.23857625,17.5 12,17.5 L12,17.5 Z M12,16 C13.9329966,16 15.5,14.4329966 15.5,12.5 C15.5,10.5670034 13.9329966,9 12,9 C10.0670034,9 8.5,10.5670034 8.5,12.5 C8.5,14.4329966 10.0670034,16 12,16 L12,16 Z"
                                      fill="#b4bbc6"
                                    ></path>
                                  </g>
                                </svg>
                                Choose a photo
                              </div>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div class="cobalt-FormField cobalt-FormField--withHint">
                    <label
                      class="cobalt-FormField__Label"
                      for="user_license_number"
                    >
                      License number
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        placeholder="e.g.: 121075012XXX"
                        class="cobalt-TextField__Input"
                        type="text"
                        required
                        value={userData.license_number}
                        name="license_number"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div class="cobalt-Hint">
                      <span class="cobalt-Hint__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z"></path>
                          </svg>
                        </span>
                      </span>
                      <span class="cobalt-Hint__Message">
                        On a European license, this is the number given in
                        section 5.{" "}
                        <a target="blank" href="/help/articles/5374cf3c9915">
                          Learn more...
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="cobalt-FormField cobalt-FormField--withHint">
                    <label
                      className="cobalt-FormField__Label"
                      htmlFor="user_license_first_issue_date"
                    >
                      First issue date
                    </label>
                    <div className="cobalt-SelectField cobalt-flexAlign">
                      <select
                        id="user_license_first_issue_date_1i"
                        name="license_first_issue_date_year"
                        className="cobalt-SelectField__Input c-mr-xs"
                        value={userData?.license_first_issue_date.split("-")[0]}
                        onChange={handleInputChange}
                      >
                        <option value="" label=" "></option>
                        {[...Array(40).keys()].map((i) => {
                          const year = 2024 - i;
                          return (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        id="user_license_first_issue_date_2i"
                        name="license_first_issue_date_month"
                        className="cobalt-SelectField__Input c-mr-xs"
                        value={userData.license_first_issue_date.split("-")[1]}
                        onChange={handleInputChange}
                      >
                        <option value="" label=" "></option>
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month, index) => (
                          <option key={index + 1} value={index + 1}>
                            {month}
                          </option>
                        ))}
                      </select>
                      <select
                        id="user_license_first_issue_date_3i"
                        name="license_first_issue_date_day"
                        className="cobalt-SelectField__Input c-mr-xs"
                        value={userData.license_first_issue_date.split("-")[2]}
                        onChange={handleInputChange}
                      >
                        <option value="" label=" "></option>
                        {[...Array(31).keys()].map((i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="cobalt-Hint">
                      <span class="cobalt-Hint__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z"></path>
                          </svg>
                        </span>
                      </span>
                      <span class="cobalt-Hint__Message">
                        On a European license, this is the "Valid from" date for
                        category B vehicles.{" "}
                        <a target="blank" href="/help/articles/5374cf3c9915">
                          Learn more...
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="cobalt-FormField">
                    <label
                      class="cobalt-FormField__Label"
                      for="user_license_country"
                    >
                      Country of issue
                    </label>
                    <div class="cobalt-SelectField">
                      <select
                        class="js_license_fields__license_country cobalt-SelectField__Input"
                        name="license_country"
                        id="user_license_country"
                        value={userData.license_country}
                        onChange={handleInputChange}
                      >
                        {/* <option value="" label=" "></option> */}
                        <option value="192">Senegal</option>
                      </select>
                    </div>
                  </div>
                  <div class="js_license-subdivision" hidden="">
                    <div class="cobalt-FormField">
                      <label
                        class="cobalt-FormField__Label"
                        for="user_license_subdivision"
                      >
                        State of issue
                      </label>
                      <div class="cobalt-SelectField">
                        <select
                          class="cobalt-SelectField__Input"
                          name="license_state"
                          id="user_license_state"
                          value={userData.license_state}
                          onChange={handleInputChange}
                        >
                          <option value="" label=" "></option>
                          <option value="Dakar">Dakar</option>
                          <option value="Diourbel">Diourbel</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    class="js_license_fields__license_document_variation"
                    data-required-for="[]"
                    hidden=""
                  >
                    <div className="cobalt-FormField">
                      <label className="cobalt-FormField__Label">
                        License type
                      </label>
                      <div className="cobalt-ChoiceList">
                        <div className="cobalt-CheckmarkField">
                          <label className="cobalt-CheckmarkField__LabelWrapper">
                            <input
                              className="cobalt-CheckmarkField__Input"
                              type="radio"
                              value="plastic"
                              name="license_document_variation"
                              checked={
                                userData.license_document_variation ===
                                "plastic"
                              }
                              onChange={handleInputChange}
                            />
                            <span className="cobalt-CheckmarkField__Checkmark"></span>
                            <span className="cobalt-CheckmarkField__Label">
                              Plastic photocard license
                            </span>
                          </label>
                        </div>
                        <div className="cobalt-CheckmarkField">
                          <label className="cobalt-CheckmarkField__LabelWrapper">
                            <input
                              className="cobalt-CheckmarkField__Input"
                              type="radio"
                              value="paper"
                              name="license_document_variation"
                              checked={
                                userData.license_document_variation === "paper"
                              }
                              onChange={handleInputChange}
                            />
                            <span className="cobalt-CheckmarkField__Checkmark"></span>
                            <span className="cobalt-CheckmarkField__Label">
                              Paper license
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </fieldset>
                {/* <fieldset class="cobalt-Fieldset">
                  <legend>Contact</legend>
                  <div
                    class="cobalt-FormField cobalt-FormField--withHint"
                    data-form-field-method="email"
                    data-form-field-hint-status="hint"
                  >
                    <label class="cobalt-FormField__Label" for="user_email">
                      Email
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        disabled="disabled"
                        class="cobalt-TextField__Input"
                        type="text"
                        value="elhadj.ba@outlook.com"
                        name="user[email]"
                        id="user_email"
                      />
                    </div>
                    <div class="cobalt-Hint">
                      <span class="cobalt-Hint__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z"></path>
                          </svg>
                        </span>
                      </span>
                      <span class="cobalt-Hint__Message">
                        <a href="/dashboard/account_settings">
                          Change your email
                        </a>
                      </span>
                    </div>
                  </div>
                  <div
                    class="cobalt-FormField cobalt-FormField--withHint"
                    data-form-field-method="phone_country_phone_number_national"
                    data-form-field-hint-status="hint"
                  >
                    <label class="cobalt-FormField__Label">
                      SMS notifications will be sent to:
                    </label>
                    <div class="cobalt-ComposedFormField">
                      <div class="cobalt-SelectField">
                        <select
                          class="cobalt-SelectField__Input"
                          name="user[phone_country]"
                          id="user_phone_country"
                        >
                          <option value="AF">Afghanistan (+93)</option>
                          <option value="AL">Albania (+355)</option>
                          <option value="DZ">Algeria (+213)</option>
                          <option value="AS">American Samoa (+1)</option>
                          <option value="AD">Andorra (+376)</option>
                          <option value="AO">Angola (+244)</option>
                          <option value="AI">Anguilla (+1)</option>
                          <option value="AG">Antigua &amp; Barbuda (+1)</option>
                          <option value="AR">Argentina (+54)</option>
                          <option value="AM">Armenia (+374)</option>
                          <option value="AW">Aruba (+297)</option>
                          <option value="AU">Australia (+61)</option>
                          <option value="AT">Austria (+43)</option>
                          <option value="AZ">Azerbaijan (+994)</option>
                          <option value="BS">Bahamas (+1)</option>
                          <option value="BH">Bahrain (+973)</option>
                          <option value="BD">Bangladesh (+880)</option>
                          <option value="BB">Barbados (+1)</option>
                          <option value="BY">Belarus (+375)</option>
                          <option value="BE">Belgium (+32)</option>
                          <option value="BZ">Belize (+501)</option>
                          <option value="BJ">Benin (+229)</option>
                          <option value="BM">Bermuda (+1)</option>
                          <option value="BT">Bhutan (+975)</option>
                          <option value="BO">Bolivia (+591)</option>
                          <option value="BA">
                            Bosnia &amp; Herzegovina (+387)
                          </option>
                          <option value="BW">Botswana (+267)</option>
                          <option value="BR">Brazil (+55)</option>
                          <option value="IO">
                            British Indian Ocean Territory (+246)
                          </option>
                          <option value="VG">
                            British Virgin Islands (+1)
                          </option>
                          <option value="BN">Brunei (+673)</option>
                          <option value="BG">Bulgaria (+359)</option>
                          <option value="BF">Burkina Faso (+226)</option>
                          <option value="BI">Burundi (+257)</option>
                          <option value="KH">Cambodia (+855)</option>
                          <option value="CM">Cameroon (+237)</option>
                          <option value="CA">Canada (+1)</option>
                          <option value="CV">Cape Verde (+238)</option>
                          <option value="KY">Cayman Islands (+1)</option>
                          <option value="CF">
                            Central African Republic (+236)
                          </option>
                          <option value="TD">Chad (+235)</option>
                          <option value="CL">Chile (+56)</option>
                          <option value="CN">China (+86)</option>
                          <option value="CX">Christmas Island (+61)</option>
                          <option value="CC">
                            Cocos (Keeling) Islands (+61)
                          </option>
                          <option value="CO">Colombia (+57)</option>
                          <option value="KM">Comoros (+269)</option>
                          <option value="CG">Congo - Brazzaville (+242)</option>
                          <option value="CD">Congo - Kinshasa (+243)</option>
                          <option value="CK">Cook Islands (+682)</option>
                          <option value="CR">Costa Rica (+506)</option>
                          <option value="CI">Côte d’Ivoire (+225)</option>
                          <option value="HR">Croatia (+385)</option>
                          <option value="CU">Cuba (+53)</option>
                          <option value="CY">Cyprus (+357)</option>
                          <option value="CZ">Czech Republic (+420)</option>
                          <option value="DK">Denmark (+45)</option>
                          <option value="DJ">Djibouti (+253)</option>
                          <option value="DM">Dominica (+1)</option>
                          <option value="DO">Dominican Republic (+1)</option>
                          <option value="EC">Ecuador (+593)</option>
                          <option value="EG">Egypt (+20)</option>
                          <option value="SV">El Salvador (+503)</option>
                          <option value="GQ">Equatorial Guinea (+240)</option>
                          <option value="EE">Estonia (+372)</option>
                          <option value="ET">Ethiopia (+251)</option>
                          <option value="FK">Falkland Islands (+500)</option>
                          <option value="FO">Faroe Islands (+298)</option>
                          <option value="FJ">Fiji (+679)</option>
                          <option value="FI">Finland (+358)</option>
                          <option value="FR">France (+33)</option>
                          <option value="GF">French Guiana (+594)</option>
                          <option value="PF">French Polynesia (+689)</option>
                          <option value="GA">Gabon (+241)</option>
                          <option value="GM">Gambia (+220)</option>
                          <option value="GE">Georgia (+995)</option>
                          <option value="DE">Germany (+49)</option>
                          <option value="GH">Ghana (+233)</option>
                          <option value="GI">Gibraltar (+350)</option>
                          <option value="GR">Greece (+30)</option>
                          <option value="GL">Greenland (+299)</option>
                          <option value="GD">Grenada (+1)</option>
                          <option value="GP">Guadeloupe (+590)</option>
                          <option value="GU">Guam (+1)</option>
                          <option value="GT">Guatemala (+502)</option>
                          <option value="GN">Guinea (+224)</option>
                          <option value="GW">Guinea-Bissau (+245)</option>
                          <option value="GY">Guyana (+592)</option>
                          <option value="HT">Haiti (+509)</option>
                          <option value="HN">Honduras (+504)</option>
                          <option value="HK">Hong Kong SAR China (+852)</option>
                          <option value="HU">Hungary (+36)</option>
                          <option value="IS">Iceland (+354)</option>
                          <option value="IN">India (+91)</option>
                          <option value="ID">Indonesia (+62)</option>
                          <option value="IR">Iran (+98)</option>
                          <option value="IQ">Iraq (+964)</option>
                          <option value="IE">Ireland (+353)</option>
                          <option value="IL">Israel (+972)</option>
                          <option value="IT">Italy (+39)</option>
                          <option value="JM">Jamaica (+1)</option>
                          <option value="JP">Japan (+81)</option>
                          <option value="JO">Jordan (+962)</option>
                          <option value="KZ">Kazakhstan (+7)</option>
                          <option value="KE">Kenya (+254)</option>
                          <option value="KI">Kiribati (+686)</option>
                          <option value="XK">Kosovo (+383)</option>
                          <option value="KW">Kuwait (+965)</option>
                          <option value="KG">Kyrgyzstan (+996)</option>
                          <option value="LA">Laos (+856)</option>
                          <option value="LV">Latvia (+371)</option>
                          <option value="LB">Lebanon (+961)</option>
                          <option value="LS">Lesotho (+266)</option>
                          <option value="LR">Liberia (+231)</option>
                          <option value="LY">Libya (+218)</option>
                          <option value="LI">Liechtenstein (+423)</option>
                          <option value="LT">Lithuania (+370)</option>
                          <option value="LU">Luxembourg (+352)</option>
                          <option value="MO">Macau SAR China (+853)</option>
                          <option value="MK">Macedonia (+389)</option>
                          <option value="MG">Madagascar (+261)</option>
                          <option value="MW">Malawi (+265)</option>
                          <option value="MY">Malaysia (+60)</option>
                          <option value="MV">Maldives (+960)</option>
                          <option value="ML">Mali (+223)</option>
                          <option value="MT">Malta (+356)</option>
                          <option value="MH">Marshall Islands (+692)</option>
                          <option value="MQ">Martinique (+596)</option>
                          <option value="MR">Mauritania (+222)</option>
                          <option value="MU">Mauritius (+230)</option>
                          <option value="YT">Mayotte (+262)</option>
                          <option value="MX">Mexico (+52)</option>
                          <option value="FM">Micronesia (+691)</option>
                          <option value="MD">Moldova (+373)</option>
                          <option value="MC">Monaco (+377)</option>
                          <option value="MN">Mongolia (+976)</option>
                          <option value="ME">Montenegro (+382)</option>
                          <option value="MS">Montserrat (+1)</option>
                          <option value="MA">Morocco (+212)</option>
                          <option value="MZ">Mozambique (+258)</option>
                          <option value="MM">Myanmar (Burma) (+95)</option>
                          <option value="NA">Namibia (+264)</option>
                          <option value="NR">Nauru (+674)</option>
                          <option value="NP">Nepal (+977)</option>
                          <option value="NL">Netherlands (+31)</option>
                          <option value="NC">New Caledonia (+687)</option>
                          <option value="NZ">New Zealand (+64)</option>
                          <option value="NI">Nicaragua (+505)</option>
                          <option value="NE">Niger (+227)</option>
                          <option value="NG">Nigeria (+234)</option>
                          <option value="NU">Niue (+683)</option>
                          <option value="NF">Norfolk Island (+672)</option>
                          <option value="KP">North Korea (+850)</option>
                          <option value="MP">
                            Northern Mariana Islands (+1)
                          </option>
                          <option value="NO">Norway (+47)</option>
                          <option value="OM">Oman (+968)</option>
                          <option selected="selected" value="PK">
                            Pakistan (+92)
                          </option>
                          <option value="PW">Palau (+680)</option>
                          <option value="PS">
                            Palestinian Territories (+970)
                          </option>
                          <option value="PA">Panama (+507)</option>
                          <option value="PG">Papua New Guinea (+675)</option>
                          <option value="PY">Paraguay (+595)</option>
                          <option value="PE">Peru (+51)</option>
                          <option value="PH">Philippines (+63)</option>
                          <option value="PL">Poland (+48)</option>
                          <option value="PT">Portugal (+351)</option>
                          <option value="PR">Puerto Rico (+1)</option>
                          <option value="QA">Qatar (+974)</option>
                          <option value="RE">Réunion (+262)</option>
                          <option value="RO">Romania (+40)</option>
                          <option value="RU">Russia (+7)</option>
                          <option value="RW">Rwanda (+250)</option>
                          <option value="WS">Samoa (+685)</option>
                          <option value="SM">San Marino (+378)</option>
                          <option value="ST">
                            São Tomé &amp; Príncipe (+239)
                          </option>
                          <option value="SA">Saudi Arabia (+966)</option>
                          <option value="SN">Senegal (+221)</option>
                          <option value="RS">Serbia (+381)</option>
                          <option value="SC">Seychelles (+248)</option>
                          <option value="SL">Sierra Leone (+232)</option>
                          <option value="SG">Singapore (+65)</option>
                          <option value="SK">Slovakia (+421)</option>
                          <option value="SI">Slovenia (+386)</option>
                          <option value="SB">Solomon Islands (+677)</option>
                          <option value="SO">Somalia (+252)</option>
                          <option value="ZA">South Africa (+27)</option>
                          <option value="KR">South Korea (+82)</option>
                          <option value="ES">Spain (+34)</option>
                          <option value="LK">Sri Lanka (+94)</option>
                          <option value="SH">St. Helena (+290)</option>
                          <option value="KN">St. Kitts &amp; Nevis (+1)</option>
                          <option value="LC">St. Lucia (+1)</option>
                          <option value="PM">
                            St. Pierre &amp; Miquelon (+508)
                          </option>
                          <option value="VC">
                            St. Vincent &amp; Grenadines (+1)
                          </option>
                          <option value="SD">Sudan (+249)</option>
                          <option value="SR">Suriname (+597)</option>
                          <option value="SJ">
                            Svalbard &amp; Jan Mayen (+47)
                          </option>
                          <option value="SZ">Swaziland (+268)</option>
                          <option value="SE">Sweden (+46)</option>
                          <option value="CH">Switzerland (+41)</option>
                          <option value="SY">Syria (+963)</option>
                          <option value="TW">Taiwan (+886)</option>
                          <option value="TJ">Tajikistan (+992)</option>
                          <option value="TZ">Tanzania (+255)</option>
                          <option value="TH">Thailand (+66)</option>
                          <option value="TG">Togo (+228)</option>
                          <option value="TK">Tokelau (+690)</option>
                          <option value="TO">Tonga (+676)</option>
                          <option value="TT">Trinidad &amp; Tobago (+1)</option>
                          <option value="TN">Tunisia (+216)</option>
                          <option value="TR">Turkey (+90)</option>
                          <option value="TM">Turkmenistan (+993)</option>
                          <option value="TC">
                            Turks &amp; Caicos Islands (+1)
                          </option>
                          <option value="TV">Tuvalu (+688)</option>
                          <option value="VI">U.S. Virgin Islands (+1)</option>
                          <option value="UG">Uganda (+256)</option>
                          <option value="UA">Ukraine (+380)</option>
                          <option value="AE">
                            United Arab Emirates (+971)
                          </option>
                          <option value="GB">United Kingdom (+44)</option>
                          <option value="US">United States (+1)</option>
                          <option value="UY">Uruguay (+598)</option>
                          <option value="UZ">Uzbekistan (+998)</option>
                          <option value="VU">Vanuatu (+678)</option>
                          <option value="VA">Vatican City (+39)</option>
                          <option value="VE">Venezuela (+58)</option>
                          <option value="VN">Vietnam (+84)</option>
                          <option value="WF">Wallis &amp; Futuna (+681)</option>
                          <option value="EH">Western Sahara (+212)</option>
                          <option value="YE">Yemen (+967)</option>
                          <option value="ZM">Zambia (+260)</option>
                          <option value="ZW">Zimbabwe (+263)</option>
                        </select>
                      </div>
                      <div class="cobalt-TextField">
                        <input
                          type="tel"
                          class="cobalt-TextField__Input"
                          value="3134005794"
                          name="user[phone_number_national]"
                          id="user_phone_number_national"
                        />
                      </div>
                    </div>
                    <div class="cobalt-Hint">
                      <span class="cobalt-Hint__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z"></path>
                          </svg>
                        </span>
                      </span>
                      <span class="cobalt-Hint__Message">
                        Your phone number will only be seen by the owners you
                        contact. By proceeding you are agreeing to receive
                        marketing and promotional messages from the Overlo.
                        Message frequency may vary. Message and data rates may
                        apply. Reply STOP to opt-out at any time.
                      </span>
                    </div>
                  </div>
                  <div
                    class="cobalt-FormField cobalt-FormField--withHint"
                    data-form-field-method="locale"
                    data-form-field-hint-status="hint"
                  >
                    <label class="cobalt-FormField__Label" for="user_locale">
                      Language
                    </label>
                    <div class="cobalt-SelectField">
                      <select
                        class="cobalt-SelectField__Input"
                        name="user[locale]"
                        id="user_locale"
                      >
                        <option value="nl_BE">Dutch (Belgium)</option>
                        <option value="en_US">English (United States)</option>
                        <option selected="selected" value="fr">
                          French
                        </option>
                        <option value="fr_BE">French (Belgium)</option>
                        <option value="de">German</option>
                        <option value="de_AT">German (Austria)</option>
                        <option value="nb_NO">Norwegian</option>
                        <option value="es">Spanish</option>
                      </select>
                    </div>
                    <div class="cobalt-Hint">
                      <span class="cobalt-Hint__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z"></path>
                          </svg>
                        </span>
                      </span>
                      <span class="cobalt-Hint__Message">
                        For notifications, text messages, and emails.
                      </span>
                    </div>
                  </div>
                </fieldset> */}
                <fieldset class="cobalt-Fieldset">
                  <legend>Address</legend>
                  <div class="cobalt-FormField">
                    <label
                      class="cobalt-FormField__Label"
                      for="user_address_line1"
                    >
                      Address
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        class="cobalt-TextField__Input"
                        type="text"
                        name="address"
                        id="user_license_state"
                        value={userData.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="cobalt-FormField">
                    <label
                      class="cobalt-FormField__Label"
                      for="user_address_line1"
                    >
                      Phone
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        class="cobalt-TextField__Input"
                        type="number"
                        name="phone"
                        value={userData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset class="cobalt-Fieldset">
                  <legend>Additional details</legend>
                  <div class="cobalt-FormField cobalt-FormField--withHint">
                    <label class="cobalt-FormField__Label" for="user_about_me">
                      About me
                    </label>
                    <div class="cobalt-TextAreaField">
                      <textarea
                        maxlength="2000"
                        class="cobalt-TextAreaField__Input"
                        name="about_me"
                        value={userData.about_me}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div class="cobalt-Hint">
                      <span class="cobalt-Hint__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z"></path>
                          </svg>
                        </span>
                      </span>
                      <span class="cobalt-Hint__Message">
                        These details enable the owner to get to know you a bit
                        better. The more information you provide, the more
                        likely you are to rent.
                      </span>
                    </div>
                  </div>
                </fieldset>
                <fieldset
                  style={{ display: inModalEdit ? "none" : "block" }}
                  class="cobalt-Fieldset"
                >
                  <input
                    type="submit"
                    id="hiddenSubmitButton"
                    value="Update my profile"
                    class="cob-Button cob-Button__default cob-Button__default--primary"
                  />
                </fieldset>
              </form>
              <div
                id="revoke-verification"
                class="c-hidden"
                data-closeable="true"
              >
                <div class="js_cobalt-inline-modal__title">
                  Verified profile
                </div>
                <div
                  class="js_cobalt-inline-modal__body"
                  style={{ minHeight: "inherit" }}
                >
                  Your profile has been verified, which allows you to now rent
                  Overlo Connect vehicles without having to repeat these
                  verifications. If you change any of these details, you will
                  need to get your profile verified again before your next
                  Overlo Connect rental.
                </div>
                <div class="js_cobalt-inline-modal__footer">
                  <a
                    data-method="post"
                    class="cob-Button cob-Button__default cob-Button__default cob-Button__default--primary cob-Button__default--destructive cob-Button--fullWidth c-mb-sm"
                    href="/dashboard/profile/revoke_verification"
                  >
                    Modify this information
                  </a>
                  <button
                    name="button"
                    type="submit"
                    class="cob-Button cob-Button__ghost cob-Button--fullWidth js_cobalt-inline-modal__close"
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div
                id="unverify-phone-number"
                class="c-hidden"
                data-closeable="true"
              >
                <div class="js_cobalt-inline-modal__title">
                  Verified phone number
                </div>
                <div
                  class="js_cobalt-inline-modal__body"
                  style={{ minHeight: "inherit" }}
                >
                  Your phone number has been verified, which allows you to rent
                  out vehicles without repeating this verification. If you
                  change it, you will have to verify it again during the booking
                  process.
                </div>
                <div class="js_cobalt-inline-modal__footer">
                  <form
                    class="button_to"
                    method="post"
                    action="/dashboard/profile/unverify_phone_number"
                  >
                    <input
                      data-method="post"
                      class="cob-Button cob-Button__default cob-Button__default cob-Button__default--primary cob-Button__default--destructive cob-Button--fullWidth c-mb-sm"
                      type="submit"
                      value="Modify my phone number"
                    />
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value="ExPmnG2kKjxcUj4pJyzI7qx1kbA_5RsXwGVVh2W5GF-93xYLGYpVg5Y9-HBcsOm7wSl7aH2G9VKjrvYEhIYt_A"
                      autocomplete="off"
                    />
                  </form>
                  <button
                    name="button"
                    type="submit"
                    class="cob-Button cob-Button__ghost cob-Button--fullWidth js_cobalt-inline-modal__close"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
