import React,{useContext, useEffect} from "react";



import priva from "../../.././image/png/pros_priva.png";
import entrepreneur from "../../.././image/png/pros_entrepreneur.png";
import EarnMoney from "./EarnMoney";
import DollarBar from "./DollarBar";
import TwoWaysShare from "./TwoWaysShare";
import { DataContext } from "../../../ContextApi";
const RentYourCar = () => {
  const {
    setInputValue
  } = useContext(DataContext);
  useEffect(() => {
    setInputValue("")
  }, []);
  
  return (
    <body style={{height:"auto"}}  id="pages_landings_owner_homepage">
      <div class="js_site_content site_content">
        <article>
         <EarnMoney/>
          <div class="floating_banner js_floating_banner">
            <div class="container">
              <div class="row">
                <div class="col-sm-4">
                  <div class="visible-xs">
                    <button
                      class="cob-Button cob-Button--fullWidth cob-Button__default cob-Button__default--primary js_rent_my_car js_rent_my_car_banner"
                      data-click-location="banner"
                      disabled="true"
                    >
                      Get started
                    </button>
                  </div>
                  <div class="hidden-xs">
                    <button
                      class="cob-Button cob-Button__default cob-Button__default--primary js_rent_my_car js_rent_my_car_banner"
                      data-click-location="banner"
                      disabled="true"
                    >
                      Get started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

       <DollarBar />

          <div
            id="js_discover_connect_flow_modal"
            class="c-hidden"
            data-closeable="true"
          >
            <div class="panel0">
              <div class="js_cobalt-inline-modal__title">
                <div class="centered">
                  <p class="c-text-title-md">Overlo Connect</p>
                  <p class="c-text-body-md c-text-subdued">
                    It works like magic! ✨
                  </p>
                </div>
              </div>
              <div
                class="js_cobalt-inline-modal__body"
                style={{ minHeight: "inherit" }}
              >
                <div class="panel-image-container c-mt-xs c-mb-sm">
                  <img width="144" class="lazy" src="assets/car_with_get.svg" />
                </div>
                <div class="c-flex c-mb-sm c-items-center sm:c-mb-lg">
                  <button
                    class="page-navigation-button cob-Button cob-Button__ghost c--ml-sm js_new_car_step_bifurcation_carousel js_cobalt-inline-modal__previous page-navigation-button--hidden"
                    data-selected-creation-flow="connect"
                    data-tracked-slide-index="-1"
                  >
                    <span class="cob-Button__Icon">
                      <span class="cobalt-Icon cobalt-Icon--size32">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </button>
                  <div class="c-text-center c-text-title-sm c-flex-1">
                    A smart device in your car
                  </div>
                  <button
                    class="page-navigation-button cob-Button cob-Button__ghost c--mr-sm js_new_car_step_bifurcation_carousel js_cobalt-inline-modal__next"
                    data-selected-creation-flow="connect"
                    data-tracked-slide-index="1"
                  >
                    <span class="cob-Button__Icon">
                      <span class="cobalt-Icon cobalt-Icon--size32">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
                <div class="panel-description c-text-subdued">
                  No need to meet drivers! Once the Overlo Connect device is
                  installed behind your dashboard, your car can be safely
                  located, locked and unlocked from the app. This smart device
                  measures fuel and mileage to adjust compensation at the end of
                  a rental.
                </div>
              </div>
              <div class="js_cobalt-inline-modal__footer">
                <div class="cobalt-carousel-pager sm:c-py-sm">
                  <div class="cobalt-carousel-pager__wrapper">
                    <div class="cobalt-carousel-pager__page cobalt-carousel-pager__page--active"></div>
                    <div class="cobalt-carousel-pager__page"></div>
                    <div class="cobalt-carousel-pager__page"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel1">
              <div class="js_cobalt-inline-modal__title">
                <div class="centered">
                  <p class="c-text-title-md">Overlo Connect</p>
                  <p class="c-text-body-md c-text-subdued">
                    It works like magic! ✨
                  </p>
                </div>
              </div>
              <div
                class="js_cobalt-inline-modal__body"
                style={{ minHeight: "inherit" }}
              >
                <div class="panel-image-container c-mt-xs c-mb-sm">
                  <img
                    width="144"
                    data-srcset="/packs/images/illustrations/light/character_sitting_on_car_trunk_euros-33f8b944942852bbd013da58b95a91bd.svg 1x, /packs/images/illustrations/light/character_sitting_on_car_trunk_euros-33f8b944942852bbd013da58b95a91bd.svg 2x"
                    data-src="/packs/images/illustrations/light/character_sitting_on_car_trunk_euros-33f8b944942852bbd013da58b95a91bd.svg"
                    class="lazy"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  />
                </div>
                <div class="c-flex c-mb-sm c-items-center sm:c-mb-lg">
                  <button
                    class="page-navigation-button cob-Button cob-Button__ghost c--ml-sm js_new_car_step_bifurcation_carousel js_cobalt-inline-modal__previous"
                    data-selected-creation-flow="connect"
                    data-tracked-slide-index="0"
                  >
                    <span class="cob-Button__Icon">
                      <span class="cobalt-Icon cobalt-Icon--size32">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </button>
                  <div class="c-text-center c-text-title-sm c-flex-1">
                    Earn more money
                  </div>
                  <button
                    class="page-navigation-button cob-Button cob-Button__ghost c--mr-sm js_new_car_step_bifurcation_carousel js_cobalt-inline-modal__next"
                    data-selected-creation-flow="connect"
                    data-tracked-slide-index="2"
                  >
                    <span class="cob-Button__Icon">
                      <span class="cobalt-Icon cobalt-Icon--size32">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
                <div class="panel-description c-text-subdued">
                  Overlo Connect helps you to earn more money by attracting 80%
                  more rentals than key exchange.<br></br>Drivers prefer renting
                  cars equipped with Overlo Connect rather than arranging
                  in-person pickups. It saves them time, too!
                </div>
              </div>
              <div class="js_cobalt-inline-modal__footer">
                <div class="cobalt-carousel-pager sm:c-py-sm">
                  <div class="cobalt-carousel-pager__wrapper">
                    <div class="cobalt-carousel-pager__page"></div>
                    <div class="cobalt-carousel-pager__page cobalt-carousel-pager__page--active"></div>
                    <div class="cobalt-carousel-pager__page"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel2">
              <div class="js_cobalt-inline-modal__title">
                <div class="centered">
                  <p class="c-text-title-md">Overlo Connect</p>
                  <p class="c-text-body-md c-text-subdued">
                    It works like magic! ✨
                  </p>
                </div>
              </div>
              <div
                class="js_cobalt-inline-modal__body"
                style={{ minHeight: "inherit" }}
              >
                <div class="panel-image-container c-mt-xs c-mb-sm">
                  <img
                    width="144"
                    data-srcset="/packs/images/illustrations/light/character_sitting_on_chair_with_computer-e9b1e949a883d130dd6c946fd61b0072.svg 1x, /packs/images/illustrations/light/character_sitting_on_chair_with_computer-e9b1e949a883d130dd6c946fd61b0072.svg 2x"
                    data-src="/packs/images/illustrations/light/character_sitting_on_chair_with_computer-e9b1e949a883d130dd6c946fd61b0072.svg"
                    class="lazy"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  />
                </div>
                <div class="c-flex c-mb-sm c-items-center sm:c-mb-lg">
                  <button
                    class="page-navigation-button cob-Button cob-Button__ghost c--ml-sm js_new_car_step_bifurcation_carousel js_cobalt-inline-modal__previous"
                    data-selected-creation-flow="connect"
                    data-tracked-slide-index="1"
                  >
                    <span class="cob-Button__Icon">
                      <span class="cobalt-Icon cobalt-Icon--size32">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </button>
                  <div class="c-text-center c-text-title-sm c-flex-1">
                    Your car stays safe
                  </div>
                  <button
                    class="page-navigation-button cob-Button cob-Button__ghost c--mr-sm js_new_car_step_bifurcation_carousel js_cobalt-inline-modal__next page-navigation-button--hidden"
                    data-selected-creation-flow="connect"
                    data-tracked-slide-index="3"
                  >
                    <span class="cob-Button__Icon">
                      <span class="cobalt-Icon cobalt-Icon--size32">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
                <div class="panel-description c-text-subdued">
                  We use advanced technology to verify every driver's identity
                  and documents before they rent.<br></br>Before unlocking your
                  car, the driver must take 10 photos from specific angles. They
                  take 10 new photos at the end to ensure the car is returned in
                  the same condition.
                </div>
              </div>
              <div class="js_cobalt-inline-modal__footer">
                <div class="cobalt-carousel-pager sm:c-py-sm">
                  <div class="cobalt-carousel-pager__wrapper">
                    <div class="cobalt-carousel-pager__page"></div>
                    <div class="cobalt-carousel-pager__page"></div>
                    <div class="cobalt-carousel-pager__page cobalt-carousel-pager__page--active"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         <TwoWaysShare/>

          

          <section class="insurance_layer">
            <div class="container">
              <h2 class="insurance_layer__title">All trips are covered by</h2>
              <div class="insurance_layer__items">
                <div class="insurance_layer__item">
                  <div class="insurance_layer__item_title">
                    Comprehensive insurance for all trips
                  </div>
                  <p class="insurance_layer__item_content">
                    You are automatically covered by comprehensive insurance
                    from Overlo Protection, which replaces your own car
                    insurance as soon as a Overlo trip starts.
                  </p>
                </div>
                <div class="insurance_layer__item">
                  <div class="insurance_layer__item_title">
                    Assistance in the event of a breakdown or accident
                  </div>
                  <div class="insurance_layer__item_content">
                    24/7 roadside assistance with the AA in case your vehicle
                    breaks down and needs to be towed.
                  </div>
                </div>
                <div class="insurance_layer__item">
                  <div class="insurance_layer__item_title">
                    A dedicated customer service team
                  </div>
                  <div class="insurance_layer__item_content">
                    Alongside the 24/7 assistance line, you can also contact our
                    customer service team 24/7.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="owner_homepage_pros__layer">
            <div class="container owner_homepage_pros__container">
              <h2 class="owner_homepage_pros__headline">
                You are already a professional or want to become one with
                Overlo?
              </h2>
              <div class="owner_homepage_pros__cards_container">
                <div class="owner_homepage_pros__card_container">
                  <div class="cobalt-Card owner_homepage_pros__card">
                    <div class="cobalt-Card__Section">
                      <div
                        class="owner_homepage_pros_split__image owner_homepage_pros_split__image--private"
                        style={{ backgroundImage: `url(${priva})` }}
                      ></div>
                      <div class="cobalt-Card__Section owner_homepage_pros_split__content">
                        <div class="c-text-title-md c-text-base c-mb-sm">
                          You're an individual...
                        </div>
                        <p class="c-text-body-md c-text-base">
                          and want to earn a living managing your own fleet of
                          cars on Overlo?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="owner_homepage_pros__card_container">
                  <div class="cobalt-Card owner_homepage_pros__card">
                    <div class="cobalt-Card__Section">
                      <div
                        class="owner_homepage_pros_split__image owner_homepage_pros_split__image--entrepreneur"
                        style={{ backgroundImage: `url(${entrepreneur})` }}
                      ></div>
                      <div class="cobalt-Card__Section owner_homepage_pros_split__content">
                        <div class="c-text-title-md c-text-base c-mb-sm">
                          You already run a rental agency, a body shop or a car
                          selling business...
                        </div>
                        <p class="c-text-body-md c-text-base">
                          and want to optimize your fleet occupancy rates?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text_center">
                <a
                  class="cob-Button cob-Button__default cob-Button--large js_tracking_element"
                  data-in-viewport-event="owner_landing_pros_offers_viewed"
                  href="/professionals"
                >
                  Discover our offers for pros
                </a>
              </div>
            </div>
          </section>

          <section class="layer layer_acier_x_light">
            <div class="container">
              <h2 class="c-text-title-lg c-text-base c-mb-md">
                Most frequently asked questions
              </h2>
              <div class="row">
                <div class="col-sm-5 c-mb-sm sm:c-mb-none">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/help/articles/145851ae14c2"
                      >
                        Understanding the requirements for cars and owners
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/help/articles/fbb8a5a2ebc3"
                      >
                        Guide to insurance coverage and conditions
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/help/articles/a89638971941"
                      >
                        Tips for getting more rentals
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/help/articles/f6491db47853"
                      >
                        Guide to citations &amp; traffic tickets
                      </a>
                    </li>
                  </ul>
                  <div class="top20">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      class="cob-Button cob-Button__default js_tracking_element"
                      data-in-viewport-event="owner_landing_faq_viewed"
                      href="/help#owners"
                    >
                      See all questions
                    </a>
                  </div>
                </div>
                <div class="col-sm-6 col-sm-offset-1">
                  <h2>More questions?</h2>
                  <p>We can help you!</p>
                  <p>
                    Take a look at our{" "}
                    <a href="/help#owners">owner help center</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </article>
        
      </div>
    </body>
  );
};

export default RentYourCar;
