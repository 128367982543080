import React from "react";

const Option = ({features}) => {
  return (
  



    <div class="cobalt-layout-section">
    <div class="car_show_options">
    {features?.map((val)=>(

    
      <div class="car_show_options__options_group">
        <div class="car_show_options__option">
          <div class="cobalt-Icon cobalt-Icon--contained cobalt-Icon--size20 cobalt-Icon--colorBase c-mr-xs">
           <img src={val?.image_url}  alt="img"/>
          </div>
          <div class="car_show_options__option_content">
           {val?.feature_name}
          </div>
        </div>
       
        
      </div>
    ))}
    </div>
  </div>);
};

export default Option;
