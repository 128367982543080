import React, { useState, useRef, useEffect, useContext } from "react";
import { DataContext } from "../ContextApi";
import { searchVehicle, searchVehicleWithoutResult } from "../api";

const Dropdown = ({ onSelect, children, heading, vehicleType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const {
    filterValue,
    isFilterActive,
    setSearchVehicles,
    setLoader,
    setFilterValue,
    openDialog,
    setIsFilterActive,
  } = useContext(DataContext);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (!isOpen && !isFilterActive.priceFilterActive) {
      setFilterValue(prevFilterValue => ({
        ...prevFilterValue,
        price: 30,
      }));
    }
    if (!isOpen && !isFilterActive.vehicleTypeFilter) {
      console.log("how its possible")
      setFilterValue(prevFilterValue => ({
        ...prevFilterValue,
        vehicleType: [],
      }));
    }
    if (!isOpen && !isFilterActive.pickupFilter) {
      setFilterValue(prevFilterValue => ({
        ...prevFilterValue,
        driverOption: {},
      }));
    }
  }, [isOpen]);

  const handleResetFilter = (heading) => {
    setIsOpen(false); // Close the dropdown
    if (heading === "Total price") {
      setFilterValue({ ...filterValue, price: 30 });
      searchVehicleWithoutResult(
        { ...filterValue, price: 30 },
        setSearchVehicles,
        setLoader,
        openDialog
      );
      setIsFilterActive({ ...isFilterActive, priceFilterActive: false });
    } else if (heading.includes("Vehicle type")) {
      setFilterValue({ ...filterValue, vehicleType: [] });
      searchVehicleWithoutResult(
        { ...filterValue, vehicleType: [] },
        setSearchVehicles,
        setLoader,
        openDialog
      );
      setIsFilterActive({ ...isFilterActive, vehicleTypeFilter: false });
    } else if (heading === "Driver option") {
      setFilterValue({ ...filterValue, driverOption: {} });
      searchVehicleWithoutResult(
        { ...filterValue, driverOption: {} },
        setSearchVehicles,
        setLoader,
        openDialog
      );
      setIsFilterActive({ ...isFilterActive, pickupFilter: false });
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const selectButton = (heading) => {
    if (heading === "Total price" && isFilterActive.priceFilterActive) {
      return "rgb(255, 201, 71)";
    }
   if (heading.includes("Vehicle type") && isFilterActive.vehicleTypeFilter) {
      return "rgb(255, 201, 71)";
    }
    if (heading === "Driver option" && isFilterActive.pickupFilter) {
      return "rgb(255, 201, 71)";
    }
  };
  return (
    <div ref={dropdownRef}>
      <div
        class="pill-filter"
        data-filter-id="price_max"
        onClick={toggleDropdown}
      >
        <button
          class="c-whitespace-nowrap with-icon cob-Button cob-Button--rounded cob-Button__default"
          aria-expanded="false"
          style={{ backgroundColor: selectButton(heading) }}
        >
          <span class="cob-Button__content">
            <span class="c-flex">{heading}</span>
          </span>
          <span class="cob-Button__Icon cob-Button__Icon--right">
            <span class="cobalt-Icon cobalt-Icon--chevronDown">
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.41 8.58 12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42Z"
                ></path>
              </svg>
            </span>
          </span>
        </button>
      </div>

      {isOpen && (
        <div
          style={{ width: heading === "Total price" ? "300px" : "" }}
          className="dropdown-menu-custom"
        >
          <p className="p-dropdown">{heading}</p>

          {children}
          <div className="drop-down-last-button">
            <button
              style={{
                color: "#999",
                borderColor: "#ccc",
                backgroundColor: "transparent",
              }}
              className="button-div"
              onClick={() => handleResetFilter(heading)}
            >
              Reset
            </button>
            <button
              onClick={() => {
                onSelect();
                setIsOpen(false);
              }}
              style={{
                color: "#090068",
                backgroundColor: "#ffb400",
                border: "none",
              }}
              className="button-div"
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
