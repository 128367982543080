import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const Milage = () => {
  const { setSelectDataCaseTwo, selectDataCaseTwo } = useContext(DataContext);
  const [selectedExtraId, setSelectedExtraId] = useState(null);
  const handlePricingChange = (event) => {
    setSelectedExtraId(event.target.value);
  };

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Is the mileage accurate?</div>
        {/* <div class="c-mb-lg">
          <div class="cobalt-FormField" data-form-field-method="mileage">
            <label class="cobalt-FormField__Label" for="car_mileage">
              Mileage
            </label>
            <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="car[mileage]"
                id="car_mileage"
              >
                <option value="1">0-15,000 km</option>
                <option value="2">15-50,000 km</option>
                <option value="3">50-100,000 km</option>
                <option value="4">100-150,000 km</option>
                <option selected="selected" value="5">
                  150-200,000 km
                </option>
                <option value="6">+200,000 km</option>
              </select>
            </div>
          </div>
        </div> */}
        <div class="cobalt-FormField" data-form-field-method="plate_number">
          <label class="cobalt-FormField__Label" for="car_plate_number">
            Price{" "}
          </label>
          <div class="cobalt-TextField">
            <input
              required
              class="cobalt-TextField__Input"
              type="number"
              name="price"
              style={{fontSize:"25px",fontWeight:"800"}}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "+") {
                  e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                }
              }}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only positive numbers
                if (value >= 0) {
                  handleChange(
                    e,
                    setSelectDataCaseTwo,
                    selectDataCaseTwo,
                    "caseTwo"
                  );
                }
              }}
              value={selectDataCaseTwo.price}
            />
          </div>
        </div>
        {selectDataCaseTwo.price&&
        <div style={{fontSize:"50px",textAlign:"center",fontWeight:"700"}}>
          $ {selectDataCaseTwo.price}
        </div>
        }
        <div class="cobalt-FormField">
          <label class="cobalt-FormField__Label" for="car_transmission">
            Extra Pricing
          </label>
          <div
            class="cobalt-CheckmarkField"
            data-form-checkmark-method="transmission"
          >
            <label class="cobalt-CheckmarkField__LabelWrapper">
              <input
                class="cobalt-CheckmarkField__Input"
                type="radio"
                value="per_km"
                checked={selectedExtraId === "per_km"}
                onChange={handlePricingChange}
                id="car_transmission_1"
              />
              <span class="cobalt-CheckmarkField__Checkmark"></span>
              <span class="cobalt-CheckmarkField__Label">Per Km</span>
            </label>
          </div>
          <div
            class="cobalt-CheckmarkField"
            data-form-checkmark-method="transmission"
          >
            <label class="cobalt-CheckmarkField__LabelWrapper">
              <input
                class="cobalt-CheckmarkField__Input"
                type="radio"
                value="region" // Example value, replace with your actual value
                checked={selectedExtraId === "region"} // Adjust this check as per your logic
                onChange={handlePricingChange}
                id="car_transmission_1"
              />
              <span class="cobalt-CheckmarkField__Checkmark"></span>
              <span class="cobalt-CheckmarkField__Label">Region</span>
            </label>
          </div>
        </div>
        {selectedExtraId === "per_km" ? (
          <>
            <div class="cobalt-FormField" data-form-field-method="plate_number">
              <label class="cobalt-FormField__Label" for="car_plate_number">
                Maximum kilometer offer by day{" "}
              </label>
              <div class="cobalt-TextField">
                <input
                  required
                  class="cobalt-TextField__Input"
                  type="number"
                  name="max_km_offered_per_day"
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "e" || e.key === "+") {
                      e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only positive numbers
                    if (value >= 0) {
                      handleChange(
                        e,
                        setSelectDataCaseTwo,
                        selectDataCaseTwo,
                        "caseTwo"
                      );
                    }
                  }}
                  value={selectDataCaseTwo.max_km_offered_per_day}
                />
              </div>
            </div>
            <div class="cobalt-FormField" data-form-field-method="plate_number">
              <label class="cobalt-FormField__Label" for="car_plate_number">
                Maximum maximum kilometer exceeded{" "}
              </label>
              <div class="cobalt-TextField">
                <input
                  required
                  class="cobalt-TextField__Input"
                  type="number"
                  name="price_per_km_max_exceeded"
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "e" || e.key === "+") {
                      e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only positive numbers
                    if (value >= 0) {
                      handleChange(
                        e,
                        setSelectDataCaseTwo,
                        selectDataCaseTwo,
                        "caseTwo"
                      );
                    }
                  }}
                  value={selectDataCaseTwo.price_per_km_max_exceeded}
                />
              </div>
            </div>
          </>
        ) : selectedExtraId === "region" ? (
          <div class="cobalt-FormField" data-form-field-method="plate_number">
            <label class="cobalt-FormField__Label" for="car_plate_number">
              Extra Price for out of region{" "}
            </label>
            <div class="cobalt-TextField">
              <input
                required
                class="cobalt-TextField__Input"
                type="number"
                name="price_outof_region"
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "+") {
                    e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value;

                  // Allow only positive numbers
                  if (value >= 0) {
                    handleChange(
                      e,
                      setSelectDataCaseTwo,
                      selectDataCaseTwo,
                      "caseTwo"
                    );
                  }
                }}
                value={selectDataCaseTwo?.price_outof_region}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div class="cobalt-Note cobalt-Note--fullWidth">
          This information allows us to ensure the quality and safety of our
          fleet.
        </div>
      </div>
    </>
  );
};

export default Milage;
