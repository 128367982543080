import React from "react";
import userPhoto from "../../../../image/png/user_photo.jpeg" 
import avator from  "../../../../image/png/avatar.png"
import character from "../../../../image/png/character_with3.svg"
import rent from "../../../../image/png/rent.png"
const Verify = () => {
  return (
    <div class="col-sm-9 col-xs-12">
    <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
      <div class="cobalt-Card__Section">
        <div class="tab_panes_container">
          <div class="tab_pane active tab_profile_verification_status">
            <div class="visible-xs download_the_app__mobile_container"
              style={{  backgroundImage: `url(${rent})`,
              }}>
              <div class="row">
                <div class="col-xs-12">
                  <div class="cobalt-Callout cobalt-Callout--error c-mb-lg">
                    <div class="cobalt-BasicCell">
                      <div class="cobalt-BasicCell__LeftAddon">
                        <div class="cobalt-BasicCell__Thumb">
                          <img alt="Elhadji Ibrahima" class="cobalt-avatar"
                            src={userPhoto}/>
                        </div>
                      </div>
                      <div class="cobalt-BasicCell__Main">
                        <div class="cobalt-BasicCell__Title">
                          Elhadji Ibrahima Ba
                        </div>
                        <div class="cobalt-BasicCell__Content">
                          Your profile is not verified yet.
                        </div>
                      </div>
                    </div>
                  </div>

                  <p class="c-text-body-md c-mb-lg">
                    Open the Getaround app to start the verification and to check the verification status.

                  </p>
                </div>
              </div>
              <div class="download_the_app__sticky_cta_wrapper">
                <div class="cobalt-Card">
                  <div class="cobalt-Card__Section">
                    <a class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_download_the_app"
                      href="/mobile">Download the app</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden-xs cobalt-Card__Section">
              <div class="download_the_app__container"
style={{  backgroundImage: `url(${rent})`,
}}                >
                <div class="row">
                  <div class="col-sm-6">
                    <div class="download_the_app__description_container">
                      <h2 class="c-text-title-lg c-mb-md">
                        Verify your profile
                      </h2>
                      <p class="c-text-body-lg c-mb-lg">
                        Open the Getaround app to start the verification and to check the verification
                        status.

                      </p>
                      <div class="cobalt-Callout cobalt-Callout--error c-mb-lg">
                        <div class="cobalt-BasicCell">
                          <div class="cobalt-BasicCell__LeftAddon">
                            <div class="cobalt-BasicCell__Thumb">
                              <img alt="Elhadji Ibrahima" class="cobalt-avatar"
                                src={avator}/>
                            </div>
                          </div>
                          <div class="cobalt-BasicCell__Main">
                            <div class="cobalt-BasicCell__Title">
                              Elhadji Ibrahima Ba
                            </div>
                            <div class="cobalt-BasicCell__Content">
                              Your profile is not verified yet.
                            </div>
                          </div>
                        </div>
                      </div>

                      <h4 class="c-text-title-sm c-mb-md">
                        Don't have the app yet?
                      </h4>
                      <a class="cob-Button cob-Button__default cob-Button__default--primary c-mb-sm js_download_the_app"
                        href="/mobile">Download the app</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cobalt-Card__Section cobalt-Card__Section--divided">
              <div class="why_verify_your_profile__container">
                <div class="why_verify_your_profile__image_container">
                  <img class="cobalt-illustration-image " role="presentation" alt="hand_checking_phone"
                    width="280" lazyload="false"
                   src={character}/>
                </div>
                <div class="why_verify_your_profile__description_container">
                  <h3 class="c-text-title-md c-mb-md">
                    Why verify your profile?
                  </h3>
                  <ul class="cobalt-BulletList">
                    <li class="cobalt-BulletListItem c-mb-md">
                      <span class="cobalt-BulletListItem__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSuccess">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                              fill-rule="evenodd"></path>
                          </svg>

                        </span>
                      </span>
                      <div class="cobalt-BulletListItem__Content">
                        <p class="c-text-body-md c-text-subdued">
                          Required to access self-service cars
                        </p>
                      </div>
                    </li>

                    <li class="cobalt-BulletListItem c-mb-md">
                      <span class="cobalt-BulletListItem__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSuccess">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                              fill-rule="evenodd"></path>
                          </svg>

                        </span>
                      </span>
                      <div class="cobalt-BulletListItem__Content">
                        <p class="c-text-body-md c-text-subdued">
                          Do it once, save time for your next booking
                        </p>
                      </div>
                    </li>

                    <li class="cobalt-BulletListItem c-mb-md">
                      <span class="cobalt-BulletListItem__Icon">
                        <span class="cobalt-Icon cobalt-Icon--colorSuccess">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                              fill-rule="evenodd"></path>
                          </svg>

                        </span>
                      </span>
                      <div class="cobalt-BulletListItem__Content">
                        <p class="c-text-body-md c-text-subdued">
                          Verifications help us keep the platform secure both for renters and owners
                        </p>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

)
};

export default Verify;
