import React from "react";
import { useNavigate } from "react-router-dom";
import {  menuArray } from "../../../ExportFiles";

const SubHeader = () => {
  const navigate = useNavigate();

  const checkCurrentPath = (val) => {
    console.log("pathname", val, window.location.pathname);

    if (window.location.pathname === val) {
      return true;
    } else {
      return false;
    }
  };
  const filteredMenuArray = menuArray.filter((val) => {
    let storedRoles = localStorage.getItem('roles');

    if (storedRoles !== null && storedRoles !== undefined) {
      try {
        storedRoles = JSON.parse(storedRoles);
      } catch (error) {
        console.error('Failed to parse JSON from localStorage:', error);
        storedRoles = {};  // Default to an empty object if parsing fails
      }
    } else {
      storedRoles = {};  // Default to an empty object if nothing is stored
    }
    
    if (val) {
      if (val.id === 9 || val.id === 10) {
        return false;
      }
      return storedRoles[val?.name.toLowerCase()] === "1";
    }
  }
);
  return (
    <div class="tab_bar_full_width_container c-mb-lg">
      <div class="container no-outer-gutter-sm">
        <div class="cobalt-TabBar js_dashboard_nav_tab_bar">
          <div class="cobalt-TabBar__ScrollControl js_tab_bar_scroll_control">
            <button
              style={{ display: "none" }}
              class="cobalt-TabBar__ScrollButton"
              hidden=""
            >
              <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
          <div
            class="cobalt-TabScroller js_tab_bar_scroller"
            style={{ height: "68px" }}
          >
            <div
              class="cobalt-TabScroller__ScrollArea js_tab_bar_scroll_area cobalt-TabScroller__ScrollArea--leftAlign"
              style={{ paddingBottom: "30px" }}
            >
              {filteredMenuArray.map((val) =>
                val.id === 9 || val.id === 10 ? null : ( // Render nothing for id 9 or 10
                  <a
                    key={val.id}
                    onClick={() => navigate(val.path)}
                    className="cobalt-Tab"
                    aria-selected={
                      checkCurrentPath(`/main-menu/${val.path}`) ||
                      (val.name === "Accounts" &&
                        window.location.pathname.includes(
                          "/main-menu/accounts/"
                        )) ||
                        (val.name === "Cars" && window.location.pathname.includes("/main-menu/cars/"))

                    }
                  >
                    <div className="cobalt-Tab__Content">
                      <div>{val.name}</div>
                    </div>
                  </a>
                )
              )}
            </div>
          </div>
          <div
            class="cobalt-TabBar__ScrollControl js_tab_bar_scroll_control"
            data-direction="right"
            hidden=""
          >
            <button
              style={{ display: "none" }}
              class="cobalt-TabBar__ScrollButton"
              hidden=""
            >
              <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
