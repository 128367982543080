import React, { useContext, useState, useEffect, useRef } from "react";
import logo from "../image/png/logo.svg";
import { AuthContext } from "../ContextApi/AuthContext";
import { DataContext } from "../ContextApi";
import { useNavigate } from "react-router-dom";
import { hardlyLogout, sendCodeVerify, sendPhoneVerify } from "../api";
import { Alert } from "@mui/material";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils.js";
import ReCAPTCHA from "react-google-recaptcha";
const Signup = () => {
  const navigate = useNavigate();
  const { signUp, isLoggedIn, loading } = useContext(AuthContext);
  const { setLoader, openDialog, setAlert, alert } = useContext(DataContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [pinShow, setAllAccountPinShow] = useState(false);
  const [verify, setVerify] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const [lastName, setLastName] = useState("");

  const phoneInputRef = useRef(null);
  const [countryCode, setCountryCode] = useState("+93"); // Default code, can be adjusted
  const [phoneNumber, setPhoneNumber] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCountryCodeChange = (e) => {
    console.log("hmmmm", e.target.value);
    setCountryCode(e.target.value);
  };
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };
  // Example function to toggle the privacy policy acceptance
const togglePrivacyPolicy = () => {
  setPrivacyAccepted(!privacyAccepted); // Toggles between true and false
};


  useEffect(() => {
    const loadIntlTelInput = async () => {
      const intlTelInput = (await import("intl-tel-input")).default;

      if (phoneInputRef.current) {
        intlTelInput(phoneInputRef.current, {
          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });
      }
    };

    loadIntlTelInput();
  }, []);
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setAlert("Please complete the CAPTCHA");
      setTimeout(() => {
        setAlert("");
      }, 5000);
      return;
    }
    if(!privacyAccepted)
    {
      setAlert("Please add privacy policy");
      setTimeout(() => {
        setAlert("");
      }, 5000);
      return
    }

    setLoader(true);

    const concatenatedPhone = `${countryCode} ${phoneNumber}`;
    // captcha: captchaValue,

    try {
      const response = await signUp(
        firstName,
        lastName,
        email,
        concatenatedPhone,
        password,
        captchaValue
      );

      setLoader(false);
      console.log("messss", response.data.message);
      openDialog(response.data.message);
      setEmail("");
      setPassword("");
      setPhoneNumber("");
      setFirstName("");
      setLastName("");
      setPrivacyAccepted(false)
      setVerify(false);
      setAllAccountPinShow(false);
    } catch (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }

      setLoader(false);

      setAlert(response?.response?.data?.message);

      setTimeout(() => {
        setAlert("");
      }, 5000);
    }
  };

  const handlePhoneInputBlur = async () => {
    setAllAccountPinShow(true);
    const concatenatedPhone = `${countryCode} ${phoneNumber}`;

    let payload = {
      phone: concatenatedPhone,
    };
    try {
      const response = await sendPhoneVerify(payload);
      // handleClickSnackbar(response?.data?.message);
      setLoader(true);
      setPin(response?.data?.response?.code);
    } catch (response) {
      setAlert(response?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (!loading && isLoggedIn) {
      navigate("/");
    }
  }, [loading, isLoggedIn, navigate]);
  const sendCodeVerifyNow = async () => {
    const response = await sendCodeVerify(pin);
    setVerify(true);
    // handleClickSnackbar(response?.data?.message);
  };
  useEffect(() => {
    if (pin.length === 6) {
      sendCodeVerifyNow();
    }
  }, [pin]);

  return (
    <>
      <div className="js_site_content site_content">
        <div className="authentication_layout">
          <div className="authentication_layout__section authentication_layout__section--primary">
            <div className="authentication_layout__logo hidden-xs authentication_layout__logo--rebranded">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="visible-xs authentication_layout__close">
              <a className="link_no_style" aria-label="Close" href="/">
                ✕
              </a>
            </div>
            <div className="authentication_layout__content c-mb-md">
              <div className="cobalt-TabBar c-mb-xl js_registration_session_tab_bar">
                <div
                  className="cobalt-TabBar__ScrollControl js_tab_bar_scroll_control"
                  data-direction="left"
                  hidden=""
                  style={{ display: "none" }}
                >
                  <button className="cobalt-TabBar__ScrollButton" hidden="">
                    <div className="cobalt-Icon cobalt-Icon--colorSubdued">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
                <div
                  className="cobalt-TabScroller js_tab_bar_scroller cobalt-TabScroller--underlined"
                  style={{ height: "68px" }}
                >
                  <div
                    className="cobalt-TabScroller__ScrollArea js_tab_bar_scroll_area"
                    style={{ paddingBottom: "30px" }}
                  >
                    <a
                      className="cobalt-Tab"
                      aria-selected="false"
                      onClick={() => navigate("/login")}
                    >
                      <div className="cobalt-Tab__Content">Log in</div>
                    </a>
                    <a
                      className="cobalt-Tab"
                      aria-selected="true"
                      onClick={() => navigate("/sign-up")}
                    >
                      <div className="cobalt-Tab__Content">Sign up</div>
                    </a>
                  </div>
                </div>
                <div
                  className="cobalt-TabBar__ScrollControl js_tab_bar_scroll_control"
                  data-direction="right"
                  hidden=""
                  style={{ display: "none" }}
                >
                  <button className="cobalt-TabBar__ScrollButton" hidden="">
                    <div className="cobalt-Icon cobalt-Icon--colorSubdued">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>

              <div className="authentication_header">
                <div className="authentication_header__title">
                  Sign up your account
                </div>
              </div>

              <form
                className="new_user"
                id="new_user"
                onSubmit={handleSignUp}
                data-gtm-form-interact-id="0"
              >
                <input
                  type="hidden"
                  name="authenticity_token"
                  value="fiFcU8Hgha_dprmmZQP69VBIApLnbBqh6hWVO-ZvpgaUHQ4WWBlYIyFAxfrMmp9YWxeDudvstyY9p0pTuW2XSg"
                  autoComplete="off"
                />
                <div
                  className="cobalt-FormField"
                  data-form-field-method="email"
                >
                  <div className="cobalt-TextField cobalt-TextField--withIcon">
                    <input
                      placeholder="First Name"
                      className="cobalt-TextField__Input"
                      type="text"
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                    <span class="cobalt-TextField__Icon cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m12 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 10c4.42 0 8 1.79 8 4v2h-16v-2c0-2.21 3.58-4 8-4z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  className="cobalt-FormField"
                  data-form-field-method="email"
                >
                  <div className="cobalt-TextField cobalt-TextField--withIcon">
                    <input
                      placeholder="Last Name"
                      className="cobalt-TextField__Input"
                      type="text"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      id="user_email"
                    />
                    <span class="cobalt-TextField__Icon cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m12 4a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 10c4.42 0 8 1.79 8 4v2h-16v-2c0-2.21 3.58-4 8-4z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  className="cobalt-FormField"
                  data-form-field-method="email"
                >
                  <div className="cobalt-TextField cobalt-TextField--withIcon">
                    <input
                      placeholder="Email"
                      className="cobalt-TextField__Input"
                      type="email"
                      required
                      name="user[email]"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="user_email"
                      data-gtm-form-interact-field-id="0"
                    />
                    <span className="cobalt-TextField__Icon cobalt-Icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 Z M20,4 L4,4 C2.89,4 2,4.89 2,6 L2,18 C2,19.1045695 2.8954305,20 4,20 L20,20 C21.1045695,20 22,19.1045695 22,18 L22,6 C22,4.89 21.1,4 20,4 Z"></path>
                      </svg>
                    </span>
                  </div>
                </div>

                <div class="c-mb-lg">
                  <div
                    class="cobalt-FormField"
                    data-form-field-method="phone_country_phone_number_national"
                  >
                    <label class="cobalt-FormField__Label"></label>
                    <div class="cobalt-ComposedFormField">
                      <div className="cobalt-TextField cobalt-TextField--withIcon">
                        <select
                          class="cobalt-SelectField__Input"
                          name="car[phone_country]"
                          id="car_phone_country"
                          onChange={handleCountryCodeChange}
                          value={countryCode}
                          style={{
                            paddingLeft: verify ? "37px" : "",
                            borderColor: verify ? "#4CAF50" : "",
                          }}
                        >
                          <option value="AF">Afghanistan (+93)</option>
                          <option value="AL">Albania (+355)</option>
                          <option value="DZ">Algeria (+213)</option>
                          <option value="AS">American Samoa (+1)</option>
                          <option value="AD">Andorra (+376)</option>
                          <option value="AO">Angola (+244)</option>
                          <option value="AI">Anguilla (+1)</option>
                          <option value="AG">Antigua &amp; Barbuda (+1)</option>
                          <option value="AR">Argentina (+54)</option>
                          <option value="AM">Armenia (+374)</option>
                          <option value="AW">Aruba (+297)</option>
                          <option value="AU">Australia (+61)</option>
                          <option value="AT">Austria (+43)</option>
                          <option value="AZ">Azerbaijan (+994)</option>
                          <option value="BS">Bahamas (+1)</option>
                          <option value="BH">Bahrain (+973)</option>
                          <option value="BD">Bangladesh (+880)</option>
                          <option value="BB">Barbados (+1)</option>
                          <option value="BY">Belarus (+375)</option>
                          <option value="BE">Belgium (+32)</option>
                          <option value="BZ">Belize (+501)</option>
                          <option value="BJ">Benin (+229)</option>
                          <option value="BM">Bermuda (+1)</option>
                          <option value="BT">Bhutan (+975)</option>
                          <option value="BO">Bolivia (+591)</option>
                          <option value="BA">
                            Bosnia &amp; Herzegovina (+387)
                          </option>
                          <option value="BW">Botswana (+267)</option>
                          <option value="BR">Brazil (+55)</option>
                          <option value="IO">
                            British Indian Ocean Territory (+246)
                          </option>
                          <option value="VG">
                            British Virgin Islands (+1)
                          </option>
                          <option value="BN">Brunei (+673)</option>
                          <option value="BG">Bulgaria (+359)</option>
                          <option value="BF">Burkina Faso (+226)</option>
                          <option value="BI">Burundi (+257)</option>
                          <option value="KH">Cambodia (+855)</option>
                          <option value="CM">Cameroon (+237)</option>
                          <option value="CA">Canada (+1)</option>
                          <option value="CV">Cape Verde (+238)</option>
                          <option value="KY">Cayman Islands (+1)</option>
                          <option value="CF">
                            Central African Republic (+236)
                          </option>
                          <option value="TD">Chad (+235)</option>
                          <option value="CL">Chile (+56)</option>
                          <option value="CN">China (+86)</option>
                          <option value="CX">Christmas Island (+61)</option>
                          <option value="CC">
                            Cocos (Keeling) Islands (+61)
                          </option>
                          <option value="CO">Colombia (+57)</option>
                          <option value="KM">Comoros (+269)</option>
                          <option value="CG">Congo - Brazzaville (+242)</option>
                          <option value="CD">Congo - Kinshasa (+243)</option>
                          <option value="CK">Cook Islands (+682)</option>
                          <option value="CR">Costa Rica (+506)</option>
                          <option value="CI">Côte d’Ivoire (+225)</option>
                          <option value="HR">Croatia (+385)</option>
                          <option value="CU">Cuba (+53)</option>
                          <option value="CY">Cyprus (+357)</option>
                          <option value="CZ">Czech Republic (+420)</option>
                          <option value="DK">Denmark (+45)</option>
                          <option value="DJ">Djibouti (+253)</option>
                          <option value="DM">Dominica (+1)</option>
                          <option value="DO">Dominican Republic (+1)</option>
                          <option value="EC">Ecuador (+593)</option>
                          <option value="EG">Egypt (+20)</option>
                          <option value="SV">El Salvador (+503)</option>
                          <option value="GQ">Equatorial Guinea (+240)</option>
                          <option value="EE">Estonia (+372)</option>
                          <option value="ET">Ethiopia (+251)</option>
                          <option value="FK">Falkland Islands (+500)</option>
                          <option value="FO">Faroe Islands (+298)</option>
                          <option value="FJ">Fiji (+679)</option>
                          <option value="FI">Finland (+358)</option>
                          <option value="FR">France (+33)</option>
                          <option value="GF">French Guiana (+594)</option>
                          <option value="PF">French Polynesia (+689)</option>
                          <option value="GA">Gabon (+241)</option>
                          <option value="GM">Gambia (+220)</option>
                          <option value="GE">Georgia (+995)</option>
                          <option value="DE">Germany (+49)</option>
                          <option value="GH">Ghana (+233)</option>
                          <option value="GI">Gibraltar (+350)</option>
                          <option value="GR">Greece (+30)</option>
                          <option value="GL">Greenland (+299)</option>
                          <option value="GD">Grenada (+1)</option>
                          <option value="GP">Guadeloupe (+590)</option>
                          <option value="GU">Guam (+1)</option>
                          <option value="GT">Guatemala (+502)</option>
                          <option value="GN">Guinea (+224)</option>
                          <option value="GW">Guinea-Bissau (+245)</option>
                          <option value="GY">Guyana (+592)</option>
                          <option value="HT">Haiti (+509)</option>
                          <option value="HN">Honduras (+504)</option>
                          <option value="HK">Hong Kong SAR China (+852)</option>
                          <option value="HU">Hungary (+36)</option>
                          <option value="IS">Iceland (+354)</option>
                          <option value="IN">India (+91)</option>
                          <option value="ID">Indonesia (+62)</option>
                          <option value="IR">Iran (+98)</option>
                          <option value="IQ">Iraq (+964)</option>
                          <option value="IE">Ireland (+353)</option>
                          <option value="IL">Israel (+972)</option>
                          <option value="IT">Italy (+39)</option>
                          <option value="JM">Jamaica (+1)</option>
                          <option value="JP">Japan (+81)</option>
                          <option value="JO">Jordan (+962)</option>
                          <option value="KZ">Kazakhstan (+7)</option>
                          <option value="KE">Kenya (+254)</option>
                          <option value="KI">Kiribati (+686)</option>
                          <option value="XK">Kosovo (+383)</option>
                          <option value="KW">Kuwait (+965)</option>
                          <option value="KG">Kyrgyzstan (+996)</option>
                          <option value="LA">Laos (+856)</option>
                          <option value="LV">Latvia (+371)</option>
                          <option value="LB">Lebanon (+961)</option>
                          <option value="LS">Lesotho (+266)</option>
                          <option value="LR">Liberia (+231)</option>
                          <option value="LY">Libya (+218)</option>
                          <option value="LI">Liechtenstein (+423)</option>
                          <option value="LT">Lithuania (+370)</option>
                          <option value="LU">Luxembourg (+352)</option>
                          <option value="MO">Macau SAR China (+853)</option>
                          <option value="MK">Macedonia (+389)</option>
                          <option value="MG">Madagascar (+261)</option>
                          <option value="MW">Malawi (+265)</option>
                          <option value="MY">Malaysia (+60)</option>
                          <option value="MV">Maldives (+960)</option>
                          <option value="ML">Mali (+223)</option>
                          <option value="MT">Malta (+356)</option>
                          <option value="MH">Marshall Islands (+692)</option>
                          <option value="MQ">Martinique (+596)</option>
                          <option value="MR">Mauritania (+222)</option>
                          <option value="MU">Mauritius (+230)</option>
                          <option value="YT">Mayotte (+262)</option>
                          <option value="MX">Mexico (+52)</option>
                          <option value="FM">Micronesia (+691)</option>
                          <option value="MD">Moldova (+373)</option>
                          <option value="MC">Monaco (+377)</option>
                          <option value="MN">Mongolia (+976)</option>
                          <option value="ME">Montenegro (+382)</option>
                          <option value="MS">Montserrat (+1)</option>
                          <option value="MA">Morocco (+212)</option>
                          <option value="MZ">Mozambique (+258)</option>
                          <option value="MM">Myanmar (Burma) (+95)</option>
                          <option value="NA">Namibia (+264)</option>
                          <option value="NR">Nauru (+674)</option>
                          <option value="NP">Nepal (+977)</option>
                          <option value="NL">Netherlands (+31)</option>
                          <option value="NC">New Caledonia (+687)</option>
                          <option value="NZ">New Zealand (+64)</option>
                          <option value="NI">Nicaragua (+505)</option>
                          <option value="NE">Niger (+227)</option>
                          <option value="NG">Nigeria (+234)</option>
                          <option value="NU">Niue (+683)</option>
                          <option value="NF">Norfolk Island (+672)</option>
                          <option value="KP">North Korea (+850)</option>
                          <option value="MP">
                            Northern Mariana Islands (+1)
                          </option>
                          <option value="NO">Norway (+47)</option>
                          <option value="OM">Oman (+968)</option>
                          <option selected="selected" value="PK">
                            Pakistan (+92)
                          </option>
                          <option value="PW">Palau (+680)</option>
                          <option value="PS">
                            Palestinian Territories (+970)
                          </option>
                          <option value="PA">Panama (+507)</option>
                          <option value="PG">Papua New Guinea (+675)</option>
                          <option value="PY">Paraguay (+595)</option>
                          <option value="PE">Peru (+51)</option>
                          <option value="PH">Philippines (+63)</option>
                          <option value="PL">Poland (+48)</option>
                          <option value="PT">Portugal (+351)</option>
                          <option value="PR">Puerto Rico (+1)</option>
                          <option value="QA">Qatar (+974)</option>
                          <option value="RE">Réunion (+262)</option>
                          <option value="RO">Romania (+40)</option>
                          <option value="RU">Russia (+7)</option>
                          <option value="RW">Rwanda (+250)</option>
                          <option value="WS">Samoa (+685)</option>
                          <option value="SM">San Marino (+378)</option>
                          <option value="ST">
                            São Tomé &amp; Príncipe (+239)
                          </option>
                          <option value="SA">Saudi Arabia (+966)</option>
                          <option value="SN">Senegal (+221)</option>
                          <option value="RS">Serbia (+381)</option>
                          <option value="SC">Seychelles (+248)</option>
                          <option value="SL">Sierra Leone (+232)</option>
                          <option value="SG">Singapore (+65)</option>
                          <option value="SK">Slovakia (+421)</option>
                          <option value="SI">Slovenia (+386)</option>
                          <option value="SB">Solomon Islands (+677)</option>
                          <option value="SO">Somalia (+252)</option>
                          <option value="ZA">South Africa (+27)</option>
                          <option value="KR">South Korea (+82)</option>
                          <option value="ES">Spain (+34)</option>
                          <option value="LK">Sri Lanka (+94)</option>
                          <option value="SH">St. Helena (+290)</option>
                          <option value="KN">St. Kitts &amp; Nevis (+1)</option>
                          <option value="LC">St. Lucia (+1)</option>
                          <option value="PM">
                            St. Pierre &amp; Miquelon (+508)
                          </option>
                          <option value="VC">
                            St. Vincent &amp; Grenadines (+1)
                          </option>
                          <option value="SD">Sudan (+249)</option>
                          <option value="SR">Suriname (+597)</option>
                          <option value="SJ">
                            Svalbard &amp; Jan Mayen (+47)
                          </option>
                          <option value="SZ">Swaziland (+268)</option>
                          <option value="SE">Sweden (+46)</option>
                          <option value="CH">Switzerland (+41)</option>
                          <option value="SY">Syria (+963)</option>
                          <option value="TW">Taiwan (+886)</option>
                          <option value="TJ">Tajikistan (+992)</option>
                          <option value="TZ">Tanzania (+255)</option>
                          <option value="TH">Thailand (+66)</option>
                          <option value="TG">Togo (+228)</option>
                          <option value="TK">Tokelau (+690)</option>
                          <option value="TO">Tonga (+676)</option>
                          <option value="TT">Trinidad &amp; Tobago (+1)</option>
                          <option value="TN">Tunisia (+216)</option>
                          <option value="TR">Turkey (+90)</option>
                          <option value="TM">Turkmenistan (+993)</option>
                          <option value="TC">
                            Turks &amp; Caicos Islands (+1)
                          </option>
                          <option value="TV">Tuvalu (+688)</option>
                          <option value="VI">U.S. Virgin Islands (+1)</option>
                          <option value="UG">Uganda (+256)</option>
                          <option value="UA">Ukraine (+380)</option>
                          <option value="AE">
                            United Arab Emirates (+971)
                          </option>
                          <option value="GB">United Kingdom (+44)</option>
                          <option value="US">United States (+1)</option>
                          <option value="UY">Uruguay (+598)</option>
                          <option value="UZ">Uzbekistan (+998)</option>
                          <option value="VU">Vanuatu (+678)</option>
                          <option value="VA">Vatican City (+39)</option>
                          <option value="VE">Venezuela (+58)</option>
                          <option value="VN">Vietnam (+84)</option>
                          <option value="WF">Wallis &amp; Futuna (+681)</option>
                          <option value="EH">Western Sahara (+212)</option>
                          <option value="YE">Yemen (+967)</option>
                          <option value="ZM">Zambia (+260)</option>
                          <option value="ZW">Zimbabwe (+263)</option>
                        </select>
                        {/* {verify && (
                          <span className="cobalt-TextField__Icon cobalt-Icon">
                            <img
                              style={{ width: "24px" }}
                              src={verifyTick}
                              alt="verify"
                            />
                          </span>
                        )} */}
                      </div>
                      <div class="cobalt-TextField">
                        <input
                          type="tel"
                          className="cobalt-TextField__Input"
                          placeholder="Phone number"
                          style={{ borderColor: verify ? "#4CAF50" : "" }}
                          value={phoneNumber}
                          name="car[phone_number_national]"
                          id="car_phone_number_national"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          onBlur={handlePhoneInputBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* {pinShow && !verify && (
                  <div
                    className="cobalt-FormField"
                    data-form-field-method="pin"
                  >
                    <div className="cobalt-TextField cobalt-TextField--withIcon">
                      <p
                        className="authentication_header__title"
                        style={{
                          fontFamily: "'BrownProMarketing' !important",
                          marginBottom: "10px",
                        }}
                      >
                        Verification Pin
                      </p>
                      <HStack spacing="px">
                        <PinInput
                          size="sm"
                          type="alphanumeric"
                          // mask
                          onChange={(value) => {
                            setPin(value);
                            setPinError(value.length < 6);
                          }}
                          value={pin}
                          autoComplete="off" // Disable autofill for the entire PinInput
                        >
                          <PinInputField size="sm" autoComplete="off" />
                          <PinInputField size="sm" autoComplete="off" />
                          <PinInputField size="sm" autoComplete="off" />

                          <PinInputField size="sm" autoComplete="off" />

                          <PinInputField size="sm" autoComplete="off" />
                          <PinInputField size="sm" autoComplete="off" />
                        </PinInput>
                      </HStack>
                      {pinError && (
                        <Box color="red" mt="2">
                          Please enter a 6-digit PIN.
                        </Box>
                      )}
                    </div>
                  </div>
                )} */}
                <div
                  className="cobalt-FormField"
                  data-form-field-method="password"
                >
                  <div className="cobalt-TextField cobalt-TextField--withIcon">
                    <input
                      placeholder="Password"
                      className="cobalt-TextField__Input"
                      type="password"
                      required
                      value={password}
                      name="user[password]"
                      onChange={(e) => setPassword(e.target.value)}
                      id="user_password"
                      data-gtm-form-interact-field-id="1"
                    />
                    <span className="cobalt-TextField__Icon cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7 10c-1.106.003-2 .901-2 2.008v6.983C5 20.098 5.898 21 7.006 21h9.988C18.103 21 19 20.1 19 18.991V12.01A2.009 2.009 0 0 0 17 10V7A5 5 0 0 0 7 7v3Zm8 0H9V7a3 3 0 1 1 6 0v3Z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="c-mb-md"></div>
                <div class="c-mb-lg">
                  <div class="cobalt-FormField">
                    <div class="cobalt-ChoiceList">
                      <div
                        class="cobalt-CheckmarkField"
                        data-form-checkmark-method="privacy_policy_accepted"
                      >
                        <label
                          class="cobalt-CheckmarkField__LabelWrapper"
                          for="checkbox-54c422cd5295d2a2a51ffcf638caeef7"
                        >
                          <input
                            name="user[privacy_policy_accepted]"
                            type="hidden"
                            value="0"
                            autocomplete="off"
                          />
                          <input
                            id="checkbox-54c422cd5295d2a2a51ffcf638caeef7"
                            class="cobalt-CheckmarkField__Input"
                            type="checkbox"
                            value={privacyAccepted ? '1' : '0'}
                            name="user[privacy_policy_accepted]"
                            onChange={togglePrivacyPolicy}
                          />
                          <span class="cobalt-CheckmarkField__Checkmark"></span>
                          <span class="cobalt-CheckmarkField__Label">
                            <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>
                                I accept the{" "}
                              </font>
                            </font>
                            <a target="_blank" rel="noopener">
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>
                                  privacy policy
                                </font>
                              </font>
                            </a>
                          </span>
                        </label>
                      </div>
                      <div
                        class="cobalt-CheckmarkField"
                        data-form-checkmark-method="notif_newsletter"
                      >
                        <label
                          class="cobalt-CheckmarkField__LabelWrapper"
                          for="checkbox-ad22f2ef957e17cd6c5cd7c48b807c1d"
                        >
                          <input
                            name="user[notif_newsletter]"
                            type="hidden"
                            value="0"
                            autocomplete="off"
                          />
                          <input
                            id="checkbox-ad22f2ef957e17cd6c5cd7c48b807c1d"
                            class="cobalt-CheckmarkField__Input"
                            type="checkbox"
                            value="1"
                            name="user[notif_newsletter]"
                          />
                          <span class="cobalt-CheckmarkField__Checkmark"></span>
                          <span class="cobalt-CheckmarkField__Label">
                            <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>
                                I would like to receive good deals, promotions
                                and new products + €5 off my first rental
                              </font>
                            </font>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-text-body-md c-text-subdued c-mb-md">
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}>
                      By registering, you agree to our{" "}
                    </font>
                  </font>
                  <a target="_blank" rel="noopener">
                    <font style={{ verticalAlign: "inherit" }}>
                      <font
                        style={{
                          verticalAlign: "inherit",
                          textDecoration: "underline",
                        }}
                      >
                        terms of use
                      </font>
                    </font>
                  </a>
                  <font style={{ verticalAlign: "inherit" }}>
                    <font style={{ verticalAlign: "inherit" }}> .</font>
                  </font>
                </div>
                <div class="c-mb-sm recaptcha_placeholder">
                  <ReCAPTCHA
                    sitekey="6LfU3TEqAAAAABFk6c7naZWCshGCeXyDfb7X1xbr"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  {alert && <Alert severity="error">{alert}</Alert>}
                </div>

                <input
                  type="submit"
                  name="commit"
                  value="Sign Up"
                  className="cob-Button cob-Button__default cob-Button__default--primary cob-Button--large cob-Button--fullWidth c-mb-md js_signin_with_email_button"
                  data-disable="true"
                />
              </form>

              {/* <div className="c-text-center c-text-body-md">
                Don't have an Overlo account?
                <a href="/sign-up">Sign up</a>
              </div> */}
            </div>
            <div class="authentication_layout__footer footer-wrapper--2024 c-border-t c-border-base">
              <div
                style={{ background: "transparent" }}
                class="corporate_footer__container container sm:c-flex-row-reverse"
              >
                <div class="corporate_footer__copyright_links_container">
                  <ul class="corporate_footer__links c-mb-sm md:c-mb-none">
                    <li class="corporate_footer__link corporate_footer__link--copyright c-text-subdued c-text-center">
                      © Overlo 2010-2024 - All rights reserved
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Legal terms
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" rel="nofollow" target="_blank">
                        Privacy policy
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Cookies
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a href="">Press</a>
                    </li>
                    <li class="corporate_footer__link">
                      <a href="">Careers</a>
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Investor relations
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="authentication_layout__section authentication_layout__section--secondary authentication_layout__section--background_2"></div>
        </div>
      </div>
    </>
  );
};

export default Signup;
