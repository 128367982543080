import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../../ContextApi";
import { carUnavailbilty, getCarImages, unavailablePeriod } from "../../../api";
import Unavailabilty from "../Unavailabilty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Make sure this is imported for the calendar styles

const AddImageCar = () => {
  const [unavailableRanges, setUnavailableRanges] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const { imageArray, setImageArray, openDialog } = useContext(DataContext);
  const { id } = useParams();

  useEffect(() => {
    getCarImages(id, setImageArray);
    unavailablePeriod(id, setUnavailableRanges);
  }, [id, setImageArray]);

  const convertDate = (newDateString) => {
    const newDate = new Date(newDateString);
    return newDate.toISOString().replace('T', ' ').split('.')[0];
  };

  const handleSaveUnavailable = async () => {
    if (startDate && endDate) {
      let payload = {
        vehicle_id: id,
        start_time: convertDate(startDate),
        end_time: convertDate(endDate),
      };
      try {
        const response = await carUnavailbilty(payload);
        setStartDate(null);
        setEndDate(null);
        openDialog(response?.data?.message);
        unavailablePeriod(id, setUnavailableRanges);

        // Update unavailableRanges after saving
        unavailablePeriod(id, setUnavailableRanges);
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response ? error.response.data : error.message
        );
      }
    }
  };

  const highlightUnavailableDates = (date) => {
    return unavailableRanges?.some(
      (range) => new Date(date) >= new Date(range.start) && new Date(date) <= new Date(range.end)
    )
      ? "highlighted-date"
      : "";
  };
  return (
    <body id="users_profile_wizard_layout">
      <div class="js_site_content site_content">
        <div class="container dashboard_container" id="dashboard">
          <div class="cobalt-Callout formated_text top20 cobalt-Callout--info">
            <div class="cobalt-Callout__Title">
              To process your next payment, we need some more information
            </div>
            <ul>
              <li>Last 4 digits of Social Security number (SSN)</li>
              <li>Zubair Khan's Social Security number (SSN)</li>
            </ul>
            <a
              class="cob-Button cob-Button__default"
              href="https://getaround.com/dashboard/payment_settings#payment_settings_bank_account"
            >
              Complete my information
            </a>
          </div>

          <a class="link_no_style" href="">
            <div class="dashboard_header_title c-flex c-gap-2xs c-flex-wrap sm:c-items-center">
              <div class="c-text-title-lg c-mr-xs">
                {imageArray?.car_make} {imageArray?.car_model}
              </div>
              <div class="c-flex c-items-center c-gap-xs c-h-lg">
                <div class="cobalt-plate-number">
                  {imageArray?.vehicle_model}
                </div>
                <div class="cobalt-Tag cobalt-Tag--accent">Carshare</div>
              </div>
            </div>
          </a>
          <div class="cobalt-layout-flexible md:c-flex-row c-gap-md">
            <div class="col-md-12 col-l-8">
              <div>
                <div class="c-text-title-md c-mb-sm">Your listing</div>
                <div class="cobalt-Card c-mb-lg">
                  <div class="car-checklist__item cobalt-Card__Section cobalt-Card__Section--divided">
                    <div class="cobalt-BasicCell">
                      <div class="cobalt-BasicCell__LeftAddon">
                        <div class="cobalt-Icon cobalt-Icon--colorSuccess c-w-lg c-mx-2xs c-my-xs">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M21 7 9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div class="cobalt-BasicCell__Main">
                        <div class="cobalt-BasicCell__Content">
                          <p class="c-text-body-md c-font-bold">
                            Add car information
                          </p>
                          <p class="car-checklist__item-description">
                            Car model, mileage, address...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a
                    class="car-checklist__item cobalt-Card__Section cobalt-Card__Section--divided link_no_style"
                    onClick={() => navigate(`/main-menu/cars/${id}/photos`)}
                  >
                    <div class="cobalt-BasicCell">
                      <div class="cobalt-BasicCell__LeftAddon">
                        <div class="cobalt-Icon cobalt-Icon--colorBase c-w-lg c-mx-2xs c-my-xs">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5 3h3v2H5v3H3V5H0V3h3V0h2v3ZM12 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2 8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V6h2a1 1 0 0 0 1-1V3h6l2 2h3.01C21.108 5 22 5.893 22 6.992v11.016c0 1.1-.898 1.992-1.99 1.992H3.99C2.892 20 2 19.107 2 18.008V8Zm15 4.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div class="cobalt-BasicCell__Main">
                        <div class="cobalt-BasicCell__Content">
                          <p class="c-text-body-md c-font-bold">Add photos</p>
                          <p class="car-checklist__item-description">
                            At least 3 more
                          </p>
                        </div>
                      </div>
                      <div class="cobalt-BasicCell__RightAddonIcon">
                        <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* <a
                    class="car-checklist__item cobalt-Card__Section cobalt-Card__Section--divided link_no_style"
                    href=""
                  >
                    <div class="cobalt-BasicCell">
                      <div class="cobalt-BasicCell__LeftAddon">
                        <div class="cobalt-Icon cobalt-Icon--colorBase c-w-lg c-mx-2xs c-my-xs">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M14,15 L7,15 L7,17 L14,17 L14,15 Z M19,20 L5,20 L5,9 L19,9 L19,20 Z M19,4 L18,4 L18,2 L16,2 L16,4 L8,4 L8,2 L6,2 L6,4 L5,4 C3.89,4 3,4.9 3,6 L3,20 C3,21.1045695 3.8954305,22 5,22 L19,22 C20.1045695,22 21,21.1045695 21,20 L21,6 C21,4.8954305 20.1045695,4 19,4 L19,4 Z M17,11 L7,11 L7,13 L17,13 L17,11 Z"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="cobalt-BasicCell__Main">
                        <div class="cobalt-BasicCell__Content">
                          <p class="c-text-body-md c-font-bold">
                            Update your calendar
                          </p>
                          <p class="car-checklist__item-description">
                            Tell drivers when your car is available
                          </p>
                        </div>
                      </div>
                      <div class="cobalt-BasicCell__RightAddonIcon">
                        <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                          <svg
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </a> */}
                </div>
                <div class="c-text-title-md c-mb-sm">
                  {" "}
                  Add Unavailable Dates:
                </div>

                <Unavailabilty startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}  handleSaveUnavailable={handleSaveUnavailable} />
              </div>
            </div>

            <div class="col-md-12 col-l-4">
              <div class="cobalt-Card md:c-mt-xl">
                <div class="cobalt-Card__Section">
                  <div class="text_center">
                    Make sure all steps are complete
                  </div>
                  <div class="c-mt-sm">
                    <form class="button_to" method="post" action="">
                      <button
                        id="publish-listing-button"
                        aria-disabled="true"
                        disabled="disabled"
                        class="cob-Button cob-Button--fullWidth cob-Button__default cob-Button__default--primary cob-Button--disabled"
                        type="submit"
                      >
                        Publish my listing
                      </button>
                      <input
                        type="hidden"
                        name="authenticity_token"
                        value="8_p7tYtOj8yypdXt2CCzUUHJrqkJWMfNJe8GdtsQQQ_rkV4SIPCcCWocM1C2tpUILWOpLrt31mp2oqHe7T0K3g"
                        autocomplete="off"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div
                style={{ marginTop: "20px" }}
                class="c-text-title-md c-mb-sm"
              >
                Unavailable Cars:
              </div>
              <div>
                <DatePicker
                  calendarClassName="full-width-calendars"
                  inline
                  monthsShown={2} // Show two months side by side
                  selected={null}
                  dayClassName={highlightUnavailableDates}
                />
              </div>
            </div>
          </div>

          <a
            class="cob-Button cob-Button__default cob-Button__default--destructive"
            rel="nofollow"
            data-method="delete"
            href=""
          >
            Delete listing
          </a>
        </div>
      </div>
    </body>
  );
};

export default AddImageCar;
