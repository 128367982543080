import React from "react";
import "./vendor.css";
import SubHeader from "./SubHeader";
import { Route, Routes } from "react-router-dom";
import { menuArrayRoute } from "../../../ExportFiles";

const Menu = () => {
  const checkRoute=(val)=>{
return val;
  }
  return (
    <body style={{ height: "auto" }} id="users_profile_wizard_layout">
      <div class="js_site_content site_content">
        <SubHeader />
        <Routes>
          {menuArrayRoute.map((val) => (
            <Route key={val.id} path={val.path} element={ checkRoute(val.component)} />
          ))}
        </Routes>
      </div>
    </body>
  );
};

export default Menu;
