import React, { useContext, useState, useEffect } from "react";
import "./role.css";
import deleteIcon from "../../../image/svg/delete.svg";
import editIcon from "../../../image/svg/edit.svg";
import ModalReusable from "../../../ReusableComponent/ModalReusable";
import { AddAccount, AddRole, deleteAccount, deleteRole, getAllAccounts, getAllRole, getRoleForAccount, updateAccounts, updateRole } from "../../../api";
import { DataContext } from "../../../ContextApi";
import Spinner from "../../../ReusableComponent/Spinner";
import { useNavigate } from "react-router-dom";


const Accounts = () => {
  const [open, setOpen] = useState(false);
  const [editingRoleId, setEditingRoleId] = useState(null);
  const [allRoles, setAllRoles] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const { setLoader, loader } = useContext(DataContext);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    user_phone: "",
    user_email: "",
    user_password: "",
    role_id: "",
  });
const navigate=useNavigate();
const {openDialog}=useContext(DataContext);

  useEffect(() => {
    setLoader(true);
    getRoleForAccount(setAllRoles);
    getAllAccounts(setAllAccounts, setLoader,openDialog,navigate);
  }, [setLoader]);

  const handleOpenEdit = (user) => {
    setEditingRoleId(user.user_id);
    setFormData({
      first_name: user.first_name,
      last_name: user.last_name,
      user_phone: user.user_phone,
      user_email: user.user_email,
      user_password: "", // password should be updated if necessary
      role_id: user.role_id,
    });
    setOpen(true);
  };

  const handleOpen = () => {
    setEditingRoleId(null);
    setFormData({
      first_name: "",
      last_name: "",
      user_phone: "",
      user_email: "",
      user_password: "",
      role_id: "",
    });
    setOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      role_id: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
       
  const handleDeleteAccount = (id) => {
    setLoader(true);
    deleteAccount(id,getAllAccounts,setAllAccounts, setLoader)

  };

  const handleFormSubmit = () => {
    setLoader(true);
    setOpen(false)
    if (editingRoleId) {
        updateAccounts( formData,editingRoleId,getAllAccounts,setAllAccounts, setLoader,openDialog)
         
        
    } else {
      AddAccount(formData,getAllAccounts,setAllAccounts, setLoader,openDialog)
       
       
    }
  };

  return (
    <>
      <div className="container dashboard_container" id="dashboard">
        <div className="row flex_row_center_above_xs ">
          <div className="col-xs-12 ">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div className="c-text-title-md">Accounts List</div>
              <a
                className="cob-Button cob-Button__default cob-Button__default--primary c-mb-sm"
                onClick={handleOpen}
              >
                Add Account
              </a>
            </div>
          </div>
        </div>
        {loader ? (
          <Spinner />
        ) : (
          <div className="row">
            <div className="col-xs-12 no-outer-gutter-xs">
              <div className="cobalt-Card cobalt-Card--responsive c-overflow-hidden c-mb-lg">
                <div className="cobalt-Card__Section cobalt-Card__Section--divided">
                  <table className="cobalt-Table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allAccounts.map((user) => (
                        <tr key={user.user_id}>
                          <td>{user.first_name} {user.last_name}</td>
                          <td>{user.user_email}</td>
                          <td>{user.user_phone}</td>

                          <td>
                            <span
                              onClick={() => handleOpenEdit(user)}
                              className="action-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <img src={editIcon} alt="Edit" />
                            </span>
                          </td>
                          <td>
                            <span
                              onClick={() => handleDeleteAccount(user.user_id)}
                              style={{ cursor: "pointer" }}
                              className="action-btn"
                            >
                              <img src={deleteIcon} alt="Delete" />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalReusable
          open={open}
          handleClose={handleClose}
          handleOperation={handleFormSubmit}
          buttonText={"Save"}
          modalHeader={editingRoleId ? "Edit Account" : "Add Account"}
        >
          <div style={{ height: "50vh", overflow: "auto", padding: "20px" }}>
            <div className="cobalt-FormField">
              <label className="cobalt-FormField__Label" htmlFor="first_name">
                First Names
              </label>
              <div className="cobalt-TextField">
                <input
                required
                  title="First Name"
                  className="cobalt-TextField__Input"
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  placeholder="Enter first name"
                />
              </div>
            </div>

            <div className="cobalt-FormField">
              <label className="cobalt-FormField__Label" htmlFor="last_name">
                Last Name
              </label>
              <div className="cobalt-TextField">
                <input
                  title="Last Name"
                  required
                  className="cobalt-TextField__Input"
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  placeholder="Enter last name"
                />
              </div>
            </div>

            <div className="cobalt-FormField">
              <label className="cobalt-FormField__Label" htmlFor="email">
                Email
              </label>
              <div className="cobalt-TextField">
                <input
                  title="Email"
                  className="cobalt-TextField__Input"
                  type="email"
                  required
                  id="email"
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleInputChange}
                  placeholder="Enter Email"
                />
              </div>
            </div>

            <div className="cobalt-FormField">
              <label className="cobalt-FormField__Label" htmlFor="password">
                Password
              </label>
              <div className="cobalt-TextField">
                <input
                  title="Password"
                  className="cobalt-TextField__Input"
                  type="password"
                  required
                  id="password"
                  name="user_password"
                  value={formData.user_password}
                  onChange={handleInputChange}
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="cobalt-FormField">
              <label className="cobalt-FormField__Label" htmlFor="role">
                Role
              </label>
              <div className="cobalt-SelectField cobalt-flexAlign">
                <select
                  id="role"
                  name="role_id"
                  required
                  className="cobalt-SelectField__Input c-mr-xs"
                  value={formData.role_id}
                  onChange={handleSelectChange}
                >
                  <option value="" label="Select Role"></option>
                  {allRoles?.map((val) => (
                    <option  key={val.role_id} value={val.role_id}>
                      {val.role_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="cobalt-FormField">
              <label className="cobalt-FormField__Label" htmlFor="phone">
                Phone
              </label>
              <div className="cobalt-TextField">
                <input
                  title="Phone"
                  required
                  className="cobalt-TextField__Input"
                  type="text"
                  id="phone"
                  name="user_phone"
                  value={formData.user_phone}
                  onChange={handleInputChange}
                  placeholder="Enter phone number"
                />
              </div>
            </div>
          </div>
        </ModalReusable>
      </div>
    </>
  );
};

export default Accounts;
