import React, { useContext, useState, useEffect } from "react";
import "./role.css";
import deleteIcon from "../../../image/svg/delete.svg";
import editIcon from "../../../image/svg/edit.svg";
import ModalReusable from "../../../ReusableComponent/ModalReusable";
import { AddRole, deleteRole, getAllRole, updateRole } from "../../../api";
import { DataContext } from "../../../ContextApi";
import Spinner from "../../../ReusableComponent/Spinner";
import { useNavigate } from "react-router-dom";

const roles = [
  { id: 1, name: "request" },
  { id: 2, name: "rentals" },
  { id: 3, name: "cars" },
  { id: 4, name: "performance" },
  { id: 5, name: "payments" },
  { id: 6, name: "profile" },
  { id: 7, name: "role" },
  { id: 8, name: "accounts" },



  // Add more roles as needed
];

const Role = () => {
  const [open, setOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [newRoleName, setNewRoleName] = useState("");
  const [allRoles, setAllRoles] = useState([]);
  const { setLoader, loader } = useContext(DataContext);
  const [roleAccessDetail, setRoleAccessDetail] = useState({});
  const [editingRoleId, setEditingRoleId] = useState(null);
const navigate=useNavigate()
const {openDialog}=useContext(DataContext);

  useEffect(() => {
    setLoader(true);
    getAllRole(setAllRoles, setLoader,openDialog,navigate);
  }, [setLoader]);
console.log("getAllRole",selectedRoles)
  const handleOpenEdit = (role) => {
    setEditingRoleId(role.role_id);
    setNewRoleName(role.role_name);
    setRoleAccessDetail(JSON.parse(role.role_access_detail));
    setSelectedRoles(
      Object.keys(JSON.parse(role.role_access_detail)).filter(
        (key) => JSON.parse(role.role_access_detail)[key] === "1"
      )
    );
    setOpen(true); // Open the modal
  };

  const handleOpen = () => {
    setEditingRoleId(null); // Reset editing role ID
    setNewRoleName("");
    setRoleAccessDetail({});
    setSelectedRoles([]);
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setNewRoleName(event.target.value);
  };

  const handleCheckboxChange = (roleName) => {
    setSelectedRoles((prevSelectedRoles) =>
      prevSelectedRoles.includes(roleName)
        ? prevSelectedRoles.filter((r) => r !== roleName)
        : [...prevSelectedRoles, roleName]
    );
  };
  const handleDeleteRole = (id) => {
    setLoader(true);
    deleteRole(id, setLoader, getAllRole, setAllRoles);
  };
  const handleFormSubmit = () => {

    const roleAccessDetail = roles.reduce((acc, role) => {
      acc[role.name] = selectedRoles.includes(role.name) ? "1" : "0";
      return acc;
    }, {});

    const payload = {
      role_name: newRoleName,
      role_access_detail: roleAccessDetail,
    };

    if (editingRoleId) {
      setLoader(true);

      updateRole(payload, editingRoleId, setLoader, getAllRole, setAllRoles);
    } else {
      setLoader(true);

      AddRole(payload, setLoader, getAllRole, setAllRoles,openDialog);
    }

    // Reset form and close modal
    setNewRoleName("");
    setSelectedRoles([]);
    handleClose();
  };

  return (
    <>
      <div className="container dashboard_container" id="dashboard">
        <div className="row flex_row_center_above_xs ">
          <div className="col-xs-12 ">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <div className="c-text-title-md">Roles List</div>
              <a
                class="cob-Button cob-Button__default cob-Button__default--primary c-mb-sm"
                onClick={handleOpen}
              >
                Add Role
              </a>
            </div>
          </div>
        </div>
        {loader ? (
          <Spinner />
        ) : (
          <div className="row">
            <div className="col-xs-12 no-outer-gutter-xs">
              <div className="cobalt-Card cobalt-Card--responsive c-overflow-hidden c-mb-lg">
                <div className="cobalt-Card__Section cobalt-Card__Section--divided">
                  <table className="cobalt-Table">
                    <thead>
                      <tr>
                        <th>Role Name</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allRoles.map((role) => (
                        <tr key={role.role_id}>
                          <td>{role.role_name}</td>
                          <td>
                            <span
                              onClick={() => handleOpenEdit(role)}
                              className="action-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <img src={editIcon} alt="Edit" />
                            </span>
                          </td>
                          <td>
                            <span
                              onClick={() => handleDeleteRole(role.role_id)}
                              style={{ cursor: "pointer" }}
                              className="action-btn"
                            >
                              <img src={deleteIcon} alt="Delete" />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        <ModalReusable
          open={open}
          handleClose={handleClose}
          handleOperation={handleFormSubmit}
          buttonText={"Save"}
          modalHeader={editingRoleId ? "Edit Role" : "Add Role"}
        >
          <div
            style={{
              height: "50vh",
              overflow: "auto",
              padding: "20px",
            }}
          >
            <div>
              <div class="cobalt-FormField" data-form-field-method="email">
                <label class="cobalt-FormField__Label" for="user_email">
                  {" "}
                  Role Name{" "}
                </label>
                <div class="cobalt-TextField">
                  <input
                  required
                    title="New email is required"
                    class="cobalt-TextField__Input"
                    type="text"
                    id="user_email"
                    value={newRoleName}
                onChange={handleInputChange}

                placeholder="Enter role name"
                  />
                </div>
              </div>
            </div>
            <table style={{ marginTop: "10px" }} className="cobalt-Table">
              <thead>
                <tr>
                  <th>Checkbox</th>
                  <th>Tabs</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role) => (
                  <tr key={role.id}>
                    <td>
                      <input
                        type="checkbox"
                        required={selectedRoles.length === 0}  // Set required based on the condition

                        style={{ width: "17px", height: "17px" }}
                        id={`role-${role.id}`}
                        checked={selectedRoles.includes(role.name)}
                        onChange={() => handleCheckboxChange(role.name)}
                      />
                    </td>
                    <td>
                      <label
                        style={{ textTransform: "capitalize" }}
                        htmlFor={`role-${role.id}`}
                      >
                        {role.name}
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalReusable>
      </div>
    </>
  );
};

export default Role;
