import React from "react";

const MillageAllownce = ({description}) => {
  console.log("description",description)
  return (
 <div class="cobalt-layout-card">
                      <div class="cobalt-layout-card__item">
                        <div class="mileage_included_section__content">
                          <div class="c-flex">
                            <div class="c-flex c-flex-col c-flex-grow">
                             
                              <div class="cobalt-BasicCell">
                             
                                <div class="cobalt-BasicCell__Main">
                                  <div class="c-text-body-md">
                                    {description}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="c-w-md">
                              <button
                                name="button"
                                type="button"
                                class="js_mileage_included_section__learn_more_link c-cursor-pointer c-border-0 c-bg-transparent c-p-none"
                              >
                                <div class="cobalt-Icon cobalt-Icon--colorSubduedInteractive js_mileage_included_section__learn_more_link_icon">
                                  <svg>
                                    <use href="#cobalt/icon/info"></use>
                                  </svg>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 

);
};

export default MillageAllownce;
