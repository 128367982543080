import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { reviewAll } from "../../../api";
import Spinner from "../../../ReusableComponent/Spinner";

const OwnerDetails = () => {
  const [review, setReview] = useState([]);
  const [loader, setLoader] = useState(true);
  const { id } = useParams();
  const originalDate = new Date(review?.member_since
  );
// Function to format the date
function formatDate(date) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
  return `${month} ${day} at ${formattedHours}:${formattedMinutes} ${ampm}`;
}
console.log("checkDate",formatDate(new Date("2024-08-10T19:04:00.000Z ")))
  function mapRatingToArray(rating) {
    const num = Number(rating);
  
    const resultArray = Array.from({ length: num }, (_, index) => index + 1);
  
    return resultArray;
  }
  console.log("idfind", review);
  const fetchReview = async (id) => {
    let payload = {
      vehicle_id: id,
    };
    try {
      const response = await reviewAll(payload);

      // handleClickSnackbar(response?.data?.message);
      setLoader(false);
      setReview(response?.data?.response);
    } catch (response) {
      // handleClickSnackbar(response?.response?.data?.message);
    } finally {
    }
  };
  useEffect(() => {
    fetchReview(id);
  }, [id]);

  return (
    <div class="full_width_content default_background">
      <div class="container">
        {loader ? (
          <Spinner />
        ) : (
          <article itemscope="" itemtype="http://schema.org/Person">
            <h1 class="header_title user_header_title" itemprop="name">
              {review?.name} profile
            </h1>
            <div class="row top20">
              <div class="col-sm-4 col-xs-12 no-outer-gutter-xs">
                <div class="cobalt-Card cobalt-Card--responsive user_card c-mb-md">
                  <div class="cobalt-Card__Section">
                    <div class="user_card__infos">
                      <a href="/users/5581365">
                        <img
                          alt="Mdg"
                          width="100"
                          height="100"
                          class="cobalt-avatar user_card_picture"
                          src={review?.profile_image ||
                                    "https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=2&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60"
                          }
                        />
                      </a>
                      <div class="user_card__name_badge_container">
                        <div class="user_card__name js_open_preview cursor_pointer">
                          <a class="link-on-hover" >
                           {review?.name }
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="cobalt-Card__Section relative">
                    <div class="row c-text-center">
                      <div class=" col-lg-5 col-lg-offset-1 col-xs-6">
                        <div class="c-text-title " >
                          <div class="user_card_icon">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                fill="none"
                                fill-rule="evenodd"
                                stroke="none"
                                stroke-width="1"
                              >
                                <path
                                  d="M3.5461731,17.4538269 C3,18 3,18 3,18.5 L3,20.0008606 C3,21.0008606 3,21.0008606 4,21.0008606 L6,21.0008602 L6,19 L8,19 L8,16.9546871 L10,16.9546871 L10,15 L12,15 L12.496129,14.5 C14.5,15.4999998 16.9999999,14.500001 20,11.5000003 C21.5,10.0000001 22,8.00000011 19,5 C16,1.99999989 14,2.49999999 12.496129,3.99999997 C9.49420346,6.99419856 8.5,9.49999978 9.5,11.5 L3.5461731,17.4538269 L3.5461731,17.4538269 Z M15.6065774,8.39161392 C16.594318,9.37935454 17.7950389,9.7800776 18.2884626,9.28665392 C18.7818863,8.79323023 18.3811632,7.59250932 17.3934226,6.6047687 C16.405682,5.61702807 15.2049611,5.21630502 14.7115374,5.7097287 C14.2181137,6.20315238 14.6188368,7.40387329 15.6065774,8.39161392 L15.6065774,8.39161392 Z"
                                  fill="#b4bbc6"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          &nbsp;{review?.rentals}
                        </div>
                        <div class="c-text-body-md c-text-subdued color-grey">rentals</div>
                      </div>
                      <div class="col-lg-5 col-xs-6">
                        <div class="c-text-title">
                          <div class="user_card_icon">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                fill="none"
                                fill-rule="evenodd"
                                stroke="none"
                                stroke-width="1"
                              >
                                <path
                                  d="M7.71163405,20.8562867 C6.84949963,21.3296772 5.84187365,20.5650648 6.00652667,19.5624075 L6.84488987,14.594771 L3.35617076,11.043006 C2.65868936,10.3329201 3.04356824,9.09575128 4.00746382,8.94946555 L8.80363311,8.21837144 L10.9461857,3.68418581 C11.3772529,2.77193806 12.6227471,2.77193806 13.0538143,3.68418581 L15.2307871,8.22679814 L19.9925362,8.94946555 C20.9564318,9.09575128 21.3413106,10.3329201 20.6438292,11.043006 L17.176653,14.5883666 L17.9934733,19.5624075 C18.1581264,20.5650648 17.1505004,21.3296772 16.2883659,20.8562867 L12.0263592,18.5160556 L7.71163405,20.8562867 Z"
                                  fill="#b4bbc6"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <span itemprop="ratingValue"> &nbsp;{review?.rating}</span>/
                          <span itemprop="bestRating">5</span>
                          <meta itemprop="worstRating" content="1" />
                        </div>
                        <div class="c-text-body-md c-text-subdued color-grey">
                          {review?.total} reviews
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cobalt-Card__Section cobalt-Card__Section--divided">
                    <div class="c-text-body-md c-text-subdued color-grey">
                      Member since {`${originalDate.getMonth() + 1}/${originalDate.getDate()}/${originalDate.getFullYear()}`
                      } 
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-8 col-xs-12 no-outer-gutter-xs">
                {review?.detail?.map((val,index) => (
                  val?.reviews?.length>0&&
                  <div
                  key={index}
                    class="js_cars_pagination_container"
                    data-infinite-pagination-added=""
                  >
                    <div class="js_cars_pagination_content">
                      <div
                        class="cobalt-Card c-mb-md js_car_reviews_infinite_pagination_container"
                        data-infinite-pagination-added=""
                      >
                        <div class="cobalt-Card__Section">
                          <h2 class="c-text-title-md c-mb-none">{`${val?.reviews?.length} reviews of ${review?.name} as car owner`}</h2>
                          <p class="c-mb-md">
                            About their
                            <a title="See listing">
                              {" "}
                              {val?.make_name} {val?.model_name}
                            </a>
                          </p>
                          {val?.reviews.map((item,index) => (
                            <div key={index} class="js_infinite_pagination_content">
                              <div
                                class="rental_review"
                                itemprop="review"
                                itemscope=""
                                itemtype="https://schema.org/Review"
                              >
                                <img
                                  alt="Charlie"
                                  class="cobalt-avatar rental_review__thumb"
                                  src={
                                    item?.profile_image ||
                                    "https://drivy.gumlet.io/uploads/us_legacy/111381c8d68516c61b6a379397230eba0cc19020.jpg?compress=true&amp;crop=faces&amp;dpr=2&amp;fm=png&amp;h=60&amp;mask=ellipse&amp;mode=crop&amp;w=60"
                                  }
                                />
                                <div class="rental_review__content">
                                  <div class="rental_review__info">
                                    <div
                                      class="c-text-title-sm"
                                      itemprop="author"
                                      itemscope=""
                                      itemtype="http://schema.org/Person"
                                    >
                                      <span itemprop="name">
                                        {item?.first_name} {item?.last_name}
                                      </span>
                                    </div>
                                    <div class="rental_review__date color-grey ">
                                     {formatDate(new Date( item?.date_time))}
                                    </div>
                                    <meta
                                      content="2024-08-15"
                                      itemprop="datePublished"
                                    />
                                  </div>
                                  <div class="rental_review__text">
                                    <p itemprop="reviewBody">{item?.remarks}</p>
                                  </div>
                                  <div class="c-text-subdued color-grey c-text-body-md">
                                   {item?.rent_time}
                                  </div>
                                  <div class="dashboard_rental_rating_and_report c-mb-sm">
                                    <div class="star_ratings">
                                      <div class="cobalt-rating-icons">
                                      {mapRatingToArray(item?.rating).map((val,index)=>(

                          
                                        <span key={index} class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                          <svg
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      ))}
                                      </div>
                                    </div>
                                    <div
                                      class="c-mb-sm"
                                      itemprop="reviewRating"
                                      itemscope=""
                                      itemtype="http://schema.org/Rating"
                                    >
                                      <meta
                                        content="5.0"
                                        itemprop="ratingValue"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* <div class="show_more_reviews">
                            <button
                              class="cob-Button cob-Button__ghost cob-Button--fullWidth js_infinite_pagination_trigger"
                              data-next-page-path="/cars/1391572/reviews?page=2&amp;paginate_per=5&amp;rel=next"
                            >
                              See more...
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div class="show_more_reviews">
                  <button
                    class="cob-Button cob-Button__default cob-Button--fullWidth c-mb-md js_cars_pagination_trigger"
                    data-next-page-path="/users/5581365/cars_with_reviews?page=2">
                    See more...
                  </button>
                </div> */}
                  </div>
                  
                  
                ))}
                <div class="row c-text-left">
                  <div class="c-p-sm">
                    Reviews are listed from newest to oldest. All reviews are
                    written by drivers and moderated by Overlo.{" "}
                    <a href="" target="_blank">
                      Learn more.
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </article>
        )}
      </div>
    </div>
  );
};

export default OwnerDetails;
