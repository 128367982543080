import React, { useContext, useState, useEffect } from "react";
import logo from "../image/png/logo.svg";
import { AuthContext } from "../ContextApi/AuthContext";
import { DataContext } from "../ContextApi";
import { useLocation, useNavigate } from "react-router-dom";
import { emailConformationLink } from "../api";
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from "@mui/material";
const Login = () => {
  const navigate = useNavigate();
  const { login, isLoggedIn, loading } = useContext(AuthContext);
  const { loader, setLoader, openDialog, setAlert, alert } =
    useContext(DataContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setAlert("Please complete the CAPTCHA");
      setTimeout(() => {
        setAlert("");
      }, 5000);
      return;
    }
    setLoader(true);
    await login(email, password, setLoader, setError, navigate, setAlert);
  };
  const location = useLocation();
 const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  useEffect(() => {
    if (!loading && isLoggedIn) {
      navigate("/");
    }
  }, [loading, isLoggedIn, navigate]);

  return (
    <>
      <div class="js_site_content site_content login">
        <div class="authentication_layout">
          <div class="authentication_layout__section authentication_layout__section--primary">
            <div class="authentication_layout__logo hidden-xs authentication_layout__logo--rebranded">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div class="visible-xs authentication_layout__close">
              <a class="link_no_style" aria-label="Close" href="/">
                ✕
              </a>
            </div>
            <div class="authentication_layout__content c-mb-md">
              <div class="cobalt-TabBar c-mb-xl js_registration_session_tab_bar">
                <div
                  class="cobalt-TabBar__ScrollControl js_tab_bar_scroll_control"
                  data-direction="left"
                  hidden=""
                  style={{ display: "none" }}
                >
                  <button class="cobalt-TabBar__ScrollButton" hidden="">
                    <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.41 16.58 9.83 12l4.58-4.59L13 6l-6 6 6 6 1.41-1.42Z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
                <div
                  class="cobalt-TabScroller js_tab_bar_scroller cobalt-TabScroller--underlined"
                  style={{ height: "68px" }}
                >
                  <div
                    class="cobalt-TabScroller__ScrollArea js_tab_bar_scroll_area"
                    style={{ paddingBottom: "30px" }}
                  >
                    <a
                      class="cobalt-Tab"
                      aria-selected="true"
                      onClick={() => navigate("/login")}
                    >
                      <div class="cobalt-Tab__Content">Log in</div>
                    </a>
                    <a class="cobalt-Tab" onClick={() => navigate("/sign-up")}>
                      <div class="cobalt-Tab__Content">Sign up</div>
                    </a>
                  </div>
                </div>
                <div
                  class="cobalt-TabBar__ScrollControl js_tab_bar_scroll_control"
                  data-direction="right"
                  hidden=""
                  style={{ display: "none" }}
                >
                  <button class="cobalt-TabBar__ScrollButton" hidden="">
                    <div class="cobalt-Icon cobalt-Icon--colorSubdued">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>

              <div class="authentication_header">
                <div class="authentication_header__title">
                  Log in to your account
                </div>
              </div>

              <form
                class="new_user"
                id="new_user"
                onSubmit={handleLogin}
                data-gtm-form-interact-id="0"
              >
                <input
                  type="hidden"
                  name="authenticity_token"
                  value="fiFcU8Hgha_dprmmZQP69VBIApLnbBqh6hWVO-ZvpgaUHQ4WWBlYIyFAxfrMmp9YWxeDudvstyY9p0pTuW2XSg"
                  autocomplete="off"
                />
                <div class="cobalt-FormField" data-form-field-method="email">
                  <div class="cobalt-TextField cobalt-TextField--withIcon">
                    <input
                      placeholder="Email or Phone"
                      class="cobalt-TextField__Input"
                      type="email"
                      required
                      name="user[email]"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="user_email"
                      data-gtm-form-interact-field-id="0"
                    />
                    <span class="cobalt-TextField__Icon cobalt-Icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 Z M20,4 L4,4 C2.89,4 2,4.89 2,6 L2,18 C2,19.1045695 2.8954305,20 4,20 L20,20 C21.1045695,20 22,19.1045695 22,18 L22,6 C22,4.89 21.1,4 20,4 Z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="cobalt-FormField" data-form-field-method="password">
                  <div class="cobalt-TextField cobalt-TextField--withIcon">
                    <input
                      placeholder="Password"
                      class="cobalt-TextField__Input"
                      type="password"
                      required
                      value={password}
                      name="user[password]"
                      onChange={(e) => setPassword(e.target.value)}
                      id="user_password"
                      data-gtm-form-interact-field-id="1"
                    />
                    <span class="cobalt-TextField__Icon cobalt-Icon">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7 10c-1.106.003-2 .901-2 2.008v6.983C5 20.098 5.898 21 7.006 21h9.988C18.103 21 19 20.1 19 18.991V12.01A2.009 2.009 0 0 0 17 10V7A5 5 0 0 0 7 7v3Zm8 0H9V7a3 3 0 1 1 6 0v3Z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              
                <div class="authentication_password_reset">
                  <div style={{textAlign:"right"}} class="c-mb-sm">
                    <a
                      style={{ fontFamily: "BrownProMarketing" }}
                     onClick={()=>navigate("/forgot_password")}
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div class="c-mb-sm recaptcha_placeholder">
                  <ReCAPTCHA
                    sitekey="6LfU3TEqAAAAABFk6c7naZWCshGCeXyDfb7X1xbr"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>{alert && <Alert severity="error">{alert}</Alert>}</div>

                <input
                  type="submit"
                  name="commit"
                  value="Log in"
                  class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--large cob-Button--fullWidth c-mb-md js_signin_with_email_button"
                  data-disable="true"
                />
              </form>

              <div class="c-text-center c-text-body-md">
                Don't have a Overlo account?
                <a  onClick={() => navigate("/sign-up")}>Sign up</a>
              </div>
            </div>
            <div class="authentication_layout__footer footer-wrapper--2024 c-border-t c-border-base">
              <div class="corporate_footer__container container sm:c-flex-row-reverse">
                <div class="corporate_footer__copyright_links_container">
                  <ul class="corporate_footer__links c-mb-sm md:c-mb-none">
                    <li class="corporate_footer__link corporate_footer__link--copyright c-text-subdued c-text-center">
                      © Overlo 2010-2024 - All rights reserved
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Legal terms
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a
                        class=""
                        href="/privacy"
                        rel="nofollow"
                        target="_blank"
                      >
                        Privacy policy
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Cookies
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a href="">Press</a>
                    </li>
                    <li class="corporate_footer__link">
                      <a href="">Careers</a>
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Investor relations
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="authentication_layout__section authentication_layout__section--secondary authentication_layout__section--background_2"></div>
        </div>
      </div>
    </>
  );
};

export default Login;
