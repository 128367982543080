import React, {  useRef, useState } from "react";
import location from "../../../image/svg/location.svg"
import {
  useLoadScript,
  GoogleMap,
  MarkerF,
} from "@react-google-maps/api";const containerStyle = {
  width: "100%",
  height: "200px", // Ensure the height is set
};
const PickupAndReturn = ({carDetail}) => {
  const libraries = ["places"];
  const [latLng, setLatLng] = useState({ lat: 31.5204, lng: 74.3587 });
  console.log("latLnf",latLng)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCK1M71JxoP14Pgs2UOMTd0USEvjg-6UAM",
    libraries,
  });
  const mapRef = useRef(null);
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;
  return (
    <div class="cobalt-layout-card">
    <div class="cobalt-layout-card__item">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="location_section_address">
            <div class="cobalt-Icon cobalt-Icon--contained cobalt-Icon--carSection cobalt-Icon--colorBase c-mr-sm">
             <img style={{width:"23px"}} src={location} alt="location"/>
            </div>
            <div
              class="location_section_address__content"
              itemprop="seller"
              itemscope=""
              itemtype="http://schema.org/Person"
            >
              <div
                class="c-text-body-md c-text-base"
                itemprop="address"
                itemscope=""
                itemtype="http://schema.org/PostalAddress"
              >
                <div class="c-font-bold">
                {carDetail?.vehicle_location}
                </div>
              </div>
            </div>
          </div>
          <div class="location_section_explanation">
            <div class="cobalt-Note cobalt-Note--fullWidth">
              This car has a reserved parking space. Return to a
              guaranteed spot!
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="car_map js_car_map">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/search/?api=1&amp;query=40.750828%2C-74.02449"
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={10}
                center={latLng}
                onLoad={(map) => (mapRef.current = map)}
              >
                {latLng && (
                  <MarkerF position={latLng} />
                )}
              </GoogleMap>
              
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default PickupAndReturn;
