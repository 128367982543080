import React, { useContext, useEffect } from "react";
import { handleChange } from "../../../../ExportFiles";
import { DataContext } from "../../../../ContextApi";

const StepPro = () => {
  const { setSelectDataCaseThree, selectDataCaseThree } = useContext(DataContext);

  // Log the current value of selectDataCaseThree.is_company to debug
  useEffect(() => {
    console.log("Current is_company value:", selectDataCaseThree?.is_company);
  }, []);

  return (
    <div className="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
      <div className="c-text-title-md c-mb-lg">
        Are you operating as a registered business?
      </div>
      <div className="c-mb-2xl">
        <div className="c-mb-lg">
          <div className="cobalt-CheckmarkField" data-form-checkmark-method="is_pro">
            <label className="cobalt-CheckmarkField__LabelWrapper">
              <input
                className="cobalt-CheckmarkField__Input"
                type="radio"
                value="1"
                name="is_company"
                id="car_is_pro_true"
                checked={selectDataCaseThree?.is_company == "1"}
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseThree,
                    selectDataCaseThree,
                    "caseThree",
                    "radio"
                  )
                }
              />
              <span className="cobalt-CheckmarkField__Checkmark"></span>
              <span className="cobalt-CheckmarkField__Label">
                Yes, I'm a professional owner
              </span>
            </label>
          </div>
        </div>
        <div className="cobalt-CheckmarkField" data-form-checkmark-method="is_pro">
          <label className="cobalt-CheckmarkField__LabelWrapper">
            <input
              className="cobalt-CheckmarkField__Input"
              type="radio"
              value="0"
              name="is_company"
              id="car_is_pro_false"
              checked={selectDataCaseThree?.is_company == "0"}
              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseThree,
                  selectDataCaseThree,
                  "caseThree",
                  "radio"
                )
              }
            />
            <span className="cobalt-CheckmarkField__Checkmark"></span>
            <span className="cobalt-CheckmarkField__Label">
              No, I’m a private owner
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default StepPro;
