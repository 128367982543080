import React, { useContext } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const StepsDoor = () => {
  const { rentYourCar, setSelectDataCaseThree, selectDataCaseThree } = useContext(DataContext);
  return (
    <div className="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
      <div className="c-text-section-heading c-mb-xs">List my car</div>
      <div className="c-text-title-md c-mb-lg">Add more details</div>
      <div className="c-mb-lg">
        <div className="cobalt-FormField">
          <label className="cobalt-FormField__Label">Number of doors</label>
          <div className="cobalt-CheckablePillsGroup">
            {rentYourCar?.doors?.map((val) => (
              <div className="cobalt-CheckablePillField" key={val.door_id}>
                <label
                  className="cobalt-CheckablePillField__LabelWrapper"
                 
                >
                  <input
                    id={`checkable-pill-${val.door_id}`}
                    className="cobalt-CheckablePillField__Input"
                    type="radio"
                    checked={selectDataCaseThree.doors === val.door_id}
                    value={val.door_id}
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDataCaseThree,
                        selectDataCaseThree,
                        "caseThree",
                        "radio"
                      )
                    }
                    name="doors"
                  />
                  <span className="cobalt-CheckablePillField__Label">{val?.doors}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="cobalt-FormField">
          <label className="cobalt-FormField__Label">Number of seats</label>
          <div className="cobalt-CheckablePillsGroup">
            {rentYourCar?.seats?.map((val) => (
              <div className="cobalt-CheckablePillField" key={val.sear_id}>
                <label
                  className="cobalt-CheckablePillField__LabelWrapper"
                >
                  <input
                    id={`checkable-pill-${val.sear_id}`}
                    className="cobalt-CheckablePillField__Input"
                    type="radio"
                    checked={selectDataCaseThree.seats === val.sear_id}
                    value={val.sear_id}
                    onChange={(e) =>
                      handleChange(
                        e,
                        setSelectDataCaseThree,
                        selectDataCaseThree,
                        "caseThree",
                        "radio"
                      )
                    }
                    name="seats"
                  />
                  <span className="cobalt-CheckablePillField__Label">{val?.seats}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="cobalt-Note cobalt-Note--fullWidth">
        This information helps drivers find the best fit for them.
      </div>
    </div>
  );
};

export default StepsDoor;
