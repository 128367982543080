import React, { useContext } from "react";
import "./NumberOfSeats.css";
import { handleAllFilters } from "../ReusableComponent/OverAllFilter";
import { DataContext } from "../ContextApi";
const Features = ({ features }) => {
  const { filterValue, setFilterValue } = useContext(DataContext);

  return (
    <div className="Numberofseats_main_div">
      <div className="NumberOfSeats_text">FEATURES</div>
      <div className="MinimumSeats_div">
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {features?.map((val) => (
            <div
              style={{
                backgroundColor: filterValue?.features?.includes(val)
                  ? "#ffc947"
                  : "",
              }}
              onClick={() =>
                handleAllFilters(
                  "features",
                  val,
                  setFilterValue,
                  filterValue.features
                )
              }
              className="drop-down-circle-internal"
            >
              <img src={val?.image_url} />
              <p>{val.feature_name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
