import React, { useContext, useEffect, useState } from "react";
import character from "../image/png/character_with.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { emailConformationLink } from "../api";
import { DataContext } from "../ContextApi";

const Verify = () => {
    const { openDialog } = useContext(DataContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(8);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('confirmation_token');

        if (token) {
            console.log('Confirmation Token:', token);
            emailConformationLink(token, openDialog, navigate);
        }
    }, [location.search, openDialog, navigate]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown > 1) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(interval);
                    return 1;
                }
            });
        }, 1000); // Decrease countdown every second

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="container dashboard_container" id="dashboard">
            <div className="c-text-center">
                <img
                    className="cobalt-illustration-image cobalt-EmptyState__Image"
                    role="presentation"
                    alt="character_in_jeep"
                    width="188"
                    src={character}
                />
                <h2 className="cobalt-EmptyState__Title">
                    Wait For {countdown} {countdown === 1 ? "Second" : "Seconds"}...
                </h2>
                <div className="cobalt-EmptyState__Body">
                    You have successfully verified your email. You will be automatically redirected to the login page.
                </div>
            </div>
        </div>
    );
};

export default Verify;
