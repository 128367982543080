import axios from "axios";
const API_URL = "https://overlo.cloudifytech.net/api/";
export const getFilter = (setAllFilter) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/search_fiter",  {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      setAllFilter(data.response);
      console.log("data=>", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const addDriver = (payload,setOpen,openDialog) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/assign_driver",  payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      setOpen(false);
      console.log("data=>", data);
      openDialog(data?.message)

    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      openDialog(response?.response?.data?.message)
      console.log("ERROR => ", response);
    });
};

export const registerCar = (payload,navigate,handleClickSnackbar) => {
  let token = localStorage.getItem("token");
  axios
    .post(API_URL + "/register_car", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      navigate(`/car_wizards/${data.response.id}/step_modal`)

      localStorage.setItem("registerId", data.response.id);
      console.log("setRegisterId", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
           handleClickSnackbar(response?.response?.data?.message);

    });
};
export const loginApi = (
  payload,
  setIsLoggedIn,
  setLoader,
  setError,
  navigate,
  handleClickSnackbar
) => {
  axios
    .post(API_URL + "/login", payload)
    .then(function ({ data }) {
      setIsLoggedIn("true");
      setLoader(false);

      const lastRoute = sessionStorage.getItem("lastRoute");

      // Check if lastRoute exists
      if (lastRoute) {
        // Navigate to lastRoute
        navigate(lastRoute);
      } else {
        // Navigate to home page
        navigate("/");
      }
      console.log("tokeenn", data.response?.detail?.role?.role_access_detail);
      localStorage.setItem("token", data.response.token);
      localStorage.setItem(
        "roles",
        data.response?.detail?.role?.role_access_detail
      );
      localStorage.setItem('is_billing_address', data.response?.detail?.is_billing_address);
      localStorage.setItem("name", data.response?.detail?.first_name);
      localStorage.setItem("cover_photo", data.response?.detail?.cover_photo);
      localStorage.setItem('company_id',data.response?.detail?.company_id);
      localStorage.setItem('profile_status',data.response?.detail?.profile_status);
      localStorage.setItem('user_type',data.response?.detail?.user_type);
      localStorage.setItem('company_type',data.response?.detail?.company_type);




    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      
      setError(response);
      setLoader(false);
      handleClickSnackbar(response?.response?.data?.message);
      setTimeout(() => {
        handleClickSnackbar("")
      }, 5000);
      console.log("ERROR =>dddd ", response?.response?.data?.message);
    });
};
export const forgetPassword = (
  payload,openDialog,setAlert,setEmail
) => {
  axios
    .post(API_URL + "/forgot_password", payload)
    .then(function ({ data }) {
     
      openDialog(data?.message)
      setEmail("")
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }

      setAlert(response?.response?.data?.message);
      setTimeout(() => {
        setAlert("")
      }, 5000);
      
      console.log("ERROR =>dddd ", response?.response?.data?.message);
    });
};
export const ChangePassword = (
  payload,openDialog,setAlert,navigate
) => {
  axios
    .post(API_URL + "/update_password", payload)
    .then(function ({ data }) {
      navigate("/login")
      openDialog(data?.message)

    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }

      setAlert(response?.response?.data?.message);
      setTimeout(() => {
        setAlert("")
      }, 5000);
      
      console.log("ERROR =>dddd ", response?.response?.data?.message);
    });
};
export const SignupApi = (
  payload,
  
      ) => {
        return axios.post(API_URL + "/signup",  payload );

    
      }
export const updateStep0 = (payload, id,navigate,basePath,handleClickSnackbar, setLoading) => {
  let token = localStorage.getItem("token");

  return axios
    .put(
      API_URL + `/register_car_step0/${id}`,
      payload,
      {
        headers: {
          token: token,
        },
      }
    )
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setLoading(false)
      navigate(`${basePath}/region_address`);


    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const updateStep1 = (payload,id,navigate,basePath,handleClickSnackbar, setLoading) => {
  let token = localStorage.getItem("token");

  axios
    .put(
      API_URL + `/register_car_step1/${id}`,
      payload,
      {
        headers: {
          token: token,
        },
      }
    )
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setLoading(false)
      navigate(`${basePath}/step_door`);

    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const updateStep2 = (payload,id,navigate,basePath,handleClickSnackbar, setLoading) => {
  let token = localStorage.getItem("token");

  axios
    .put(
      API_URL + `/register_car_step2/${id}`,
      payload,
      {
        headers: {
          token: token,
        },
      }
    )
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setLoading(false)

      const billing = localStorage.getItem('is_billing_address');
  
      if (billing == '1') {
        // Navigate to step_billing if billing is true
        navigate(`${basePath}/step_contact`);
      } else {
        // Navigate to the next step if billing is false
        navigate(`${basePath}/step_billing`);
      }
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const updateStep3 = (payload,id,navigate,basePath,handleClickSnackbar, setLoading) => {
  let token = localStorage.getItem("token");

  axios
    .put(
      API_URL + `/register_car_step3/${id}`,
      payload,
      {
        headers: {
          token: token,
        },
      }
    )
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setLoading(false)

      navigate(`${basePath}/image_upload`);

    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const updateStep4 = (payload,id,navigate,basePath,handleClickSnackbar, setLoading) => {
  let token = localStorage.getItem("token");

  axios
    .put(
      API_URL + `/register_car_step4/${id}`,
      payload,
      {
        headers: {
          token: token,
        },
      }
    )
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setLoading(false)

      navigate(`/main-menu/cars`);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const getrentYourCarData = (setRentYourCar) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/register_step1", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setRentYourCar(data.response);

      console.log("data=>hmmss", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const getCarDetail = (payload, setCarDetail, setLoader) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + `/car_by_id`, payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      setLoader(false);
      setCarDetail(data.response.result);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const updateDates = (payload, setLoader,openDialog) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + `/edit_booking`, payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      setLoader(false);
      openDialog(data?.message)
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      setLoader(false);

      openDialog(response?.response?.data?.message)





      console.log("ERROR => ", response);
    });
};
export const bookingDateDetail = (payload, setStartDate,setEndDate, setLoader,setUnavailableRanges) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + `/booking_detail_by_car`, payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      setLoader(true);
      setStartDate(data.response.pickup_datetime);
      setEndDate(data.response.dropoff_datetime)
      setUnavailableRanges(data.response.result)
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const getCarDetailAdmin = (payload, setCarDetail, setLoader) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + `/car_by_id_admin`, payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      setLoader(false);
      setCarDetail(data.response.result);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const getRegisterCarList = (
  setRegisterCarList,
  setLoader,
  handleClickSnackbar,
  navigate
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/car_list", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setRegisterCarList(data.response);

      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      handleClickSnackbar(response?.response?.data?.message);
      navigate("/main-menu/wrong_route");
    });
};
export const getRequestCarList = (
  setRequestCarList,
  setLoader,
  handleClickSnackbar,
  navigate
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/car_requests", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setRequestCarList(data.response);

      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      handleClickSnackbar(response?.response?.data?.message);
      if (navigate) {
        navigate("/main-menu/wrong_route");
      }
    });
};
export const getRentalCarList = (
  setRequestCarList,
  setLoader,
  handleClickSnackbar,
  navigate
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/car_rental_list", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setRequestCarList(data.response);

      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      handleClickSnackbar(response?.response?.data?.message);
      if (navigate) {
        navigate("/main-menu/wrong_route");
      }
    });
};
export const searchVehicle = (payload, setSearchVehicle, setLoader,setResultArray,handleClickSnackbar) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/search_vehicle", payload, {
      headers: token,
    })
    .then(function ({ data }) {
      setSearchVehicle(data.response.detail);
      console.log("data=>aassss", data.response.request);

      sessionStorage.setItem("period", data.response?.period);
      setLoader(false);
      if(setResultArray)
      {
        console.log("data=>aassss", data.response);
setResultArray(data.response.request)
      }
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      if (handleClickSnackbar) {
        setLoader(false);

        handleClickSnackbar(response?.response?.data?.message);
      }
      console.log("ERROR => ", response);
    });
};
export const searchVehicleWithoutResult = (payload, setSearchVehicle, setLoader,handleClickSnackbar) => {
  let token = localStorage.getItem("token");
  axios
    .post(API_URL + "/search_vehicle", payload, {
      headers: token,
    })
    .then(function ({ data }) {
      if(setSearchVehicle)
{
  setSearchVehicle(data.response.detail);

}
      sessionStorage.setItem("period", data.response?.period);
      setLoader(false);
     
    })
    .catch(function (response) {
      console.log("data=>aasssshmm", response);

      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      if (handleClickSnackbar) {
        setLoader(false);

        handleClickSnackbar(response?.response?.data?.message);
      }
      console.log("ERROR => ", response);
    });
};
export const getAllRole = (
  setAllRole,
  setLoader,
  handleClickSnackbar,
  navigate
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/role", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setAllRole(data.response.all);
     
      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      if (handleClickSnackbar) {
        handleClickSnackbar(response?.response?.data?.message);
      }
      if (navigate) {
        navigate("/main-menu/wrong_route");
      }
    });
};
export const getdriverList = (
  id,
  setOpen,
  setDriverList,
  handleClickSnackbar,
  
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + `/available_driver/${id}`, {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setOpen(true)
      setDriverList(data?.response?.result);
     
      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      if (handleClickSnackbar) {
        handleClickSnackbar(response?.response?.data?.message);
      }
     
    });
};

export const getEditCarDetail = (
  id,
  setLoader,
  setEditList,
  handleClickSnackbar,
  
) => {
  let token = localStorage.getItem("token");

 return axios
    .get(API_URL + `/car_detail/${id}`, {
      headers: {
        token: token,
      },
      params: {},
    })
    // .then(function ({ data }) {
    //   setLoader(false)
    //   setEditList(data?.response?.result);
     
    //   console.log("data=>hmmlsit", data.response);
    // })
    // .catch(function (response) {
    //   if (response?.response?.data?.message === "Token expired") {
    //     hardlyLogout();
    //   }
    //   console.log("ERROR => ", response);
    //   if (handleClickSnackbar) {
    //     handleClickSnackbar(response?.response?.data?.message);
    //   }
     
    // });
};
export const getVerifyLisence = (
  id,
  setLoader,
  setLiscenseList,
  handleClickSnackbar,
  setLoaderLisence
  
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + `/view_license/${id}`, {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false)
      setLiscenseList(data?.response?.result);
     
      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      setLoader(false)
      setLoaderLisence(false)
      console.log("ERROR => ", response);
      if (handleClickSnackbar) {
        handleClickSnackbar(response?.response?.data?.message);
      }
     
    });
};

export const getPaymentHistory = (
  setLoader,
  setPaymentHistory,
  handleClickSnackbar,
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/payment_history", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setPaymentHistory(data.response);
     
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      if (handleClickSnackbar) {
        handleClickSnackbar(response?.response?.data?.message);
      }
      
    });
};
export const getRoleForAccount = (setAllRole) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/role_list", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setAllRole(data.response.all);

      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const getAllAccounts = (
  setAllAccounts,
  setLoader,
  handleClickSnackbar,
  navigate
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/users", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setAllAccounts(data.response.all);

      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      if (handleClickSnackbar) {
        handleClickSnackbar(response?.response?.data?.message);
        navigate("/main-menu/wrong_route");
      }
    });
};
export const getAllCompany = (
  setAllAccounts,
  setLoader,
  handleClickSnackbar,
  navigate
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/company_list", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setAllAccounts(data.response.detail);

      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      if (handleClickSnackbar) {
        handleClickSnackbar(response?.response?.data?.message);
        navigate("/main-menu/wrong_route");
      }
    });
};
export const AddRole = (payload, setLoader, getAllRole, setAllRoles,handleClickSnackbar) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/add_role", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      getAllRole(setAllRoles, setLoader);

        handleClickSnackbar(data?.message);
    
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};

export const AddReview = (payload,setOpen,handleClickSnackbar) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/add_rating", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
     
      setOpen(false)
        handleClickSnackbar(data?.message);
    
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const UpdateCar = (id,payload,setOpen,handleClickSnackbar) => {
  let token = localStorage.getItem("token");

  axios
    .put(API_URL + `/update_car/${id}`, payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
     
      setOpen()
        handleClickSnackbar(data?.message);
    
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};

export const getReview = (
  id,setLoader,setReviewData,handleClickSnackbar
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + `/rating_by_car/${id}`, {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      setLoader(false);
      setReviewData(data.response.result);

      console.log("data=>hmmlsit", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
      if (handleClickSnackbar) {
        handleClickSnackbar(response?.response?.data?.message);
      }
    });
};
export const AddRegister = (payload,setOpen,handleClickSnackbar) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/add_role", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
     
      setOpen(false)
        handleClickSnackbar(data?.message);
    
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const AddAccount = (
  payload,
  getAllAccounts,
  setAllAccounts,
  setLoader,
  handleClickSnackbar
) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/add_user", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      getAllAccounts(setAllAccounts, setLoader);
      handleClickSnackbar(data?.message);

      console.log("data=>aass", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }   
         handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const ActiveInactiveCompany = (
  payload,
  
) => {
  let token = localStorage.getItem("token");

return  axios
    .post(API_URL + "/active_inactive_company", payload, {
      headers: {
        token: token,
      },
    })
   
};
export const updateRole = (payload, id, setLoader, getAllRole, setAllRoles) => {
  let token = localStorage.getItem("token");

  axios
    .put(API_URL + `/edit_role/${id}`, payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      getAllRole(setAllRoles, setLoader);
      console.log("setRegisterstep1", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const updateAccounts = (
  payload,
  id,
  getAllAccounts,
  setAllAccounts,
  setLoader,
  handleClickSnackbar
) => {
  let token = localStorage.getItem("token");

  axios
    .put(API_URL + `/edit_user/${id}`, payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      getAllAccounts(setAllAccounts, setLoader);
      handleClickSnackbar(data?.message);

    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};
export const deleteRole = (id, setLoader, getAllRole, setAllRoles) => {
  let token = localStorage.getItem("token");

  axios
    .put(
      API_URL + `/delete_role/${id}`,
      {},
      {
        headers: {
          token: token,
        },
      }
    )
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      getAllRole(setAllRoles, setLoader);
      console.log("setRegisterstep1", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const deleteAccount = (
  id,
  getAllAccounts,
  setAllAccounts,
  setLoader
) => {
  let token = localStorage.getItem("token");

  axios
    .put(
      API_URL + `/delete_user/${id}`,
      {},
      {
        headers: {
          token: token,
        },
      }
    )
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      getAllAccounts(setAllAccounts, setLoader);
      console.log("setRegisterstep1", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const deleteCompany = (
  id,
  
) => {
  let token = localStorage.getItem("token");

return  axios
    .put(
      API_URL + `/delete_company/${id}`,
      {},
      {
        headers: {
          token: token,
        },
      }
    )
   
};
export const approvedCompany = (
  id,
  
) => {
  let token = localStorage.getItem("token");

return  axios
    .put(
      API_URL + `/approve_company/${id}`,
      {},
      {
        headers: {
          token: token,
        },
      }
    )
   
};
export const Booking = (payload,setOpen,openDialog) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/car_booking", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      // getAllAccounts(setAllAccounts, setLoader);
      setOpen(false);
      openDialog(data?.message)
      console.log("data=>aass", data.response);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const carImageUpload = (formData) => {
  let token = localStorage.getItem("token");

  return axios.post(API_URL + "/uploadfile", formData, {
    headers: {
      token: token,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const carUnavailbilty = (payload) => {
  let token = localStorage.getItem("token");

  return axios.post(API_URL + "/add_off_time", payload, {
    headers: {
      token: token,
    },
  });
};

export const getCarImages = (id, setImageArray, setLoading) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + `/car_image_by_id/${id}`, {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setImageArray(data.response.detail);
      if (setLoading) {
        setLoading((prevLoading) => ({ ...prevLoading, [id]: false }));
      }
      console.log("setImageArray", data.response.detail);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const deleteImageUpload = (
  payload,
  setLoading,
  getCarImages,
  setImageArray,
  id
) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/delete_image", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      if (setLoading) {
        setLoading((prevLoading) => ({
          ...prevLoading,
          [payload?.image_id]: false,
        }));
        getCarImages(id, setImageArray);
      }
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const profileImageUpload = (formData, setPreviewUrl) => {
  let token = localStorage.getItem("token");

  return axios
    .post(API_URL + "/upload_profile", formData, {
      headers: {
        token: token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setPreviewUrl(data.response.image_url);
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const ImageUploads = (formData,imageURLs,setImageURLs,setIsUploading) => {
  let token = localStorage.getItem("token");

   axios
    .post(API_URL + "/upload_car_image", formData, {
      headers: {
        token: token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setIsUploading(false)
      setImageURLs([...imageURLs, data.response.image_url]); // Add uploaded image URL to state array
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const lisenceImageUpload = (formData, setPreviewUrl) => {
  let token = localStorage.getItem("token");

  return axios
    .post(API_URL + "/upload_license", formData, {
      headers: {
        token: token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      console.log("data.response",data.response)
      setPreviewUrl(data.response.image_url);
      
    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", response);
    });
};
export const profileUpload = (payload, handleClickSnackbar,setOpenModal) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/edit_profile", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      handleClickSnackbar(data?.message);
      if(setOpenModal)

        {
          setOpenModal(false)
        }
      console.log("editProfile",data?.response?.profile_status)
      localStorage.setItem('profile_status',data?.response?.profile_status);

    })
    .catch(function (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      handleClickSnackbar(response?.response?.data?.message);

      console.log("ERROR => ", response);
    });
};

export const getUserDetail = (
  setUserData,
  setPreviewUrl,
  handleClickSnackbar,
  navigate,
  setPreviewLisenceUrl,
  setPreviewLisenceUrl2
) => {
  let token = localStorage.getItem("token");

  axios
    .get(API_URL + "/user", {
      headers: {
        token: token,
      },
      params: {},
    })
    .then(function ({ data }) {
      if (setPreviewUrl) {
        setUserData({
          first_name: data.response.user_detail?.first_name || "",
          last_name: data.response.user_detail?.last_name || "",
          birth_date: data.response.user_detail?.user_date_of_birth || "",
          birth_place: data.response.user_detail?.user_place_of_birth || "",
          license_number:
            data.response.user_detail?.user_driver_license_number || "",
          license_first_issue_date:
            data.response.user_detail?.user_issue_date || "",
          license_country:
            data.response.user_detail?.user_license_country || "",
          license_state: data.response.user_detail?.user_license_state || "",
          address: data.response.user_detail?.user_address || "",
          phone: data.response.user_detail?.user_phone || "",
          about_me: data.response.user_detail?.about_me || "",
        });
        setPreviewUrl(data.response.user_detail?.cover_photo);
        setPreviewLisenceUrl(data.response.user_detail?.user_driver_license_photo)
        setPreviewLisenceUrl2(data.response.user_detail?.user_driver_license_photo_back)

      } else {
        setUserData({
          email: data.response.user_detail?.user_email || "",
        });
      }
    })
    .catch(function (error) {
      if (error?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      console.log("ERROR => ", error);
      handleClickSnackbar(error?.response?.data?.message);
      navigate("/main-menu/wrong_route");
    });
};

export const changeEmail = (payload, handleClickSnackbar) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/change_email", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      handleClickSnackbar(data?.message);
    })
    .catch(function (response) {
      handleClickSnackbar(response?.response?.data?.message);
    });
};
export const addCompany = (payload, handleClickSnackbar) => {
  let token = localStorage.getItem("token");

  axios
    .post(API_URL + "/add_company", payload, {
      headers: {
        token: token,
      },
    })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      handleClickSnackbar(data?.message);
      // navigate("/login")
    })
    .catch(function (response) {
      handleClickSnackbar(response?.response?.data?.message);
    });
};
export const acceptBooking = (payload) => {
  let token = localStorage.getItem("token");

  return axios.post(API_URL + "/accept_reject_booking", payload, {
    headers: {
      token: token,
    },
  });
};
export const emailConformationLink = (id, handleClickSnackbar, navigate) => {
  axios
    .get(API_URL + `/verify_email_token/${id}`, {
      params: {},
    })
    .then(function ({ data }) {
     // handleClickSnackbar(data?.message);
      navigate("/verify");
      setTimeout(() => {
        navigate("/login");

      }, 8000);
    })
    .catch(function (error) {
      // if (error?.response?.data?.message === "Token expired") {
      //   hardlyLogout();
      // }
      console.log("ERROR => ", error);
      handleClickSnackbar(error?.response?.data?.message);
       navigate("/");
    
      
    });
};
export const sendPhoneVerify = (payload) => {
  let token = localStorage.getItem("token");

  return axios.post(API_URL + "/verify_phone", payload, {
    headers: {
      token: token,
    },
  });
};
export const sendCodeVerify = (code) => {
  let token = localStorage.getItem("token");

  return axios.get(API_URL + `/verify_code/${code}`, {
    headers: {
      token: token,
    },
    params: {},
  });
};
export const LisenceVerify = (id) => {
  let token = localStorage.getItem("token");

  return axios.get(API_URL + `/verify_license/${id}`, {
    headers: {
      token: token,
    },
    params: {},
  });
};
export const unavailablePeriod = (id,setUnavailableDates) => {
  let token = localStorage.getItem("token");

   axios.get(API_URL + `/unavailable_period/${id}`, {
    headers: {
      token: token,
    },
    params: {},
  })
    .then(function ({ data }) {
      // setRegisterId(data.response.id)
      setUnavailableDates(data?.response?.result)
      // navigate("/login")
    })
    .catch(function (response) {
      // handleClickSnackbar(response?.response?.data?.message);
    });
  
};
export const hardlyLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("roles");
  localStorage.removeItem("cover_photo");
  localStorage.removeItem("name");
  localStorage.removeItem('company_id');


  sessionStorage.removeItem("lastRoute");
  window.location.href = "/";
};

export const acceptRejectAdmin = (payload) => {
  let token = localStorage.getItem("token");

  return axios.post(API_URL + "/accept_reject_car", payload, {
    headers: {
      token: token,
    },
  });
};
export const reviewAll = (payload) => {
  let token = localStorage.getItem("token");

  return axios.post(API_URL + "/all_reviews", payload, {
    headers: {
      token: token,
    },
  });
};
