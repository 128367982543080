import React, { useContext, useState } from "react";
import { DataContext } from "../ContextApi";
import { AddRegister } from "../api";
const inputFields = [
  { name: "first_name", label: "First Name", type: "text", required: true },
  { name: "last_name", label: "Last Name", type: "text", required: true },

  { name: "company_phone", label: "PHONE", type: "text", required: true },
  { name: "password", label: "PASSWORD", type: "password", required: true },

  { name: "yesr", label: "Number of Years", type: "number", required: false },
  { name: "type", label: "Type of License", type: "text", required: false },

  {
    name: "company_email",
    label: "E-MAIL",
    type: "email",
    required: true,
    placeholder: "your@email.com",
  },

  { name: "company_address", label: "ADDRESS", type: "text", required: false },
  { name: "company_country", label: "COUNTRY", type: "text", required: false },
];

const Register = () => {
  const [formValues, setFormValues] = useState({
    company_name: "",
    admin_name: "",
    company_email: "",
    company_phone: "",
    company_address: "",
    company_city: "",
    company_country: "Senegal",
    admin_email: "",
    company_type: "1",
  });
  const { openDialog } = useContext(DataContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = { ...formValues };
    AddRegister(payload, openDialog);
  };

  return (
    <div id="dashboard-cars-show">
      <div className="js_site_content site_content">
        <div
          id="form"
          className="section--grid section--center dark"
          style={{
            paddingBottom: "60px",
            paddingTop: "60px",
            "--pdx-min-height": "0px",
            "--wr-max": "1030px",
          }}
        >
          <div className="wr titleAlign--">
            <div className="titleWrapper"></div>
            <div
              className="formWrapper"
              style={{
                borderRadius: "3px",
                maxWidth: "600px",
                margin: "0 auto",
              }}
            >
              <div className="fontfamily" data-form-checkmark-method="is_pro">
                <label
                  className="Company_info"
                  style={{ verticalAlign: "inherit" }}
                >
                  Register For{" "}
                  <span style={{ verticalAlign: "inherit" }}>*</span>
                </label>
              </div>
              <form
                id="cf-a788qic6ecm0mdsy"
                className="form"
                style={{
                  "--cf-bg": "#fafafa",
                  "--cf-color": "#000",
                  "--cf-placeholder": "#bbb",
                  "--cf-borderColor": "rgba(126, 126, 126, 0.3)",
                }}
                data-siteid="8om2m5yd35wf"
                data-formid="form_bHpHCmPSyakpdpqK"
                onSubmit={handleSubmit}
              >
                {inputFields.map((field, index) => (
                  <div className="formRow" key={field.name}>
                    <div
                      className="cf-field"
                      data-id={field.name.toUpperCase()}
                      data-label={field.label}
                      data-required={field.required}
                      data-field={field.type}
                    >
                      <label style={{ verticalAlign: "inherit" }}>
                        {field.label}{" "}
                        <span style={{ verticalAlign: "inherit" }}>*</span>
                      </label>
                      <input
                        type={field.type}
                        name={field.name}
                        value={formValues[field.name]}
                        placeholder={field.placeholder || ""}
                        onChange={handleChange}
                        required={field.required}
                        disabled={field.name === "company_country"}
                      />
                    </div>
                  </div>
                ))}
                <label style={{ verticalAlign: "inherit" }}>
                  Add Lisence Image
                  <span style={{ verticalAlign: "inherit" }}>*</span>
                </label>
                <div className="formRow" >
                <div
                  class="car_picture_uploader__wrapper "
                >
                  <label                   style={{ width: "300px",height:"250px" }}
 class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_text">
                    <input
                      class="car_picture_uploader__file-input js_car_picture_uploader_file_input"
                      name="file"
                      type="file"
                      // onChange={(e) =>
                      //   handleFileUpload(
                      //     e.target.files[0],
                      //     val.id
                      //   )
                      // }
                    />
                    <div class="car_picture_uploader__placeholder_content">
                      <div class="c-text-body-md c-text-subdued c-mb-xs">
                        {/* {val?.text} */}Image
                      </div>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="none"
                          stroke-width="1"
                        >
                          <path
                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 L11,7 L11,11 Z"
                            fill="#b4bbc6"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </label>
                </div>
                </div>
                <label style={{ verticalAlign: "inherit" }}>
                  Add Profile Image
                  <span style={{ verticalAlign: "inherit" }}>*</span>
                </label>
                <div className="formRow" >
                <div
                  class="car_picture_uploader__wrapper "
                >
                  <label                   style={{ width: "300px",height:"250px" }}
 class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_text">
                    <input
                      class="car_picture_uploader__file-input js_car_picture_uploader_file_input"
                      name="file"
                      type="file"
                      // onChange={(e) =>
                      //   handleFileUpload(
                      //     e.target.files[0],
                      //     val.id
                      //   )
                      // }
                    />
                    <div class="car_picture_uploader__placeholder_content">
                      <div class="c-text-body-md c-text-subdued c-mb-xs">
                        {/* {val?.text} */}Image
                      </div>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="none"
                          stroke-width="1"
                        >
                          <path
                            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 L11,7 L11,11 Z"
                            fill="#b4bbc6"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </label>
                </div>
                </div>
                <div className="ctaWrapper">
                  <button className="btn primary btn--large" type="submit">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
