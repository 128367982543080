import React, { useState, useContext, useEffect, useRef } from "react";
import ModalReusable from "../../../ReusableComponent/ModalReusable";
import { DataContext } from "../../../ContextApi";
import LocationSearch from "../../../ReusableComponent/LocationSearch";
import {
  carImageUpload,
  carUnavailbilty,
  deleteImageUpload,
  getCarImages,
  getEditCarDetail,
  hardlyLogout,
  unavailablePeriod,
  UpdateCar,
} from "../../../api";
import { useLoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";
import Spinner from "../../../ReusableComponent/Spinner";

import { Modal } from "@material-ui/core";
import Unavailability from "../Unavailabilty";
import { addMoreImageArray } from "../../../ExportFiles";
const containerStyle = {
  width: "100%",
  height: "200px", // Ensure the height is set
};
export const EditCar = ({ openEditForm, handleCloseEditModal, id }) => {
  const {
    rentYourCar,
    setSearchVehicles,
    openDialog,
    setInputValue,
    setSelectDataCaseFiveLocation,
    selectDataCaseFiveLocation,
  } = useContext(DataContext);
  console.log("selectDataCaseFiveLocation", id);
  const [editList, setEditList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [location, SetLocation] = useState({ lat: "", lng: "", location: "" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [unavailableRanges, setUnavailableRanges] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const libraries = ["places"];
  const [latLng, setLatLng] = useState({});
  const [loading, setLoading] = useState({});
  const [images, setImages] = useState(addMoreImageArray.map(() => null));
  const { imageArray, setImageArray } = useContext(DataContext);
  const billing = localStorage.getItem("is_billing_address");
  const [selectedExtraId, setSelectedExtraId] = useState(null);
  const handlePricingChange = (event) => {
    setSelectedExtraId(event.target.value);
  };

  const handleFileUpload = async (file, index) => {
    const formData = new FormData();
    formData.append("file_name", file);
    formData.append("vehicle_id", id);
    formData.append("image_id", index);

    setLoading((prevLoading) => ({ ...prevLoading, [index]: true }));
    try {
      const response = await carImageUpload(formData);
      console.log("File uploaded:", response.data);

      setImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = response.data.response; // Store the response data
        return newImages;
      });
      getCarImages(id, setImageArray, setLoading);
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = (imageId, index) => {
    setLoading((prevLoading) => ({ ...prevLoading, [index]: true }));
    let payload = { vehicle_id: id, image_id: imageId };

    deleteImageUpload(payload, setLoading, getCarImages, setImageArray, id);
  };
  const handleFeatureChange = (e, featureId) => {
    if (e.target.checked) {
      setSelectedFeatures([...selectedFeatures, featureId]);
    } else {
      setSelectedFeatures(selectedFeatures.filter((id) => id !== featureId));
    }
  };

  // State to hold form data
  const [formData, setFormData] = useState({
    carBrand: "",
    carModel: "",
    releaseYear: "",
    mileage: "",
    location: { lat: "", lng: "", location: "" },
    price: "",
    plate_number: "",
    state_id: "",
    country_id: "",
    registration_year: "",
    vehicle_milage: "",
    fuel_type: "",
    transmission_id: "",
    doors: "",
    seats: "",
    is_company: "0",
    city: "",
    address: "",
    zip: "",
    billing_country: "",
    billing_state: "",
    billing_country: "",
    driver_option_id: [],
    booking_option_id: "",
    meeting_point: "",
    vehicle_type: "",
    vehicle_region: "",
    vehicle_location: "",
    max_km_offered_per_day: "",
    price_per_km_max_exceeded: "",
    price_outof_region: "",
  });
  const convertDate = (newDateString) => {
    const newDate = new Date(newDateString);
    return newDate.toISOString().replace("T", " ").split(".")[0];
  };

  const handleSaveUnavailable = async () => {
    if (startDate && endDate) {
      let payload = {
        vehicle_id: id,
        start_time: convertDate(startDate),
        end_time: convertDate(endDate),
      };
      try {
        const response = await carUnavailbilty(payload);
        setStartDate(null);
        setEndDate(null);
        openDialog(response?.data?.message);
        unavailablePeriod(id, setUnavailableRanges);
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response ? error.response.data : error.message
        );
      }
    }
  };

  const highlightUnavailableDates = (date) => {
    return unavailableRanges?.some(
      (range) =>
        new Date(date) >= new Date(range.start) &&
        new Date(date) <= new Date(range.end)
    )
      ? "highlighted-date"
      : "";
  };const handleInputChangeDriverOption = (event) => {
    const { value, checked } = event.target;
    const featureId = parseInt(value);  // Ensure featureId is an integer
    const updatedFeatures = checked
      ? [...formData.driver_option_id, featureId]
      : formData.driver_option_id.filter((id) => id !== featureId);
  
    setFormData({
      ...formData,
      driver_option_id: updatedFeatures,  // Update driver options
    });
  };
  

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  console.log("editCar", formData?.driver_option_id);
  const getDriverOptionIds = (driverOptions) => {
    // Ensure driverOptions is an array, and extract driver_option_id from each option
    return driverOptions.map((option) => option.driver_option_id);
  };
  
  const getEditDetail = async () => {
    try {
      const response = await getEditCarDetail(
        id,
        setLoader,
        setEditList,
        openDialog
      );
      setLoader(false);
      const {
        make_id,
        vehicle_model,
        vehicle_year,
        vehicle_milage,
        vehicle_location,
        low_price,
        vehicle_plate_number,
        vehicle_registration_country,
        vehicle_registration_state,
        vehicle_milage_unit_id,
        vehicle_fuel_type_id,
        vehicle_transmission_id,
        vehicle_number_of_doors,
        vehicle_number_of_seats,
        feature,
        billing_country,
        is_company,
        billing_zipcode,
        billing_address,
        billing_city,
        billing_state,
        driver_option,
        booking_option_id,
        latitude,
        longitude,
        meeting_point_address,
        unavailable_dates,
        vehicle_type_id,
        vehicle_region,
      } = response.data.response.result;
      console.log("responser", response.data.response.result);
      // Set formData state with values from API
      setFormData({
        carBrand: make_id,
        carModel: vehicle_model,
        releaseYear: vehicle_year,
        mileage: vehicle_milage,
        location: vehicle_location,
        price: low_price,
        plate_number: vehicle_plate_number,
        state_id: vehicle_registration_state,
        country_id: vehicle_registration_country,
        registration_year: vehicle_year,
        vehicle_milage: vehicle_milage_unit_id,
        fuel_type: vehicle_fuel_type_id,
        transmission_id: vehicle_transmission_id,
        doors: vehicle_number_of_doors,
        seats: vehicle_number_of_seats,
        is_company: is_company,
        city: billing_city,
        address: billing_address,
        zip: billing_zipcode,
        billing_country: billing_country,
        billing_state: billing_state,
        driver_option_id: getDriverOptionIds(driver_option),
        booking_option_id: booking_option_id,
        vehicle_type: vehicle_type_id,
        vehicle_region: vehicle_region,
        vehicle_location: vehicle_location,
        max_km_offered_per_day: "",
        price_per_km_max_exceeded: "",
        price_outof_region: "",
      });
      setSelectDataCaseFiveLocation(meeting_point_address);
      setInputValue(vehicle_location);
      SetLocation({
        lat: latitude,
        lng: longitude,
        location: vehicle_location,
      });
      setSelectedFeatures(feature?.map((feature) => feature?.feature_id));
      setUnavailableRanges(unavailable_dates);
    } catch (response) {
      if (response?.response?.data?.message === "Token expired") {
        hardlyLogout();
      }
      openDialog(response?.response?.data?.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(true);
    if (openEditForm) {
      getEditDetail();
      getCarImages(id, setImageArray);
    }
  }, [openEditForm]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCK1M71JxoP14Pgs2UOMTd0USEvjg-6UAM",
    libraries,
  });
  const mapRef = useRef(null);
  console.log("imageArray", formData?.driver_option_id);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  const onMarkerDragEnd = async (event) => {
    const geocoder = new window.google.maps.Geocoder();

    const newLatLng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setLatLng(newLatLng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setInputValue(results[0].formatted_address);
          setSelectDataCaseFiveLocation(results[0].formatted_address);
          console.log("currentLocation", results[0].formatted_address);
        } else {
          alert("No results found");
        }
      } else {
        alert("Geocoder failed due to: " + status);
      }
    });
  };
  const handleFormSubmit = () => {
    console.log(formData);
    let payload = {
      ...formData,
      features: selectedFeatures,
      ...location,
      selectDataCaseFiveLocation: location?.location,
      start_time: convertDate(startDate),
      end_time: convertDate(endDate),
    };

    UpdateCar(id, payload, handleCloseEditModal, openDialog);
  };
  return (
    <Modal
      open={openEditForm}
      onClose={handleCloseEditModal}
      // handleOperation={handleFormSubmit}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      // BackdropComponent={Backdrop}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Set the background color
        timeout: 500,
      }}
    >
      <div className="modal-containers modal-con">
        <div className="modal-contents">
          <p
            class="close cross-icon"
            onClick={handleCloseEditModal}
            id="modal-description"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.51672 15.8995C1.12447 15.9223 0.738678 15.7919 0.440711 15.5358C-0.146904 14.9447 -0.146904 13.99 0.440711 13.3989L13.3075 0.532C13.9187 -0.0398937 14.8777 -0.00810248 15.4496 0.603069C15.9668 1.15575 15.9969 2.00523 15.5202 2.59314L2.57756 15.5358C2.28347 15.7882 1.90384 15.9184 1.51672 15.8995Z"
                fill="white"
              />
              <path
                d="M14.3684 15.8996C13.9708 15.8979 13.5898 15.74 13.3075 15.4601L0.440589 2.59313C-0.103806 1.9574 -0.0297925 1.00067 0.605933 0.456224C1.17334 -0.0296788 2.01014 -0.0296788 2.57749 0.456224L15.5201 13.3231C16.1312 13.8952 16.1628 14.8542 15.5907 15.4653C15.568 15.4896 15.5444 15.5131 15.5201 15.5358C15.3633 15.6722 15.1802 15.7748 14.9821 15.8374C14.7839 15.9 14.575 15.9211 14.3684 15.8996Z"
                fill="white"
              />
            </svg>
          </p>
          <body id="search_show">
            <div class="js_site_content site_content">
              {loader ? (
                <Spinner />
              ) : (
                <div
                  class="row car_preview_row js_car_preview_row"
                  data-car-id="1475829"
                >
                  <div
                    style={{ background: "#fff" }}
                    class="col-md-9 col-sm-8 car_preview_content_xs car_preview_content_xs--to_the_top js_car_preview_content_xs"
                  >
                    {" "}
                    <header class=" owner_homepage__hero_layer owner_landing_hero__wrapper">
                      <div style={{ width: "100%" }}>
                        <div style={{ width: "100%" }}>
                          <div>
                            {/* <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >              
               Details
              </h1> */}

                            <Unavailability
                              startDate={startDate}
                              setStartDate={setStartDate}
                              endDate={endDate}
                              setEndDate={setEndDate}
                              handleSaveUnavailable={handleSaveUnavailable}
                              highlightUnavailableDates={
                                highlightUnavailableDates
                              }
                            />
                          </div>

                          <div>
                            <div class="tab_panes_container formated_text  c-mb-sm">
                              <div class="tab_pane active" id="tab_photos">
                                <div class="cobalt-Card cobalt-Card--responsive">
                                  <div class="cobalt-Card__Section">
                                    <h1
                                      style={{
                                        marginTop: "10px",
                                        width: "100%",
                                      }}
                                      class="h1now"
                                    >
                                      {" "}
                                      Pictures of the vehicle
                                    </h1>
                                    <p class="c-mb-md">
                                      We only display cars with photos. You can{" "}
                                      <b>start with one</b> and add more after
                                      your listing is live.
                                    </p>
                                    <h3 class="c-text-section-heading c-mb-sm">
                                      Our tips
                                    </h3>
                                    <div class="row">
                                      <div class="flex_row_center c-mb-xs col-xs-12 col-lg-6">
                                        <span class="c-mr-xs car_picture_uploader__advice-icon car_picture_uploader__advice-icon--colorSuccess">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g
                                              fill="none"
                                              fill-rule="evenodd"
                                              stroke="none"
                                              stroke-width="1"
                                            >
                                              <path
                                                d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                                                fill="#b4bbc6"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                        <p>Use the landscape format</p>
                                      </div>
                                      <div class="flex_row_center c-mb-xs col-xs-12 col-lg-6">
                                        <span class="c-mr-xs car_picture_uploader__advice-icon car_picture_uploader__advice-icon--colorSuccess">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g
                                              fill="none"
                                              fill-rule="evenodd"
                                              stroke="none"
                                              stroke-width="1"
                                            >
                                              <path
                                                d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                                                fill="#b4bbc6"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                        <p>Follow our angle guidelines</p>
                                      </div>
                                      <div class="flex_row_center c-mb-xs col-xs-12 col-lg-6">
                                        <span class="c-mr-xs car_picture_uploader__advice-icon car_picture_uploader__advice-icon--colorSuccess">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g
                                              fill="none"
                                              fill-rule="evenodd"
                                              stroke="none"
                                              stroke-width="1"
                                            >
                                              <path
                                                d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                                                fill="#b4bbc6"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                        <p>
                                          Keep the background clear and neutral
                                        </p>
                                      </div>
                                      <div class="flex_row_center c-mb-md col-xs-12 col-lg-6">
                                        <span class="c-mr-xs car_picture_uploader__advice-icon car_picture_uploader__advice-icon--colorSuccess">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g
                                              fill="none"
                                              fill-rule="evenodd"
                                              stroke="none"
                                              stroke-width="1"
                                            >
                                              <path
                                                d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                                                fill="#b4bbc6"
                                              ></path>
                                            </g>
                                          </svg>
                                        </span>
                                        <p>Use only natural daylight</p>
                                      </div>
                                    </div>
                                    {addMoreImageArray.map((val, index) => (
                                      <>
                                        <hr class="car_picture_uploader__advice_divider" />
                                        <div
                                          class="js_car_picture_manager"
                                          data-base-count="4"
                                          data-car-photos-order-path="/cars/1514586/car_photos/order"
                                          data-maximum-extra-count="4"
                                        >
                                          <h3 class="c-text-section-heading c-mb-md">
                                            {val?.header}
                                          </h3>
                                          <div
                                            class="js_car_picture_uploader"
                                            data-car-photo-path="/cars/1514586/car_photo"
                                          >
                                            <div class="c-mb-md">
                                              <div class="js_car_picture_uploader_empty">
                                                <div class="row">
                                                  <div class="col-xs-12 col-md-6">
                                                    {loading[val.id] ? (
                                                      <div class="car_picture_uploader__wrapper">
                                                        <div
                                                          class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_spinner"
                                                          hidden=""
                                                        >
                                                          <div class="car_picture_uploader__placeholder_content">
                                                            <div class="cobalt-Spinner">
                                                              <span></span>
                                                              <span></span>
                                                              <span></span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {imageArray[
                                                          `image_${index + 1}`
                                                        ] ? (
                                                          <div class="car_picture_uploader__wrapper">
                                                            <div class="car_picture_uploader__actions">
                                                              <div
                                                                onClick={() =>
                                                                  handleDeleteImage(
                                                                    index + 1
                                                                  )
                                                                }
                                                                class="car_picture_uploader__actions_destroy js_car_picture_uploader_destroy"
                                                              >
                                                                <svg
                                                                  width="24"
                                                                  height="24"
                                                                  viewBox="0 0 24 24"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <g
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                  >
                                                                    <path
                                                                      d="M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z M6,19 C6,20.1045695 6.8954305,21 8,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,7 L6,7 L6,19 Z"
                                                                      fill="#b4bbc6"
                                                                    ></path>
                                                                  </g>
                                                                </svg>
                                                              </div>
                                                            </div>
                                                            <div class="car_picture_uploader__image js_car_picture_uploader_site">
                                                              <img
                                                                srcset={
                                                                  imageArray[
                                                                    `image_${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                }
                                                                style={{
                                                                  height:
                                                                    "198px",
                                                                  objectFit:
                                                                    "cover",
                                                                }}
                                                                src={
                                                                  imageArray[
                                                                    `image_${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                }
                                                                alt={
                                                                  imageArray[
                                                                    `image_${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                }
                                                              />
                                                              {/* <img srcset="https://drivy.gumlet.io/uploads/originals/5c4001b3fa204700bd6aa7b1737897bd.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=198&amp;mode=crop&amp;w=352 1x, https://drivy.gumlet.io/uploads/originals/5c4001b3fa204700bd6aa7b1737897bd.jpg?compress=true&amp;dpr=2&amp;fm=auto&amp;h=198&amp;mode=crop&amp;w=352 2x" src="https://drivy.gumlet.io/uploads/originals/5c4001b3fa204700bd6aa7b1737897bd.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=198&amp;mode=crop&amp;w=352"/> */}
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div class="car_picture_uploader__wrapper">
                                                            <label class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_text">
                                                              <input
                                                                class="car_picture_uploader__file-input js_car_picture_uploader_file_input"
                                                                name="file"
                                                                type="file"
                                                                onChange={(e) =>
                                                                  handleFileUpload(
                                                                    e.target
                                                                      .files[0],
                                                                    val.id
                                                                  )
                                                                }
                                                              />
                                                              <div class="car_picture_uploader__placeholder_content">
                                                                <div class="c-text-body-md c-text-subdued c-mb-xs">
                                                                  {val?.text}
                                                                </div>
                                                                <svg
                                                                  width="32"
                                                                  height="32"
                                                                  viewBox="0 0 24 24"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <g
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                  >
                                                                    <path
                                                                      d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 L11,7 L11,11 Z"
                                                                      fill="#b4bbc6"
                                                                    ></path>
                                                                  </g>
                                                                </svg>
                                                              </div>
                                                            </label>
                                                          </div>
                                                        )}
                                                      </>
                                                    )}

                                                    {/* waiting */}
                                                    {/* <div
                                    class="car_picture_uploader__callout js_car_picture_uploader_error_container"
                                    hidden="true"
                                  >
                                    <div class="cobalt-Callout cobalt-Callout--error js_car_picture_uploader_error_text"></div>
                                  </div> */}
                                                  </div>
                                                  <div class="col-xs-12 col-md-6">
                                                    <div class="row car_picture_uploader__description_container">
                                                      <div class="col-xs-6 col-md-12">
                                                        <div class="car_picture_uploader__description_text">
                                                          <p>
                                                            {val?.description}
                                                          </p>
                                                          {/* <p>
                                          <b>A 3/4 front photo</b>
                                          that stands out: it is the first one
                                          drivers see.
                                        </p> */}
                                                        </div>
                                                      </div>
                                                      <div class="col-xs-6 col-md-7">
                                                        <a
                                                          class="car_picture_uploader__description_image to_right"
                                                          data-modalid="js_see_example_car_picture_front_popin"
                                                          href="https://getaround.com/dashboard/cars/1514586/photos?origin=checklist#js_see_example_car_picture_front_popin"
                                                          data-modal-inited="true"
                                                        >
                                                          <img
                                                            srcset={val?.img}
                                                            src={val?.img}
                                                          />
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <svg
                            style={{ display: "none" }}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <defs>
                              <symbol
                                id="svg_icons-marker"
                                class="svg_from_sprite"
                                viewBox="0 0 24 24"
                              >
                                <g fill-rule="evenodd" stroke="none">
                                  <path d="M19,10 C19,15.0914792 13.07625,21.0728333 12.8254167,21.3249792 C12.5964583,21.5527708 12.2989583,21.6666667 12,21.6666667 C11.7010417,21.6666667 11.4035417,21.5527708 11.1745833,21.3249792 C10.9222917,21.0728333 5,15.0914792 5,10 C5,6.14022917 8.13979167,3 12,3 C15.8602083,3 19,6.14022917 19,10 Z M12,13.5 C13.9322917,13.5 15.5,11.9330208 15.5,10 C15.5,8.067125 13.9322917,6.5 12,6.5 C10.0677083,6.5 8.5,8.067125 8.5,10 C8.5,11.9330208 10.0677083,13.5 12,13.5 Z"></path>
                                </g>
                              </symbol>
                            </defs>
                          </svg>
                          <div className="cobalt-Card cobalt-Card--responsive c-mb-sm">
                            <div class="cobalt-Card__Section owner_homepage_hero_estimation_form__container js_car_model_estimation_form__container">
                              <h1 class="h1now">
                                Earn money by sharing your car with locals
                              </h1>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Make
                                </label>
                                <select
                                  name="carBrand"
                                  value={formData.carBrand}
                                  onChange={handleInputChange}
                                  id="car_model_estimation_car_brand_id"
                                  class=" labelwidth owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                  data-url-for-models="/car_models/models"
                                >
                                  {rentYourCar?.make?.map((val) => (
                                    <option value={val.id}>{val.title}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Model
                                </label>
                                <select
                                  name="carModel"
                                  value={formData.carModel}
                                  onChange={handleInputChange}
                                  id=" car_model_estimation_car_model_id"
                                  class=" owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_model_select js_submit_on_change js_show_placeholder js_submit_if_other"
                                >
                                  {/* <option disabled="disabled" selected="selected" value="">
                  Model
                </option> */}
                                  {rentYourCar?.model?.map((val) => (
                                    <option value={val.id}>{val.title}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Year
                                </label>
                                <select
                                  name="releaseYear"
                                  value={formData.releaseYear}
                                  onChange={handleInputChange}
                                  id="car_model_estimation_release_year"
                                  class="owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_release_year_select js_submit_on_change js_show_placeholder"
                                >
                                  {rentYourCar?.year?.map((val) => (
                                    <option value={val.year_id}>
                                      {val.year}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Milage
                                </label>
                                <select
                                  name="mileage"
                                  value={formData.mileage}
                                  onChange={handleInputChange}
                                  id="car_model_estimation_mileage"
                                  class="owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_mileage_select js_submit_on_change js_show_placeholder"
                                >
                                  {rentYourCar?.milage?.map((val) => (
                                    <option value={val?.milage_id}>
                                      {val?.milage}
                                    </option>
                                  ))}{" "}
                                </select>
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Vehicle Type
                                </label>
                                <select
                                  id="car_model_estimation_mileage"
                                  class="owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_mileage_select js_submit_on_change js_show_placeholder"
                                  required="required"
                                  name="vehicle_type"
                                  onChange={handleInputChange}
                                  value={formData?.vehicle_type}
                                >
                                  {rentYourCar?.vehcile_type?.map((val) => (
                                    <option value={val.vehicle_type_id}>
                                      {/* <img src={val?.image} style={{width:"20px",height:"20px"}} /> */}
                                      {val.vehicle_type}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  fuel{" "}
                                </label>
                                <select
                                  class="cobalt-SelectField__Input"
                                  name="fuel_type"
                                  value={formData.fuel_type}
                                  onChange={handleInputChange}
                                >
                                  {rentYourCar?.fuel_type?.map((val) => (
                                    <option value={val.fuel_type_id}>
                                      {val.fuel_type}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                What's your plate number?{" "}
                              </h1>

                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Plate number
                                </label>
                                <input
                                  type="text"
                                  name="plate_number"
                                  value={formData.plate_number}
                                  onChange={handleInputChange}
                                  style={{ paddingLeft: "8px" }}
                                  placeholder="Enter Plate Number"
                                />
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  State
                                </label>
                                <select
                                  class="cobalt-SelectField__Input"
                                  name="state_id"
                                  id="car_subdivision"
                                  onChange={handleInputChange}
                                  value={formData.state_id}
                                >
                                  {rentYourCar?.state?.map((val) => (
                                    <option value={val.id}>{val.name}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Year of first registration
                                </label>
                                <select
                                  class="cobalt-SelectField__Input"
                                  name="registration_year"
                                  id="car_release_year"
                                  onChange={handleInputChange}
                                  value={formData.registration_year}
                                >
                                  {rentYourCar?.year?.map((val) => (
                                    <option value={val.year_id}>
                                      {val.year}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Country of registration
                                </label>
                                <select
                                  class="cobalt-SelectField__Input"
                                  name="country_id"
                                  id="car_registration_country"
                                  onChange={handleInputChange}
                                  value={formData.country_id}
                                >
                                  {rentYourCar?.country?.map((val) => (
                                    <option value={val.id}>{val.name}</option>
                                  ))}
                                </select>
                              </div> */}
                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Where will you meet drivers for Region?
                              </h1>

                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Region{" "}
                                </label>
                                <select
                                  class="cobalt-SelectField__Input"
                                  name="vehicle_region"
                                  value={formData.vehicle_region}
                                  onChange={handleInputChange}
                                >
                                  {rentYourCar?.state?.map((val) => (
                                    <option value={val.id}>{val.name}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Location
                                </label>
                                <div class="cobalt-TextField cobalt-TextField--withIcon">
                                  <LocationSearch
                                    value={true}
                                    setSearchVehicles={setSearchVehicles}
                                    searchPage={true}
                                    SetLocation={SetLocation}
                                    setLatLng={setLatLng}
                                  />

                                  <span class="cobalt-TextField__Icon cobalt-Icon cobalt-Icon--locationPin">
                                    <svg
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M19 10c0 5.091-5.924 11.073-6.175 11.325a1.165 1.165 0 0 1-1.65 0C10.922 21.073 5 15.091 5 10c0-3.86 3.14-7 7-7s7 3.14 7 7zm-7 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{ width: "100%" }}
                                className="edit-form"
                              >
                                {location?.location && (
                                  <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    zoom={15}
                                    center={latLng}
                                    onLoad={(map) => (mapRef.current = map)}
                                  >
                                    {latLng && (
                                      <MarkerF
                                        position={latLng}
                                        draggable={true} // Make the marker draggable
                                        onDragEnd={onMarkerDragEnd} // Event when dragging ends
                                      />
                                    )}
                                  </GoogleMap>
                                )}
                              </div>
                              {/* Is the mileage accurate? */}

                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Is the mileage accurate?
                              </h1>

                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Price
                                </label>
                                <input
                                  type="number"
                                  name="price"
                                  value={formData.price}
                                  onChange={handleInputChange}
                                  style={{ paddingLeft: "8px" }}
                                  placeholder="Enter price"
                                />
                              </div>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Extra Pricing
                                </label>
                                <div
                                  class="cobalt-CheckmarkField"
                                  data-form-checkmark-method="transmission"
                                >
                                  <label class="cobalt-CheckmarkField__LabelWrapper">
                                    <input
                                      class="cobalt-CheckmarkField__Input"
                                      type="radio"
                                      value="per_km"
                                      checked={selectedExtraId === "per_km"}
                                      onChange={handlePricingChange}
                                      id="car_transmission_1"
                                    />
                                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                                    <span class="cobalt-CheckmarkField__Label">
                                      Per Km
                                    </span>
                                  </label>
                                </div>
                                <div
                                  class="cobalt-CheckmarkField"
                                  data-form-checkmark-method="transmission"
                                >
                                  <label class="cobalt-CheckmarkField__LabelWrapper">
                                    <input
                                      class="cobalt-CheckmarkField__Input"
                                      type="radio"
                                      value="region" // Example value, replace with your actual value
                                      checked={selectedExtraId === "region"} // Adjust this check as per your logic
                                      onChange={handlePricingChange}
                                      id="car_transmission_1"
                                    />
                                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                                    <span class="cobalt-CheckmarkField__Label">
                                      Region
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {selectedExtraId === "per_km" ? (
                                <>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label"
                                      for="car_plate_number"
                                    >
                                      Maximum kilometer offer by day{" "}
                                    </label>
                                    <input
                                      required
                                      type="number"
                                      name="max_km_offered_per_day"
                                      onChange={handleInputChange}
                                      value={formData.max_km_offered_per_day}
                                    />
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label"
                                      for="car_plate_number"
                                    >
                                      Maximum maximum kilometer exceeded{" "}
                                    </label>
                                    <input
                                      required
                                      type="number"
                                      name="price_per_km_max_exceeded"
                                      onChange={handleInputChange}
                                      value={formData.price_per_km_max_exceeded}
                                    />
                                  </div>
                                </>
                              ) : selectedExtraId === "region" ? (
                                <div className="edit-form">
                                  <label
                                    class="cobalt-FormField__Label"
                                    for="car_plate_number"
                                  >
                                    Extra Price for out of region{" "}
                                  </label>
                                  <input
                                    required
                                    type="number"
                                    name="price_outof_region"
                                    onChange={handleInputChange}
                                    value={formData?.price_outof_region}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Add discounts
                              </h1>
                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Add more details{" "}
                              </h1>
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Gearbox
                                </label>
                                {rentYourCar?.transmission?.map((val) => (
                                  <div
                                    class="cobalt-CheckmarkField"
                                    data-form-checkmark-method="transmission"
                                  >
                                    <label class="cobalt-CheckmarkField__LabelWrapper">
                                      <input
                                        class="cobalt-CheckmarkField__Input"
                                        type="radio"
                                        value={val?.transmission_id}
                                        checked={
                                          formData.transmission_id ===
                                          val.transmission_id
                                        }
                                        name="transmission_id"
                                        onChange={handleInputChange}
                                        id="car_transmission_1"
                                      />
                                      <span class="cobalt-CheckmarkField__Checkmark"></span>
                                      <span class="cobalt-CheckmarkField__Label">
                                        {val?.transmission_type}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                              <div
                                style={{ width: "100%" }}
                                className="edit-form"
                              >
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Number of doors
                                </label>
                                <div className="cobalt-CheckablePillsGroup">
                                  {rentYourCar?.doors?.map((val) => (
                                    <div
                                      className="cobalt-CheckablePillField"
                                      key={val.door_id}
                                    >
                                      <label className="cobalt-CheckablePillField__LabelWrapper">
                                        <input
                                          id={`checkable-pill-${val.door_id}`}
                                          className="cobalt-CheckablePillField__Input"
                                          type="radio"
                                          checked={
                                            formData.doors === val.door_id
                                          }
                                          value={val.door_id}
                                          onChange={handleInputChange}
                                          name="doors"
                                        />
                                        <span className="cobalt-CheckablePillField__Label">
                                          {val?.doors}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div
                                style={{ width: "100%" }}
                                className="edit-form"
                              >
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Number of seats{" "}
                                </label>
                                <div className="cobalt-CheckablePillsGroup">
                                  {rentYourCar?.seats?.map((val) => (
                                    <div
                                      className="cobalt-CheckablePillField"
                                      key={val.sear_id}
                                    >
                                      <label className="cobalt-CheckablePillField__LabelWrapper">
                                        <input
                                          id={`checkable-pill-${val.sear_id}`}
                                          className="cobalt-CheckablePillField__Input"
                                          type="radio"
                                          checked={
                                            formData.seats === val.sear_id
                                          }
                                          value={val.sear_id}
                                          onChange={handleInputChange}
                                          name="seats"
                                        />
                                        <span className="cobalt-CheckablePillField__Label">
                                          {val?.seats}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Any other features?
                              </h1>
                              <div
                                style={{ width: "100%" }}
                                className="edit-form"
                              >
                                <div class="cobalt-CheckablePillsGroup">
                                  {rentYourCar?.features?.map((val) => (
                                    <div
                                      class="cobalt-CheckablePillField cobalt-CheckablePillField--with-icon"
                                      data-form-checkmark-method="equipments"
                                    >
                                      <label class="cobalt-CheckablePillField__LabelWrapper">
                                        <input
                                          id={`checkable-pill-${val.feature_id}`}
                                          className="cobalt-CheckablePillField__Input"
                                          type="checkbox"
                                          name="features"
                                          checked={selectedFeatures.includes(
                                            val.feature_id
                                          )}
                                          onChange={(e) =>
                                            handleFeatureChange(
                                              e,
                                              val.feature_id
                                            )
                                          }
                                        />
                                        <span class="cobalt-CheckablePillField__Label">
                                          <span class="cobalt-Icon cobalt-Icon--car">
                                            <svg
                                              viewBox="0 0 24 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="m15.336 15.957-1.604-2.778h3.208l2.53 2.53a.749.749 0 1 0 1.06-1.06l-1.469-1.47H21a1 1 0 0 0 0-2h-1.939l1.469-1.47a.749.749 0 1 0-1.06-1.06l-2.53 2.53h-3.208L15.336 8.4l3.456-.925a.752.752 0 0 0-.388-1.45l-2.007.538.969-1.68a1 1 0 1 0-1.732-1l-.97 1.68-.537-2.007a.752.752 0 0 0-1.45.388l.927 3.456L12 10.179 10.397 7.4l.926-3.456a.75.75 0 1 0-1.449-.388l-.538 2.007-.97-1.68a1 1 0 0 0-1.732 1l.97 1.68-2.008-.538a.752.752 0 0 0-.388 1.45l3.456.925 1.604 2.778H7.061L4.53 8.649a.749.749 0 1 0-1.06 1.06l1.47 1.47H3a1 1 0 0 0 0 2h1.94l-1.47 1.47a.749.749 0 1 0 1.06 1.06l2.531-2.53h3.207l-1.604 2.778-3.456.926a.75.75 0 1 0 .388 1.449l2.008-.538-.97 1.68a1 1 0 0 0 1.732 1l.97-1.68.538 2.008a.75.75 0 1 0 1.449-.39l-.926-3.455L12 14.179l1.604 2.778-.927 3.456a.751.751 0 0 0 1.45.389l.537-2.008.97 1.68a1 1 0 0 0 1.732-1l-.969-1.68 2.007.538a.75.75 0 0 0 .388-1.45l-3.456-.925Z"
                                              ></path>
                                            </svg>
                                          </span>
                                          {val?.feature_name}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              {/* <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Are you operating as a registered business?
                              </h1> */}
                              {/* <div className="edit-form">
                                <div
                                  className="cobalt-CheckmarkField"
                                  data-form-checkmark-method="is_pro"
                                >
                                  <label className="cobalt-CheckmarkField__LabelWrapper">
                                    <input
                                      className="cobalt-CheckmarkField__Input"
                                      type="radio"
                                      value="1"
                                      name="is_company"
                                      id="car_is_pro_true"
                                      checked={formData?.is_company == "1"}
                                      onChange={handleInputChange}
                                    />
                                    <span className="cobalt-CheckmarkField__Checkmark"></span>
                                    <span className="cobalt-CheckmarkField__Label">
                                      Yes, I'm a professional owner
                                    </span>
                                  </label>
                                </div>

                                <div
                                  className="cobalt-CheckmarkField"
                                  data-form-checkmark-method="is_pro"
                                >
                                  <label className="cobalt-CheckmarkField__LabelWrapper">
                                    <input
                                      className="cobalt-CheckmarkField__Input"
                                      type="radio"
                                      value="0"
                                      name="is_company"
                                      id="car_is_pro_false"
                                      checked={formData?.is_company == "0"}
                                      onChange={handleInputChange}
                                    />
                                    <span className="cobalt-CheckmarkField__Checkmark"></span>
                                    <span className="cobalt-CheckmarkField__Label">
                                      No, I’m a private owner
                                    </span>
                                  </label>
                                </div>
                              </div> */}
                              {billing == "0" && (
                                <div>
                                  <h1
                                    style={{ marginTop: "10px", width: "100%" }}
                                    class="h1now"
                                  >
                                    What's your billing address?
                                  </h1>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      name="address"
                                      value={formData.address}
                                      onChange={handleInputChange}
                                      style={{ paddingLeft: "8px" }}
                                      placeholder="Enter address"
                                    />
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      ZIP code
                                    </label>
                                    <input
                                      type="text"
                                      name="zip"
                                      value={formData.zip}
                                      onChange={handleInputChange}
                                      style={{ paddingLeft: "8px" }}
                                      placeholder="Enter ZIP code"
                                    />
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      name="city"
                                      value={formData.city}
                                      onChange={handleInputChange}
                                      style={{ paddingLeft: "8px" }}
                                      placeholder="Enter City"
                                    />
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      Country
                                    </label>
                                    <select
                                      name="billing_country"
                                      value={formData.billing_country}
                                      onChange={handleInputChange}
                                      id="car_model_estimation_car_brand_id"
                                      class=" labelwidth owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                      data-url-for-models="/car_models/models"
                                    >
                                      {rentYourCar?.country?.map((val) => (
                                        <option value={val.id}>
                                          {val.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      Make
                                    </label>
                                    <select
                                      name="billing_state"
                                      value={formData.billing_state}
                                      onChange={handleInputChange}
                                      id="car_model_estimation_car_brand_id"
                                      class=" labelwidth owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                      data-url-for-models="/car_models/models"
                                    >
                                      {rentYourCar?.state?.map((val) => (
                                        <option value={val.id}>
                                          {val.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              )}
                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Services Offered
                              </h1>
                              <div className="edit-form">
                                <div
                                  className="cobalt-CheckmarkField"
                                  data-form-checkmark-method="is_pro"
                                >
                                  {rentYourCar?.driver_option?.map((val) => (
                                    <label className="cobalt-CheckmarkField__LabelWrapper">
                                      <input
                                        className="cobalt-CheckmarkField__Input"
                                        type="checkbox"
                                        value={val?.driver_option_id}
                                        checked={formData?.driver_option_id.includes(val.driver_option_id)} // Compare using numbers
                                        name="driver_option_id"
                                        onChange={handleInputChangeDriverOption}
                                      />
                                      <span className="cobalt-CheckmarkField__Checkmark"></span>
                                      <span className="cobalt-CheckmarkField__Label">
                                        {val?.driver_option}
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Booking Options
                              </h1>
                              <div className="edit-form">
                                <div
                                  className="cobalt-CheckmarkField"
                                  data-form-checkmark-method="is_pro"
                                >
                                  {rentYourCar?.booking_option?.map((val) => (
                                    <label className="cobalt-CheckmarkField__LabelWrapper">
                                      <input
                                        className="cobalt-CheckmarkField__Input"
                                        type="radio"
                                        value={formData?.booking_option_id}
                                        checked={
                                          formData.booking_option_id ===
                                          val.booking_option_id
                                        }
                                        name="booking_option_id"
                                        id="car_is_pro_true"
                                        onChange={handleInputChange}
                                      />
                                      <span className="cobalt-CheckmarkField__Checkmark"></span>
                                      <span className="cobalt-CheckmarkField__Label">
                                        {val?.booking_option}
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                              <h1
                                style={{ marginTop: "10px", width: "100%" }}
                                class="h1now"
                              >
                                Where will you meet drivers for check-in?
                              </h1>
                              {/* <div className="edit-form">
                    <label
                      class="cobalt-FormField__Label labelwidth"
                      for="car_brand_id"
                    >
                      Commune
                    </label>
                    <input
                      type="text"
                      name="commune

"
                      value={formData.commune}
                      onChange={handleInputChange}
                      
                      style={{ paddingLeft: "8px" }}
                      placeholder="Enter Commune
"
                    />
                  </div> */}
                              <div className="edit-form">
                                <label
                                  class="cobalt-FormField__Label labelwidth"
                                  for="car_brand_id"
                                >
                                  Meeting point
                                </label>
                                <div class="cobalt-TextField cobalt-TextField--withIcon">
                                  <LocationSearch
                                    value={true}
                                    setSearchVehicles={setSearchVehicles}
                                    searchPage={true}
                                    SetLocation={setSelectDataCaseFiveLocation}
                                    setLatLng={setLatLng}
                                  />
                                  <span class="cobalt-TextField__Icon cobalt-Icon cobalt-Icon--locationPin">
                                    <svg
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M19 10c0 5.091-5.924 11.073-6.175 11.325a1.165 1.165 0 0 1-1.65 0C10.922 21.073 5 15.091 5 10c0-3.86 3.14-7 7-7s7 3.14 7 7zm-7 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              {/* <div style={{ width: "100%" }} className="edit-form">
                    {location.location && (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        zoom={15}
                        center={latLng}
                        onLoad={(map) => (mapRef.current = map)}
                      >
                        {latLng && (
                          <MarkerF
                            position={latLng}
                            draggable={true} // Make the marker draggable
                            onDragEnd={onMarkerDragEnd} // Event when dragging ends
                          />
                        )}
                      </GoogleMap>
                    )}
                  </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>
                  </div>
                  <div
                    style={{ position: "sticky", top: "10px" }}
                    class="col-md-3 col-sm-4 no-outer-gutter-xs"
                  >
                    <div class="js_side_preview_form side_preview_form toggle_pointer_events">
                      <div class="preview_price_and_pick js_preview_price_and_pick">
                        <div class="cobalt-Card c-overflow-hidden c-shadow">
                          <div class="cobalt-Card__Section">
                            <div
                              class="hidden-xs centered c-mb-sm js_price_block"
                              data-original-title=""
                              title=""
                            ></div>
                            {/* <div
                          class="cobalt-Note cobalt-Note--fullWidth c-mb-sm js_delivery-car-already-picked"
                          hidden=""
                        >
                          You've already selected this car for delivery
                        </div>
                        <div
                          class="cobalt-Note cobalt-Note--fullWidth c-mb-sm js_car_already_picked"
                          hidden=""
                        >
                          <div class="cobalt-Icon cobalt-Icon--colorSuccess c-mr-xs">
                            <svg>
                              <use href="#cobalt/icon/checkCircle"></use>
                            </svg>
                          </div>
                          Vehicle already selected.
                        </div> */}

                            <button
                              class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                              data-car-id="1475829"
                              onClick={handleFormSubmit}
                            >
                              <span class="cob-Button__Icon cob-Button__Icon--left">
                                <span class="cobalt-Icon cobalt-Icon--colorInversed">
                                  <svg>
                                    <use href="#cobalt/icon/instant"></use>
                                  </svg>
                                </span>
                              </span>
                              <span>Update car</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </body>
        </div>
      </div>
    </Modal>
  );
};
