import React from "react";

const TechnicalFeatures = ({carDetail}) => {
  return(
    <div class="cobalt-layout-section">
    <div class="car_technical_features">
      <div class="car_technical_features__features_group">
        <div class="c-mb-xs" itemprop="fuelType">
          <div style={{color:"#7b728e"}} class="c-text-body-md c-text-subdued">
            Fuel type
          </div>
          <p class="c-text-body-md">{carDetail?.fuel_type}</p>
        </div>
        <div itemprop="mileageFromOdometer">
          <div style={{color:"#7b728e"}} class="c-text-body-md c-text-subdued">
            Mileage
          </div>
          <p class="c-text-body-md">{carDetail?.milage}</p>
        </div>
      </div>
      <div class="car_technical_features__features_group">
        <div class="c-mb-xs">
          <div style={{color:"#7b728e"}} class="c-text-body-md c-text-subdued">
            Transmission
          </div>
          <p class="c-text-body-md">{carDetail?.transmission_type}</p>
        </div>
      </div>
    </div>
  </div>

  );
};

export default TechnicalFeatures;
