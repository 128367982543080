import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './languageTranslator';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}else{
  // console.log("THIS IS NOT IN PRODUCTION YET!")
  // console.log = () => {}
  // console.error = () => {}
  // console.debug = () => {}
}

root.render(
  <React.StrictMode>
  <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
