import React, { useContext, useState, useEffect } from "react";
import logo from "../../image/png/logo.svg";
import { Alert } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../ContextApi";
import { ChangePassword } from "../../api";
const VerifyForget = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {   openDialog, alert,setAlert} =
    useContext(DataContext);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
const [token,setToken]=useState("")
  const handleVerify =  (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
        setAlert("Passwords do not match");
        setTimeout(() => {
            setAlert("")   
        }, 5000);
        return;
      }
      let payload={ 
        
        "token": token,
        "password":password
    }
    ChangePassword(payload,openDialog,setAlert,navigate)

  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('confirmation_token');

    if (token) {
        console.log('Confirmation Token:', token);
setToken(token)
    }
}, [location.search]);


  return (
    <>
      <div class="js_site_content site_content login">
        <div class="authentication_layout">
          <div class="authentication_layout__section authentication_layout__section--primary">
            <div class="authentication_layout__logo hidden-xs authentication_layout__logo--rebranded">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div class="visible-xs authentication_layout__close">
              <a class="link_no_style" aria-label="Close" href="/">
                ✕
              </a>
            </div>
            <div class="authentication_layout__content c-mb-md">
            <div class="authentication_header authentication_header--with_icon">
                <a
                  class="authentication_header__icon"
                  onClick={() => navigate("/login")}
                >
                  <div class="asphalt_svg">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                        stroke="none"
                        stroke-width="1"
                      >
                        <path
                          d="M22.2 10.7H6.6l7.5-7.4-1.7-1.8-8.8 8.7-.9.9-.9.9 10.6 10.5 1.7-1.8-7.5-7.5h15.6z"
                          fill="#b4bbc6"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </a>
                <div class="authentication_header__title">Change Password</div>
              </div>

          <form onSubmit={handleVerify} className="new_user" id="new_user"  accept-charset="UTF-8" method="post">
          <input type="hidden" name="_method" value="patch" autoComplete="off" />
          <input type="hidden" name="authenticity_token" value="your_auth_token_here" autoComplete="off" />
          <input type="hidden" value="your_reset_password_token_here" name="user[reset_password_token]" id="user_reset_password_token" autoComplete="off" />

          <div className="c-mb-sm">
            <div className="cobalt-FormField" data-form-field-method="password">
              <label className="cobalt-FormField__Label" htmlFor="user_password">New password</label>
              <div className="cobalt-TextField">
                <input autoFocus className="js_password_input cobalt-TextField__Input" type="password" name="user[password]" id="user_password" onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>
          </div>
          
          <div className="c-mb-md">
            <div className="cobalt-FormField" data-form-field-method="password_confirmation">
              <label className="cobalt-FormField__Label" htmlFor="user_password_confirmation">Confirm password</label>
              <div className="cobalt-TextField">
                <input className="cobalt-TextField__Input" type="password" name="user[password_confirmation]" id="user_password_confirmation" onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>

            </div>
          </div>
          <div style={{marginBottom:"10px"}}>{alert && <Alert severity="error">{alert}</Alert>}</div>

          <input type="submit" name="commit" value="Change password" className="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth cob-Button--large c-mb-md" />
        </form>

             
            </div>
            <div class="authentication_layout__footer footer-wrapper--2024 c-border-t c-border-base">
              <div class="corporate_footer__container container sm:c-flex-row-reverse">
                <div class="corporate_footer__copyright_links_container">
                  <ul class="corporate_footer__links c-mb-sm md:c-mb-none">
                    <li class="corporate_footer__link corporate_footer__link--copyright c-text-subdued c-text-center">
                      © Overlo 2010-2024 - All rights reserved
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Legal terms
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a
                        class=""
                        href="/privacy"
                        rel="nofollow"
                        target="_blank"
                      >
                        Privacy policy
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Cookies
                      </a>
                    </li>
                    <li class="corporate_footer__link">
                      <a href="">Press</a>
                    </li>
                    <li class="corporate_footer__link">
                      <a href="">Careers</a>
                    </li>
                    <li class="corporate_footer__link">
                      <a class="" href="" rel="nofollow" target="_blank">
                        Investor relations
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="authentication_layout__section authentication_layout__section--secondary authentication_layout__section--background_2"></div>
        </div>
      </div>
    </>
  );
};

export default VerifyForget;
