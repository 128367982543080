import React, { useContext } from "react";
import { handleChange } from "../../../../ExportFiles";
import { DataContext } from "../../../../ContextApi";

const BillingAddress = () => {
  const { setSelectDataCaseFour, selectDataCaseFour,rentYourCar } = useContext(DataContext);
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">What's your billing address?</div>
        <div class="c-mb-lg">
          <div class="cobalt-FormField" data-form-field-method="address_line1">
            <label class="cobalt-FormField__Label" for="car_address_line1">
              Address
            </label>
            <div class="cobalt-TextField">
              <input
                class="cobalt-TextField__Input"
                type="text"
                name="address"
                value={selectDataCaseFour?.address}

                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseFour,
                    selectDataCaseFour,
                    "caseFour"
                  )
                }
              />
            </div>
          </div>
          {/* <div class="cobalt-FormField" data-form-field-method="address_line2">
            <label class="cobalt-FormField__Label" for="car_address_line2">
              Address line 2
            </label>
            <div class="cobalt-TextField">
            <input
                class="cobalt-TextField__Input"
                type="text"
                name="address2"
                value={selectDataCaseFour?.address2}
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseFour,
                    selectDataCaseFour,
                    "caseFour"
                  )
                }
              />
            </div>
          </div> */}
          <div class="cobalt-FormField" data-form-field-method="postal_code">
            <label class="cobalt-FormField__Label" for="car_postal_code">
              ZIP code
            </label>
            <div class="cobalt-TextField">
            <input
                class="cobalt-TextField__Input"
                type="text"
                name="zip_code"
                value={selectDataCaseFour?.zip_code}

                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseFour,
                    selectDataCaseFour,
                    "caseFour"
                  )
                }
              />
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="city">
            <label class="cobalt-FormField__Label" for="car_city">
              City
            </label>
            <div class="cobalt-TextField">
            <input
                class="cobalt-TextField__Input"
                type="text"
                name="city"
                value={selectDataCaseFour?.city}

                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseFour,
                    selectDataCaseFour,
                    "caseFour"
                  )
                }
              />
            </div>
          </div>
          <div class="cobalt-FormField" data-form-field-method="country">
            <label class="cobalt-FormField__Label" for="car_country">
              Country
            </label>
            <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="country"

                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseFour,
                    selectDataCaseFour,
                    "caseFour"
                  )
                }
                value={selectDataCaseFour?.country}
              >
               {rentYourCar?.country?.map((val) => (
                  <option value={val.id}>{val.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="js_country-subdivision" hidden="">
            <div class="cobalt-FormField" data-form-field-method="subdivision">
              <label class="cobalt-FormField__Label" for="car_subdivision">
                State
              </label>
              <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="state"

                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseFour,
                    selectDataCaseFour,
                    "caseFour"
                  )
                }
                value={selectDataCaseFour?.state}
              >
               {rentYourCar?.state?.map((val) => (
                  <option value={val.id}>{val.name}</option>
                ))}
              </select>
              </div>
            </div>
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          In order for you to receive payments, we use your billing address,
          phone number and email. You can edit them in your account details.
        </div>
      </div>
    </>
  );
};

export default BillingAddress;
