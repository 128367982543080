import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";
import "./reservation.css";
const CancelationTypeInsurance = () => {
  const { setSelectDataCaseThree, selectDataCaseThree, rentYourCar } =
    useContext(DataContext);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Car replace</div>
      
        <div class="c-text-section-heading c-mb-xs">Insurance</div>

        <div className="c-mb-lg">
            <div
              className="cobalt-CheckmarkField"
              data-form-checkmark-method="is_pro"
            >
              <div>
                <div className="discount-option">
                  <input
                    type="checkbox"
                    name="discount"

                    // checked={selectDataCaseThree.discount.includes("1")}
                    //   value="1"
                    //   onChange={(e) =>
                    //     handleChange(
                    //       e,
                    //       setSelectDataCaseThree,
                    //       selectDataCaseThree,
                    //       "caseThree"
                    //     )
                    //   }
                  />
                  <div className="discount-details">
                    <span className="discount-percentage">10%</span>
                    <div className="discount-info">
                      <strong>Promotion Nouvelles annonces</strong>
                      <p>Offer a 20% discount on your first 3 bookings</p>
                    </div>
                  </div>
                </div>

                <div className="discount-option">
                  <input
                    type="checkbox"
                    name="discount"

                    // checked={selectDataCaseThree.discount.includes("2")}
                    //   value="2"
                    //   onChange={(e) =>
                    //     handleChange(
                    //       e,
                    //       setSelectDataCaseThree,
                    //       selectDataCaseThree,
                    //       "caseThree"
                    //     )
                    //   }
                  />
                  <div className="discount-details">
                    <span className="discount-percentage">10%</span>
                    <div className="discount-info">
                      <strong>Weekly reduction</strong>
                      <p>For stays of 7 nights or more</p>
                    </div>
                  </div>
                </div>

                <div className="discount-option">
                  <input
                    type="checkbox"
                    name="discount"

// checked={selectDataCaseThree.discount.includes("3")}
//   value="3"
//   onChange={(e) =>
//     handleChange(
//       e,
//       setSelectDataCaseThree,
//       selectDataCaseThree,
//       "caseThree"
//     )
//   }
                  />
                  <div className="discount-details">
                    <span className="discount-percentage">19%</span>
                    <div className="discount-info">
                      <strong>Monthly discount</strong>
                      <p>For stays of 28 nights or more</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          We'll only contact you with important info about your rentals (no spam
          ✌️).
        </div>
      </div>
    </>
  );
};

export default CancelationTypeInsurance;
