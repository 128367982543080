import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./ContextApi/AuthContext";
import Spinner from "./ReusableComponent/Spinner";

function PrivateRoute({ element, ...rest }) {
  const { isLoggedIn, loading } = useContext(AuthContext);
console.log("isLoggedIndd",isLoggedIn)
  if (loading) {
    return <Spinner/>;
  }

  return isLoggedIn ? (
    React.cloneElement(element, rest)
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
