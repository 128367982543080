import React from "react";
import pricing_edu from "../../../../image/png/pricing_edu.jpg"
import "../../../search.css"
const PricingEducation = () => {
  return (
    <div class="pricing-education-seasonality">
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">How can I optimize my prices?</div>
        <p class="c-mb-md">
          There is more demand on <b>weekends</b> and during{" "}
          <b>school vacations</b>. Adapting your prices is the best way to make
          sure you’re renting at a good price.
        </p>
        <div class="c-flex c-items-center c-gap-2xs c-mb-lg">
          <div>Demand</div>
          <div class="cobalt-Tag pricing-demand-legend--low-ncf">Low</div>
          <div class="cobalt-Tag pricing-demand-legend--mid-ncf">Medium</div>
          <div class="cobalt-Tag pricing-demand-legend--high-ncf">High</div>
        </div>
        <div class="months-container sm:c-mx-xl">
          <div class="month">
            <div class="month-name">January</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">February</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">March</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">April</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">May</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">June</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">July</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">August</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">September</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">October</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">November</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
          <div class="month">
            <div class="month-name">December</div>
            <img src={pricing_edu} alt="pricing_edu" />
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth c-mt-md">
          Illustration for reference only.
        </div>
      </div>
    
    </div>
  );
};

export default PricingEducation;
