import React from "react";
import { useNavigate } from "react-router-dom";
import { LeftBarArray } from "../../../../ExportFiles";

const LeftBar = () => {
  const navigate = useNavigate();
  const checkCurrentPath = (val) => {
    if (window.location.pathname === `/main-menu/profile/${val}`) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div class="col-sm-3 col-xs-12">
      <nav class="nav_list_stacked js_nav_list_stacked">
        {LeftBarArray.map((val) => (
          <a
            onClick={() => navigate(val.path)}
            key={val.id}
            className={`nav_item_link ${
              checkCurrentPath(val.path) ? "active" : ""
            }`}
          >
            {val.text}
          </a>
        ))}

        <div class="xs_expend_nav_list js_responsive_nav_list"></div>
      </nav>
    </div>
  );
};

export default LeftBar;
