import React, { useContext } from "react";
import "./FuelType.css";
import { handleAllFilters } from "../ReusableComponent/OverAllFilter";
import { DataContext } from "../ContextApi";

export default function FuelType({ fuel }) {
  const { filterValue, setFilterValue } = useContext(DataContext);

  const handleCheckboxChange = (fuelType) => {
    const isSelected = filterValue.fuelType?.some(fuel => fuel.fuel_type_id === fuelType.fuel_type_id);
    handleAllFilters(
      "fuelType",
      fuelType,
      setFilterValue,
      filterValue.fuelType
    );
  };

  return (
    <div className="FuelType_main_div">
      <div className="FuelType_text">FUEL TYPE</div>
      <div className="FuelType_btns_div">
        {fuel?.map((val) => {
          const isChecked = filterValue?.fuelType.some(fuel => fuel.fuel_type_id === val.fuel_type_id);
          
          return (
            <div key={val.fuel_type_id} onClick={() => handleCheckboxChange(val)}>
              <input
                checked={isChecked}
                type="checkbox"
                onChange={() => handleCheckboxChange(val)}
              />
              <label htmlFor="">{val.fuel_type}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
