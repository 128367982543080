import React,{useState} from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const Calender = ({unavailableSlots}) => {
    const [selectedDate, setSelectedDate] = useState(null);

    // Example highlighted dates

  const highlightUnavailableDates = (date) => {
    return unavailableSlots?.some(
      (range) => new Date(date) >= new Date(range.start) && new Date(date) <= new Date(range.end)
    )
      ? "highlighted-date"
      : "";
  };
  return (
  
<div>
                                <DatePicker
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date)}
      inline
      monthsShown={2} // Show two months side by side
      dayClassName={highlightUnavailableDates}

      calendarClassName="full-width-calendar"

    
    />
    </div>

)
};

export default Calender;
