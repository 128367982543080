// DataContext.js
import React, { createContext, useEffect, useState } from "react";
import { getrentYourCarData } from "../api";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [filterValue, setFilterValue] = useState({
    vehicleType: [],
    driverOption: {},
    pick_up_date_time: null,
    drop_off_date_time: null,
    instantBooking: false,
    numberSeats: 0,
    newCarOnly: false,
    features: [],
    transmission: {},
    fuelType: [],
    carMake: [],
    acceptRate: false,
    price: 30,
    // lat:"",
    // lng:""
  });
  const [imageArray, setImageArray] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [rentYourCar, setRentYourCar] = useState([]);
  const [alert, setAlert] = useState("");
  const [imageURLs, setImageURLs] = useState([]); // Array to store image URLs

  const [selectDataCaseOne, setSelectDataCaseOne] = useState({
    carBrand: "",
    carModel: "",
    releaseYear: "",
    milage: "",
    country: "",
    vehicleType: "",
    transmissionType: "",
    fuel_type: "",
  });
  const [selectDataCaseTwo, setSelectDataCaseTwo] = useState({
    plate_number: "",
    country_id: "",
    state_id: "",
    registration_year: "",
    milage: "",
    max_km_offered_per_day: "",
    price_per_km_max_exceeded: "",
    price_outof_region: "",
    region_state_id: "",
    price: "",
  });
  const [selectDataCaseThree, setSelectDataCaseThree] = useState({
    fuel_type: "",
    transmission_id: "",
    doors: "",
    seats: "",
    features: [],
    discount:[],
    cancelation:[],
    is_company: "1",
    description: "",
  });
  const [checkboxValues, setCheckboxValues] = useState({
    pickup: 0,
    dropoff: 0,
    withDriver: 0,
    withoutDriver: 0,
  });
  const [selectDataCaseFour, setSelectDataCaseFour] = useState({
    address: "",
    address2: "",

    zip_code: "",
    city: "",
    country: "",
    driver_option_id: [],
    state: "",
    phone: "",
    country_code: "",

    is_wellMaintained: "",
    is_upto_date_roadworthiness: "",
  });
  const [selectDataCaseFiveLocation, setSelectDataCaseFiveLocation] =
    useState("");
  const [latLngRegion, setLatLngRegion] = useState({lat:"",lng:""});
  useEffect(() => {
    getrentYourCarData(setRentYourCar);
  }, []);
  console.log("filterValue", latLngRegion);
  useEffect(() => {
    const savedStateOne = getSavedState("caseOne");
    const savedStateTwo = getSavedState("caseTwo");
    const savedStateThree = getSavedState("caseThree");
    const savedStateFour = getSavedState("caseFour");

    if (savedStateOne) {
      setSelectDataCaseOne({ ...savedStateOne, price: "" });
    } else if (
      rentYourCar?.make?.length > 0 &&
      rentYourCar?.model?.length > 0 &&
      rentYourCar?.year?.length > 0
    ) {
      setSelectDataCaseOne({
        carBrand: rentYourCar.make[0].id,
        carModel: rentYourCar.model[0].id,
        releaseYear: rentYourCar.year[0].year_id,
        milage: rentYourCar.milage[0].milage_id,
        country: "",
        vehicleType: rentYourCar.vehcile_type[0].vehicle_type_id,
        transmissionType: rentYourCar.transmission[0].transmission_id,
        fuelType: rentYourCar?.fuel_type[0].fuel_type_id,
      });
    }

    if (savedStateTwo) {
      setSelectDataCaseTwo(savedStateTwo);
    } else if (
      rentYourCar?.country?.length > 0 &&
      rentYourCar?.state?.length > 0 &&
      rentYourCar?.milage?.length > 0 &&
      rentYourCar?.year?.length > 0
    ) {
      setSelectDataCaseTwo({
        plate_number: "",
        country_id: rentYourCar.country[0].id||"",
        state_id: rentYourCar.state[0].id||"",
        registration_year: rentYourCar.year[0].year_id||"",
        milage: rentYourCar.milage[0].milage_id||"",
        max_km_offered_per_day: "",
        price_per_km_max_exceeded: "",
        price_outof_region: "",
        region_state_id: rentYourCar.state[0].id||"",
        price: "",
      });
    }
    if (savedStateThree) {
      setSelectDataCaseThree(savedStateThree);
    } else if (
      rentYourCar?.fuel_type?.length > 0 &&
      rentYourCar?.transmission?.length > 0 &&
      rentYourCar?.doors?.length > 0 &&
      rentYourCar?.seats?.length > 0
    ) {
      setSelectDataCaseThree({
        transmission_id: rentYourCar.transmission[0].transmission_id||"",
        doors: rentYourCar.doors[0].door_id||"",
        seats: rentYourCar.seats[0].sear_id||"",
        fuel_type: rentYourCar?.fuel_type[0].fuel_type_id||"",
        features: [],
        discount:[],
        cancelation:[],

        is_company: "1",
        description: "",
      });
    }
    if (savedStateFour) {
      setSelectDataCaseFour(savedStateFour);
    } else if (
      rentYourCar?.country?.length > 0 &&
      rentYourCar?.state?.length > 0
    ) {
      setSelectDataCaseFour({
        address: "",
        address2: "",
        zip_code: "",
        city: "",
        driver_option_id: [],
        booking_option_id: rentYourCar.booking_option[0]?.booking_option_id||"",
        country: rentYourCar.country[0].id||"",
        state: rentYourCar.state[0].id||"",
        phone: "",


        country_code: "",
        is_wellMaintained: "",
        is_upto_date_roadworthiness: "",
      });
    }
  }, [rentYourCar]);

  const getSavedState = (key) => {
    const savedState = sessionStorage.getItem(key);
    return savedState ? JSON.parse(savedState) : null;
  };

  const [searchVehicles, setSearchVehicles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [registerId, setRegisterId] = useState("");
  const [isFilterActive, setIsFilterActive] = useState({
    priceFilterActive: false,
    vehicleTypeFilter: false,
    pickupFilter: false,
  });
  console.log("selectDataCaseOne", selectDataCaseOne);
  const [state, setState] = useState({
    open: false,
    vertical: "center",
    horizontal: "center",
    message: "loves",
  });
  const { vertical, horizontal, open, message } = state;

  const handleClickSnackbar = (message, newState) => {
    setState({ ...newState, open: true, message: message });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [dialogState, setDialogState] = useState({
    open: false,
    message: "",
  });

  const openDialog = (message, prevState) => {
    setDialogState({ ...prevState, open: true, message });
  };

  const closeDialog = () => {
    setDialogState({ ...dialogState, open: false });
  };

  return (
    <DataContext.Provider
      value={{
        selectDataCaseTwo,
        vertical,
        horizontal,
        open,
        handleClose,
        state,
        setState,
        handleClickSnackbar,
        message,
        setSelectDataCaseTwo,
        openDialog,
        imageURLs,
        setImageURLs,
        filterValue,
        selectDataCaseOne,
        selectDataCaseFour,
        setSelectDataCaseFour,
        registerId,
        setRegisterId,
        checkboxValues, setCheckboxValues,
        selectDataCaseThree,
        selectDataCaseFiveLocation,
        setSelectDataCaseFiveLocation,
        setSelectDataCaseThree,
        latLngRegion,
        setLatLngRegion,
        setSelectDataCaseOne,
        setFilterValue,
        searchVehicles,
        setSearchVehicles,
        loader,
        imageArray,
        setImageArray,
        inputValue,
        setInputValue,
        setLoader,
        isFilterActive,
        setIsFilterActive,
        rentYourCar,
        setRentYourCar,
        alert,
        setAlert,
      }}
    >
      {children}
      <Dialog
        open={dialogState?.open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
      >
        <div style={{ padding: "20px", fontFamily: "BrownProMarketing" }}>
          <div>{dialogState.message}</div>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <button
              style={{ width: "45px", height: "35px" }}
              className="button-color"
              onClick={closeDialog}
            >
              OK
            </button>
          </div>
        </div>
        {/* <DialogTitle id="alert-dialog-title">
          {dialogState.message}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </DataContext.Provider>
  );
};
