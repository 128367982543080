import React from "react";
import { Backdrop, Modal } from "@material-ui/core";
import "./modal.css";

const ModalReusable = ({
  open,
  handleClose,
  children,
  handleOperation,
  modalHeader,
  buttonText,
  setFilterSelect
}) => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
if(setFilterSelect)
  {
    setFilterSelect(true)
  }    handleOperation(); // Call handleOperation function passed from props
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
    style: { backgroundColor: 'rgba(0, 0, 0, 0.85)' }, // Set the background color
    timeout: 500,
  }}
    >
      <div style={{width:modalHeader==="Driver Add"?"400px":""}} className={`modal-main`}>
        <div className="modal-container ">
          <h3 id="modal-title">{modalHeader}</h3>
          <p
class="closeother cross-icon"
            onClick={handleClose}
            id="modal-description"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.51672 15.8995C1.12447 15.9223 0.738678 15.7919 0.440711 15.5358C-0.146904 14.9447 -0.146904 13.99 0.440711 13.3989L13.3075 0.532C13.9187 -0.0398937 14.8777 -0.00810248 15.4496 0.603069C15.9668 1.15575 15.9969 2.00523 15.5202 2.59314L2.57756 15.5358C2.28347 15.7882 1.90384 15.9184 1.51672 15.8995Z"
                fill="white"
              />
              <path
                d="M14.3684 15.8996C13.9708 15.8979 13.5898 15.74 13.3075 15.4601L0.440589 2.59313C-0.103806 1.9574 -0.0297925 1.00067 0.605933 0.456224C1.17334 -0.0296788 2.01014 -0.0296788 2.57749 0.456224L15.5201 13.3231C16.1312 13.8952 16.1628 14.8542 15.5907 15.4653C15.568 15.4896 15.5444 15.5131 15.5201 15.5358C15.3633 15.6722 15.1802 15.7748 14.9821 15.8374C14.7839 15.9 14.575 15.9211 14.3684 15.8996Z"
                fill="white"
              />
            </svg>{" "}
          </p>
        </div>
        {buttonText==="Update my profile"
        ?
        <div>

          {children}
          <div className="modal-container-footer">
            <button onClick={handleOperation} className="button-color">
              {buttonText}
            </button>
          </div>
        </div>
        :
        <form onSubmit={handleSubmit}>
          {children} {/* Render modal content (inputs, text, etc.) */}
          {modalHeader!=="Reviews"&&
          <div className="modal-container-footer">
            <button type="submit" className="button-color">
              {buttonText}
            </button>
          </div>
          }
        </form>
        }
      </div>
    </Modal>
  );
};

export default ModalReusable;
