import React from "react";

const OwnerRules = ({rules}) => {
  return (
  
    <div class="cobalt-layout-section">
    <div class="car_owner_guidelines_wrapper">
      <div class="car_owner_extra_conditions">
        <div class="js_car_owner_extra_conditions c-text-body-md">
        {rules?.map((val)=>(

          <p>
           {val?.rule_detail}
          </p>
        ))}

        </div>
        {/* <div
          class="js_car_owner_extra_conditions_see_more c-font-brand c-text-accentInteractive c-pt-xs c-cursor-pointer"
          hidden=""
          type="button"
        >
          + See full rules
        </div> */}
      </div>
    </div>
  </div>

)
};

export default OwnerRules;
