import React from "react";
import character from "../../.././image/png/character_with.svg"
const Performance = () => {
  return (
<div class="container dashboard_container" id="dashboard">

<div class="c-text-center">
  <img class="cobalt-illustration-image cobalt-EmptyState__Image" role="presentation" alt="character_in_jeep"
    width="188" lazyload="false"
   src={character}/>
  <h2 class="cobalt-EmptyState__Title">No data available</h2>
  <div class="cobalt-EmptyState__Body">You need an active car or at least one rental since last year.</div>
</div>

</div>  

)
};

export default Performance;
