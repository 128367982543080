import React, { useContext } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const Registration = () => {
  const { rentYourCar, selectDataCaseTwo, setSelectDataCaseTwo } =
    useContext(DataContext);

  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-xl">What's your plate number?</div>
       
     
        <div class="js_country-subdivision" hidden="">
          <div class="cobalt-FormField" data-form-field-method="subdivision">
            <label class="cobalt-FormField__Label" for="car_subdivision">
              State
            </label>
            <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="state_id"
                id="car_subdivision"
                onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseTwo,
                  selectDataCaseTwo,
                  "caseTwo"
                )
              }
              value={selectDataCaseTwo.state_id}
              >
                {rentYourCar?.state?.map((val) => (
                  <option value={val.id}>{val.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div
          class="cobalt-FormField cobalt-FormField--withHint"
          data-form-field-method="release_year"
          data-form-field-hint-status="hint"
        >
          <label class="cobalt-FormField__Label" for="car_release_year">
            Year of first registration
          </label>
          <div class="cobalt-SelectField">
            <select
              class="cobalt-SelectField__Input"
              name="registration_year"
              id="car_release_year"
              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseTwo,
                  selectDataCaseTwo,
                  "caseTwo"
                )
              }
              value={selectDataCaseTwo.registration_year}
            >
              {rentYourCar?.year?.map((val) => (
                <option value={val.year_id}>{val.year}</option>
              ))}
            </select>
          </div>
          <div class="cobalt-Hint">
            <span class="cobalt-Hint__Icon">
              <span class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z"></path>
                </svg>
              </span>
            </span>
            <span class="cobalt-Hint__Message">
              You can find this information on the car registration certificate.
            </span>
          </div>
        </div>
        <div class="cobalt-FormField" data-form-field-method="plate_number">
          <label class="cobalt-FormField__Label" for="car_plate_number">
            Plate number
          </label>
          <div class="cobalt-TextField">
            <input
              required
              class="cobalt-TextField__Input"
              type="text"
              name="plate_number"
              id="car_plate_number"
              onChange={(e) =>
                handleChange(
                  e,
                  setSelectDataCaseTwo,
                  selectDataCaseTwo,
                  "caseTwo"
                )
              }
              value={selectDataCaseTwo.plate_number}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
