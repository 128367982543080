import React from "react";

const DollarBar = () => {
  return (
  

    <div class="owner_homepage_usps">
    <div class="container">
      <ul class="owner_usps">
        <li class="owner_usps__item">
          <div class="owner_usps__detail">1.6 million</div>
          <p class="c-text-body-md c-text-subdued">drivers</p>
        </li>
        <li class="owner_usps__item">
          <div class="owner_usps__detail">5.6 million</div>
          <p class="c-text-body-md c-text-subdued">trips completed</p>
        </li>
        <li class="owner_usps__item">
          <div class="owner_usps__detail">24/7</div>
          <p class="c-text-body-md c-text-subdued">Customer service</p>
        </li>
        <li class="owner_usps__item">
          <div
            class="owner_usps__detail"
            data-name="protection_us"
          ></div>
          <p class="c-text-body-md c-text-subdued">
            Extensive coverage
          </p>
        </li>
      </ul>
    </div>
  </div>)
};

export default DollarBar;
