import React, { useContext } from 'react';
import './Transmission.css';
import { DataContext } from '../ContextApi';

export default function Transmission({ transmission }) {
  const { filterValue, setFilterValue } = useContext(DataContext);

  const handleChange = (val) => {
    setFilterValue({ ...filterValue, transmission: val });
  };

  return (
    <div className='Transmission_main_div'>
      <div className='Transmission_text'>TRANSMISSION</div>
      <div className='radio_btns_div'>
        {transmission?.map((val) => (
          <div key={val.transmission_type}>
            <input
              type="radio"
              name="transmission"
              id={`transmission-${val.transmission_type}`}
              value={val.transmission_type}
              checked={filterValue.transmission.transmission_type === val.transmission_type}
              onChange={() => handleChange(val)}
            />
            <label htmlFor={`transmission-${val.transmission_type}`}>
              {val.transmission_type}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
