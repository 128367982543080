import React, { useContext } from "react";
import { DataContext } from "../ContextApi";
import { handleAllFilters } from "../ReusableComponent/OverAllFilter";

const CarMake = ({carMake}) => {
  const { filterValue, setFilterValue } = useContext(DataContext);

  return (
  
<div className='Numberofseats_main_div'>
            <div className='NumberOfSeats_text'>CAR MAKE</div>
            <div className='MinimumSeats_div'>
            <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
               {carMake?.map((val) => ( 
                  <div  style={{
                      backgroundColor: filterValue?.carMake?.includes(val)
                        ? "#ffc947"
                        : "",
                    }} onClick={() =>
                      handleAllFilters(
                        "carMake",
                        val,
                        setFilterValue,
                        filterValue.carMake
                        
                      )
                    } className="drop-down-circle-internal">
                    <p>{val.make_name
}</p>
                  </div>
                ))}
              </div>
            </div>
        </div>)
};

export default CarMake;
