import React from "react";

const Notification = () => {
  return (
    <div class="col-sm-9 col-xs-12">
    <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
      <div class="cobalt-Card__Section">
        <div class="tab_panes_container">
          <div class="tab_pane active tab_notifications">
            <form class="edit_user" id="edit_user_5970957"
              action="/dashboard/profile/update_notification_preferences" accept-charset="UTF-8"
              method="post"><input type="hidden" name="_method" value="patch" autocomplete="off"/><input
                type="hidden" name="authenticity_token"
                value="A6gSFspcsoiu5n6GM3kdB7SHYneyBk8Fq9lbJi7svpCtZOKBvnLNN2SJuN9I5TxS2duIr_BloUDIEvilz9OLMw"
                autocomplete="off"/>
              <div class="c-mb-2xl">
                <div class="notificationGroupConfig">
                  <div class="notificationGroupConfig__Description">
                    <fieldset class="cobalt-Fieldset">
                      <legend>As an owner</legend>
                    </fieldset>
                  </div>
                  <div class="notificationGroupConfig__Kind c-text-body-md c-text-subdued">App</div>
                  <div class="notificationGroupConfig__Kind c-text-body-md c-text-subdued">Email</div>
                  <div class="notificationGroupConfig__Kind c-text-body-md c-text-subdued">SMS</div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Booking and modification requests</strong>
                    <p class="c-text-body-md c-text-subdued">Answer promptly to booking requests and
                      modification requests requiring your confirmation.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_bookings_and_modifications_push"><label
                        for="checkbox-9ade680cd0dc6745e79c2a5646b4fe03"><input
                          name="user[owner_bookings_and_modifications_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-9ade680cd0dc6745e79c2a5646b4fe03"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_bookings_and_modifications_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_bookings_and_modifications_email"><label
                        for="checkbox-f06ef93afafde6291bfb0773b5d74fce"><input
                          name="user[owner_bookings_and_modifications_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-f06ef93afafde6291bfb0773b5d74fce"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_bookings_and_modifications_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_bookings_and_modifications_sms"><label
                        for="checkbox-de313f99339a697a22762ae8f4f94b5a"><input
                          name="user[owner_bookings_and_modifications_sms]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-de313f99339a697a22762ae8f4f94b5a"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_bookings_and_modifications_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Overlo Connect confirmations</strong>
                    <p class="c-text-body-md c-text-subdued">Receive confirmation for your bookings,
                      cancelations and automatic modifications made by the driver.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_open_confirmations_push"><label
                        for="checkbox-c8c655ed4e05cf8c3d3e2cdca4b4638c"><input
                          name="user[owner_open_confirmations_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-c8c655ed4e05cf8c3d3e2cdca4b4638c"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_open_confirmations_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_open_confirmations_email"><label
                        for="checkbox-2635a464b88484e2f700b8f762d859b0"><input
                          name="user[owner_open_confirmations_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-2635a464b88484e2f700b8f762d859b0"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_open_confirmations_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_open_confirmations_sms"><label
                        for="checkbox-8c7eb5830e33db4977b518e8940e36c3"><input
                          name="user[owner_open_confirmations_sms]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-8c7eb5830e33db4977b518e8940e36c3"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_open_confirmations_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Confirmations for rentals with key handover</strong>
                    <p class="c-text-body-md c-text-subdued">Organize your meetings and the key handover by
                      staying informed of drivers booking confirmations.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_classic_confirmations_push"><label
                        for="checkbox-b5b448b251f9c1aaa6736bd27587fdb9"><input
                          name="user[owner_classic_confirmations_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-b5b448b251f9c1aaa6736bd27587fdb9"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_classic_confirmations_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_classic_confirmations_email"><label
                        for="checkbox-32b638334879e4ad88f91845820e9773"><input
                          name="user[owner_classic_confirmations_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-32b638334879e4ad88f91845820e9773"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_classic_confirmations_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_classic_confirmations_sms"><label
                        for="checkbox-259531e9a852a551995098838b900e15"><input
                          name="user[owner_classic_confirmations_sms]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-259531e9a852a551995098838b900e15"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_classic_confirmations_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Messages</strong>
                    <p class="c-text-body-md c-text-subdued">Read messages and reviews sent by the driver.
                    </p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="owner_messages_push">
                      <label for="checkbox-fd741c43441d9944d36fe2882bb82f0f"><input
                          name="user[owner_messages_push]" type="hidden" value="0" autocomplete="off"/><input
                          id="checkbox-fd741c43441d9944d36fe2882bb82f0f"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_messages_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="owner_messages_email">
                      <label for="checkbox-f7038d4c1b82917f35c022ed6cda2500"><input
                          name="user[owner_messages_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-f7038d4c1b82917f35c022ed6cda2500"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_messages_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="owner_messages_sms">
                      <label for="checkbox-10ea2e78a681c45b24fae7fe70c9d831"><input
                          name="user[owner_messages_sms]" disabled="disabled" type="hidden" value="0"
                          autocomplete="off"/><input disabled="disabled"
                          id="checkbox-10ea2e78a681c45b24fae7fe70c9d831"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_messages_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">End of rental</strong>
                    <p class="c-text-body-md c-text-subdued">Receive confirmation of the end of rental, you
                      will then be able to see the summary of your rental and review the driver.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_end_of_rental_push"><label
                        for="checkbox-b2713f145ac0294fc2d5dd1a8c7a44e1"><input
                          name="user[owner_end_of_rental_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-b2713f145ac0294fc2d5dd1a8c7a44e1"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_end_of_rental_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_end_of_rental_email"><label
                        for="checkbox-8af3132246c12149d46f1cc895702f28"><input
                          name="user[owner_end_of_rental_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-8af3132246c12149d46f1cc895702f28"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_end_of_rental_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="owner_end_of_rental_sms">
                      <label for="checkbox-78bcb1cf69e5a9ecae97cd966648733e"><input
                          name="user[owner_end_of_rental_sms]" disabled="disabled" type="hidden" value="0"
                          autocomplete="off"/><input disabled="disabled"
                          id="checkbox-78bcb1cf69e5a9ecae97cd966648733e"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_end_of_rental_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Adjustments</strong>
                    <p class="c-text-body-md c-text-subdued">Additional mileage and fuel adjustments.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_checkout_adjustments_push"><label
                        for="checkbox-96dd1395ff94bd80b1e751b6d70fff2a"><input
                          name="user[owner_checkout_adjustments_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-96dd1395ff94bd80b1e751b6d70fff2a"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_checkout_adjustments_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_checkout_adjustments_email"><label
                        for="checkbox-85238b2fb296d19a549e2b7d037f38b9"><input
                          name="user[owner_checkout_adjustments_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-85238b2fb296d19a549e2b7d037f38b9"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_checkout_adjustments_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_checkout_adjustments_sms"><label
                        for="checkbox-aced22545089ff1b33c0470d2e4854f8"><input
                          name="user[owner_checkout_adjustments_sms]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-aced22545089ff1b33c0470d2e4854f8"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_checkout_adjustments_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Reviews reminder</strong>
                    <p class="c-text-body-md c-text-subdued">Don’t forget to review your drivers.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_reviews_reminder_push"><label
                        for="checkbox-8b3ae8852160686a46053fef6fe55b30"><input
                          name="user[owner_reviews_reminder_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-8b3ae8852160686a46053fef6fe55b30"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_reviews_reminder_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_reviews_reminder_email"><label
                        for="checkbox-a059fee428d8ea0d0cbc26d66b9864b2"><input
                          name="user[owner_reviews_reminder_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-a059fee428d8ea0d0cbc26d66b9864b2"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_reviews_reminder_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_reviews_reminder_sms"><label
                        for="checkbox-b4a0821474e2d6384b5d8d99ecbc0188"><input
                          name="user[owner_reviews_reminder_sms]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-b4a0821474e2d6384b5d8d99ecbc0188"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_reviews_reminder_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Rental agreements</strong>
                    <p class="c-text-body-md c-text-subdued">No need to get your checkin and checkout
                      agreements by email, we keep them always available in your rental page.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_rental_agreements_push"><label
                        for="checkbox-fc6a358608ae0bff54b9df8d5d3a9011"><input
                          name="user[owner_rental_agreements_push]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-fc6a358608ae0bff54b9df8d5d3a9011"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_rental_agreements_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_rental_agreements_email"><label
                        for="checkbox-4ed027c733885399cbf8e3b151f77ec3"><input
                          name="user[owner_rental_agreements_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-4ed027c733885399cbf8e3b151f77ec3"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_rental_agreements_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_rental_agreements_sms"><label
                        for="checkbox-9a210eb04d96b4a8a2d71692f4d10747"><input
                          name="user[owner_rental_agreements_sms]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-9a210eb04d96b4a8a2d71692f4d10747"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_rental_agreements_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Alerts</strong>
                    <p class="c-text-body-md c-text-subdued">Be alerted live of late returns, distant
                      checkouts, needs for a cleaning, ...</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="owner_alerts_push"><label
                        for="checkbox-a4779324224acab1195fbf36339b8030"><input
                          name="user[owner_alerts_push]" type="hidden" value="0" autocomplete="off"/><input
                          id="checkbox-a4779324224acab1195fbf36339b8030"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_alerts_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="owner_alerts_email">
                      <label for="checkbox-97417954915df6a4e44711562a45cf83"><input
                          name="user[owner_alerts_email]" type="hidden" value="0" autocomplete="off"/><input
                          id="checkbox-97417954915df6a4e44711562a45cf83"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_alerts_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="owner_alerts_sms"><label
                        for="checkbox-649088e1d6d705bd3e9a9013b7f8d5cf"><input name="user[owner_alerts_sms]"
                          disabled="disabled" type="hidden" value="0" autocomplete="off"/><input
                          disabled="disabled" id="checkbox-649088e1d6d705bd3e9a9013b7f8d5cf"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_alerts_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Price calendar</strong>
                    <p class="c-text-body-md c-text-subdued">Be alerted when your calendar is updated with
                      the latest demand levels.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_calendar_update_push"><label
                        for="checkbox-4cf2621ba430e42763dbe671472ffd5a"><input
                          name="user[owner_calendar_update_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-4cf2621ba430e42763dbe671472ffd5a"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_calendar_update_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_calendar_update_email"><label
                        for="checkbox-3653de4b63a108d6f1a2f50937e8feb5"><input
                          name="user[owner_calendar_update_email]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-3653de4b63a108d6f1a2f50937e8feb5"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_calendar_update_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_calendar_update_sms"><label
                        for="checkbox-78c6030c3d4a489360ddea7b5af23a63"><input
                          name="user[owner_calendar_update_sms]" disabled="disabled" type="hidden" value="0"
                          autocomplete="off"/><input disabled="disabled"
                          id="checkbox-78c6030c3d4a489360ddea7b5af23a63"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_calendar_update_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Discount recommendation</strong>
                    <p class="c-text-body-md c-text-subdued">Be alerted when discount recommendations are
                      updated.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_recommendation_degressivity_update_push"><label
                        for="checkbox-66a40dc331b58e015caaf218b7c1f352"><input
                          name="user[owner_recommendation_degressivity_update_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-66a40dc331b58e015caaf218b7c1f352"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[owner_recommendation_degressivity_update_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_recommendation_degressivity_update_email"><label
                        for="checkbox-664199a49f9f0ca71ac2136e8ebf0296"><input
                          name="user[owner_recommendation_degressivity_update_email]" disabled="disabled"
                          type="hidden" value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-664199a49f9f0ca71ac2136e8ebf0296"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_recommendation_degressivity_update_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="owner_recommendation_degressivity_update_sms"><label
                        for="checkbox-1010c3a3071c3ad5ab90f5cc8495ecbf"><input
                          name="user[owner_recommendation_degressivity_update_sms]" disabled="disabled"
                          type="hidden" value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-1010c3a3071c3ad5ab90f5cc8495ecbf"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[owner_recommendation_degressivity_update_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
              </div>
              <div class="c-mb-2xl">
                <div class="notificationGroupConfig">
                  <div class="notificationGroupConfig__Description">
                    <fieldset class="cobalt-Fieldset">
                      <legend>As a driver</legend>
                    </fieldset>
                  </div>
                  <div class="notificationGroupConfig__Kind c-text-body-md c-text-subdued">App</div>
                  <div class="notificationGroupConfig__Kind c-text-body-md c-text-subdued">Email</div>
                  <div class="notificationGroupConfig__Kind c-text-body-md c-text-subdued">SMS</div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Responses to your requests</strong>
                    <p class="c-text-body-md c-text-subdued">Get responses to your booking requests or
                      modification requests, as well as a notification in case of a cancelation from the
                      owner.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="driver_requests_push">
                      <label for="checkbox-6a20998e84f0221980c17025613ffc26"><input
                          name="user[driver_requests_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-6a20998e84f0221980c17025613ffc26"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_requests_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="driver_requests_email">
                      <label for="checkbox-0069bc27c47c7efa7d1014b851c7de9b"><input
                          name="user[driver_requests_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-0069bc27c47c7efa7d1014b851c7de9b"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_requests_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="driver_requests_sms">
                      <label for="checkbox-d6debb3a69ab6798048135b69cb1689a"><input
                          name="user[driver_requests_sms]" type="hidden" value="0" autocomplete="off"/><input
                          id="checkbox-d6debb3a69ab6798048135b69cb1689a"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_requests_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Confirmations</strong>
                    <p class="c-text-body-md c-text-subdued">Receive a confirmation for your bookings and
                      your rental modifications.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_confirmations_push"><label
                        for="checkbox-773366ad4d19aac62d3fc2a1ce233011"><input
                          name="user[driver_confirmations_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-773366ad4d19aac62d3fc2a1ce233011"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_confirmations_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_confirmations_email"><label
                        for="checkbox-fe15574bb1c691dd5de6798dae18902f"><input
                          name="user[driver_confirmations_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-fe15574bb1c691dd5de6798dae18902f"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_confirmations_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_confirmations_sms"><label
                        for="checkbox-f8d12f4c82bd679511c429c622a7686f"><input
                          name="user[driver_confirmations_sms]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-f8d12f4c82bd679511c429c622a7686f"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_confirmations_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Messages</strong>
                    <p class="c-text-body-md c-text-subdued">Read messages and reviews sent by the owner</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="driver_messages_push">
                      <label for="checkbox-90d902f7d61ab5a475353029becd65bb"><input
                          name="user[driver_messages_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-90d902f7d61ab5a475353029becd65bb"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_messages_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="driver_messages_email">
                      <label for="checkbox-594e3f0233475cb27eab1c68d24a2ef0"><input
                          name="user[driver_messages_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-594e3f0233475cb27eab1c68d24a2ef0"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_messages_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField" data-form-checkmark-method="driver_messages_sms">
                      <label for="checkbox-20a2c51342d73545c6231e0eacfc9ce7"><input
                          name="user[driver_messages_sms]" disabled="disabled" type="hidden" value="0"
                          autocomplete="off"/><input disabled="disabled"
                          id="checkbox-20a2c51342d73545c6231e0eacfc9ce7"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[driver_messages_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">End of rental</strong>
                    <p class="c-text-body-md c-text-subdued">Once you have ended your rental, you can review
                      your experience, and see the summary of your rental.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_end_of_rental_push"><label
                        for="checkbox-18d72e010dabd7cfaa8563d4b5fad69b"><input
                          name="user[driver_end_of_rental_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-18d72e010dabd7cfaa8563d4b5fad69b"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_end_of_rental_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_end_of_rental_email"><label
                        for="checkbox-ad3b054ab67328e70f280c396c37372d"><input
                          name="user[driver_end_of_rental_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-ad3b054ab67328e70f280c396c37372d"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_end_of_rental_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_end_of_rental_sms"><label
                        for="checkbox-b32d5b5e0adb177c2842b7cdc8f1ce29"><input
                          name="user[driver_end_of_rental_sms]" disabled="disabled" type="hidden" value="0"
                          autocomplete="off"/><input disabled="disabled"
                          id="checkbox-b32d5b5e0adb177c2842b7cdc8f1ce29"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[driver_end_of_rental_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Adjustments</strong>
                    <p class="c-text-body-md c-text-subdued">Additional mileage and fuel adjustments.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_checkout_adjustments_push"><label
                        for="checkbox-f4a76ef80dfde9def69d3afcd2aa1565"><input
                          name="user[driver_checkout_adjustments_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-f4a76ef80dfde9def69d3afcd2aa1565"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_checkout_adjustments_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_checkout_adjustments_email"><label
                        for="checkbox-18cb6ab4510d6ac40fe09229a16475b0"><input
                          name="user[driver_checkout_adjustments_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-18cb6ab4510d6ac40fe09229a16475b0"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_checkout_adjustments_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_checkout_adjustments_sms"><label
                        for="checkbox-1774da03d6596b02e70252768d9bdfa2"><input
                          name="user[driver_checkout_adjustments_sms]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-1774da03d6596b02e70252768d9bdfa2"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[driver_checkout_adjustments_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Reviews reminders</strong>
                    <p class="c-text-body-md c-text-subdued">Don’t forget to review your rentals.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_reviews_reminder_push"><label
                        for="checkbox-76da6dd647c28494767695199fbe165f"><input
                          name="user[driver_reviews_reminder_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-76da6dd647c28494767695199fbe165f"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_reviews_reminder_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_reviews_reminder_email"><label
                        for="checkbox-92b9bb6be534d1093d6e99336acf8279"><input
                          name="user[driver_reviews_reminder_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-92b9bb6be534d1093d6e99336acf8279"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_reviews_reminder_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_reviews_reminder_sms"><label
                        for="checkbox-f6c69e2786c56406bd2a6aa5417319f1"><input
                          name="user[driver_reviews_reminder_sms]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-f6c69e2786c56406bd2a6aa5417319f1"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[driver_reviews_reminder_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Rental agreements</strong>
                    <p class="c-text-body-md c-text-subdued">No need to get your checkin and checkout
                      agreements by email, we keep them always available in your rental page.</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_rental_agreements_push"><label
                        for="checkbox-33e502103d765c2663d00c3a9e5c362b"><input
                          name="user[driver_rental_agreements_push]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-33e502103d765c2663d00c3a9e5c362b"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[driver_rental_agreements_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_rental_agreements_email"><label
                        for="checkbox-3aa1545651f02bcd28f69d8b4f2e8a39"><input
                          name="user[driver_rental_agreements_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-3aa1545651f02bcd28f69d8b4f2e8a39"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_rental_agreements_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_rental_agreements_sms"><label
                        for="checkbox-6e1bc2b93776ea3a4e8837a0c3a9ed77"><input
                          name="user[driver_rental_agreements_sms]" disabled="disabled" type="hidden"
                          value="0" autocomplete="off"/><input disabled="disabled"
                          id="checkbox-6e1bc2b93776ea3a4e8837a0c3a9ed77"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1"
                          name="user[driver_rental_agreements_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-md">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Cancelations</strong>
                    <p class="c-text-body-md c-text-subdued">Book another car right away in case there's a
                      cancelation by the owner</p>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_cancellations_push"><label
                        for="checkbox-6e195c9470e7d721c34e18ad45d4ec32"><input
                          name="user[driver_cancellations_push]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-6e195c9470e7d721c34e18ad45d4ec32"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_cancellations_push]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_cancellations_email"><label
                        for="checkbox-3d62df56e1c191990e88e6d5065d86fd"><input
                          name="user[driver_cancellations_email]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-3d62df56e1c191990e88e6d5065d86fd"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_cancellations_email]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                  <div class="notificationGroupConfig__Kind">
                    <div class="cobalt-CheckmarkField"
                      data-form-checkmark-method="driver_cancellations_sms"><label
                        for="checkbox-ebbffa6b6bc535bcc9276a99e871374f"><input
                          name="user[driver_cancellations_sms]" type="hidden" value="0"
                          autocomplete="off"/><input id="checkbox-ebbffa6b6bc535bcc9276a99e871374f"
                          class="cobalt-CheckmarkField__Input" type="checkbox" value="1" checked="checked"
                          name="user[driver_cancellations_sms]"/><span
                          class="cobalt-CheckmarkField__Checkmark c-mr-none"></span></label></div>
                  </div>
                </div>
              </div>
              <fieldset class="cobalt-Fieldset">
                <legend>Informational emails</legend>
                <div class="notificationGroupConfig c-mb-sm">
                  <div class="notificationGroupConfig__Description">
                    <p class="c-text-body-md">
                      <strong>Emails will be sent to this address: </strong>
                      elhadj.ba@outlook.com
                    </p>
                  </div>
                  <div class="notificationGroupConfig__Action">
                    <p><a class="cob-Button cob-Button__ghost cob-Button--fullWidth"
                        href="/dashboard/account_settings">Edit</a></p>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-sm">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Overlo Newsletter</strong>
                    <p class="c-text-body-md c-text-subdued">Stay informed monthly on Overlo news and
                      marketing offers</p>
                  </div>
                  <div class="notificationGroupConfig__Action">
                    <div
                      class="cobalt-ToggleSwitchField cobalt-ToggleSwitchField--large cobalt-ToggleSwitchField--standalone"
                      data-form-checkmark-method="notif_newsletter"><label
                        class="cobalt-ToggleSwitchField__LabelWrapper"><input name="user[notif_newsletter]"
                          type="hidden" value="0" autocomplete="off"/><input
                          class="cobalt-ToggleSwitchField__Input" type="checkbox" value="1"
                          name="user[notif_newsletter]" id="user_notif_newsletter"/><span
                          class="cobalt-ToggleSwitchField__ToggleSwitch"><span
                            class="cobalt-ToggleSwitchField__ToggleSwitchSlider"></span></span></label>
                    </div>
                  </div>
                </div>
                <div class="notificationGroupConfig c-mb-sm">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Account activity</strong>
                    <p class="c-text-body-md c-text-subdued">Payment notice, security, ToS updates… These
                      notifications are required to service your account, you cannot disable them.</p>
                  </div>
                  <div class="notificationGroupConfig__Action">
                    <div
                      class="cobalt-ToggleSwitchField cobalt-ToggleSwitchField--large cobalt-ToggleSwitchField--standalone"
                      data-form-checkmark-method="notif_important_emails"><label
                        class="cobalt-ToggleSwitchField__LabelWrapper"><input
                          name="user[notif_important_emails]" disabled="disabled" type="hidden" value="0"
                          autocomplete="off"/><input disabled="disabled"
                          class="cobalt-ToggleSwitchField__Input" type="checkbox" value="1"
                          checked="checked" name="user[notif_important_emails]"
                          id="user_notif_important_emails"/><span
                          class="cobalt-ToggleSwitchField__ToggleSwitch"><span
                            class="cobalt-ToggleSwitchField__ToggleSwitchSlider"></span></span></label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset class="cobalt-Fieldset">
                <legend>Cell notifications</legend>
              </fieldset>
              <div class="cobalt-FormField" data-form-field-method="phone_country_phone_number_national">
                <label class="cobalt-FormField__Label">SMS notifications will be sent to:</label>
                <div class="cobalt-ComposedFormField">
                  <div class="cobalt-SelectField"><select class="cobalt-SelectField__Input"
                      name="user[phone_country]" id="user_phone_country">
                      <option value="AF">Afghanistan (+93)</option>
                      <option value="AL">Albania (+355)</option>
                      <option value="DZ">Algeria (+213)</option>
                      <option value="AS">American Samoa (+1)</option>
                      <option value="AD">Andorra (+376)</option>
                      <option value="AO">Angola (+244)</option>
                      <option value="AI">Anguilla (+1)</option>
                      <option value="AG">Antigua &amp; Barbuda (+1)</option>
                      <option value="AR">Argentina (+54)</option>
                      <option value="AM">Armenia (+374)</option>
                      <option value="AW">Aruba (+297)</option>
                      <option value="AU">Australia (+61)</option>
                      <option value="AT">Austria (+43)</option>
                      <option value="AZ">Azerbaijan (+994)</option>
                      <option value="BS">Bahamas (+1)</option>
                      <option value="BH">Bahrain (+973)</option>
                      <option value="BD">Bangladesh (+880)</option>
                      <option value="BB">Barbados (+1)</option>
                      <option value="BY">Belarus (+375)</option>
                      <option value="BE">Belgium (+32)</option>
                      <option value="BZ">Belize (+501)</option>
                      <option value="BJ">Benin (+229)</option>
                      <option value="BM">Bermuda (+1)</option>
                      <option value="BT">Bhutan (+975)</option>
                      <option value="BO">Bolivia (+591)</option>
                      <option value="BA">Bosnia &amp; Herzegovina (+387)</option>
                      <option value="BW">Botswana (+267)</option>
                      <option value="BR">Brazil (+55)</option>
                      <option value="IO">British Indian Ocean Territory (+246)</option>
                      <option value="VG">British Virgin Islands (+1)</option>
                      <option value="BN">Brunei (+673)</option>
                      <option value="BG">Bulgaria (+359)</option>
                      <option value="BF">Burkina Faso (+226)</option>
                      <option value="BI">Burundi (+257)</option>
                      <option value="KH">Cambodia (+855)</option>
                      <option value="CM">Cameroon (+237)</option>
                      <option value="CA">Canada (+1)</option>
                      <option value="CV">Cape Verde (+238)</option>
                      <option value="KY">Cayman Islands (+1)</option>
                      <option value="CF">Central African Republic (+236)</option>
                      <option value="TD">Chad (+235)</option>
                      <option value="CL">Chile (+56)</option>
                      <option value="CN">China (+86)</option>
                      <option value="CX">Christmas Island (+61)</option>
                      <option value="CC">Cocos (Keeling) Islands (+61)</option>
                      <option value="CO">Colombia (+57)</option>
                      <option value="KM">Comoros (+269)</option>
                      <option value="CG">Congo - Brazzaville (+242)</option>
                      <option value="CD">Congo - Kinshasa (+243)</option>
                      <option value="CK">Cook Islands (+682)</option>
                      <option value="CR">Costa Rica (+506)</option>
                      <option value="CI">Côte d’Ivoire (+225)</option>
                      <option value="HR">Croatia (+385)</option>
                      <option value="CU">Cuba (+53)</option>
                      <option value="CY">Cyprus (+357)</option>
                      <option value="CZ">Czech Republic (+420)</option>
                      <option value="DK">Denmark (+45)</option>
                      <option value="DJ">Djibouti (+253)</option>
                      <option value="DM">Dominica (+1)</option>
                      <option value="DO">Dominican Republic (+1)</option>
                      <option value="EC">Ecuador (+593)</option>
                      <option value="EG">Egypt (+20)</option>
                      <option value="SV">El Salvador (+503)</option>
                      <option value="GQ">Equatorial Guinea (+240)</option>
                      <option value="EE">Estonia (+372)</option>
                      <option value="ET">Ethiopia (+251)</option>
                      <option value="FK">Falkland Islands (+500)</option>
                      <option value="FO">Faroe Islands (+298)</option>
                      <option value="FJ">Fiji (+679)</option>
                      <option value="FI">Finland (+358)</option>
                      <option value="FR">France (+33)</option>
                      <option value="GF">French Guiana (+594)</option>
                      <option value="PF">French Polynesia (+689)</option>
                      <option value="GA">Gabon (+241)</option>
                      <option value="GM">Gambia (+220)</option>
                      <option value="GE">Georgia (+995)</option>
                      <option value="DE">Germany (+49)</option>
                      <option value="GH">Ghana (+233)</option>
                      <option value="GI">Gibraltar (+350)</option>
                      <option value="GR">Greece (+30)</option>
                      <option value="GL">Greenland (+299)</option>
                      <option value="GD">Grenada (+1)</option>
                      <option value="GP">Guadeloupe (+590)</option>
                      <option value="GU">Guam (+1)</option>
                      <option value="GT">Guatemala (+502)</option>
                      <option value="GN">Guinea (+224)</option>
                      <option value="GW">Guinea-Bissau (+245)</option>
                      <option value="GY">Guyana (+592)</option>
                      <option value="HT">Haiti (+509)</option>
                      <option value="HN">Honduras (+504)</option>
                      <option value="HK">Hong Kong SAR China (+852)</option>
                      <option value="HU">Hungary (+36)</option>
                      <option value="IS">Iceland (+354)</option>
                      <option value="IN">India (+91)</option>
                      <option value="ID">Indonesia (+62)</option>
                      <option value="IR">Iran (+98)</option>
                      <option value="IQ">Iraq (+964)</option>
                      <option value="IE">Ireland (+353)</option>
                      <option value="IL">Israel (+972)</option>
                      <option value="IT">Italy (+39)</option>
                      <option value="JM">Jamaica (+1)</option>
                      <option value="JP">Japan (+81)</option>
                      <option value="JO">Jordan (+962)</option>
                      <option value="KZ">Kazakhstan (+7)</option>
                      <option value="KE">Kenya (+254)</option>
                      <option value="KI">Kiribati (+686)</option>
                      <option value="XK">Kosovo (+383)</option>
                      <option value="KW">Kuwait (+965)</option>
                      <option value="KG">Kyrgyzstan (+996)</option>
                      <option value="LA">Laos (+856)</option>
                      <option value="LV">Latvia (+371)</option>
                      <option value="LB">Lebanon (+961)</option>
                      <option value="LS">Lesotho (+266)</option>
                      <option value="LR">Liberia (+231)</option>
                      <option value="LY">Libya (+218)</option>
                      <option value="LI">Liechtenstein (+423)</option>
                      <option value="LT">Lithuania (+370)</option>
                      <option value="LU">Luxembourg (+352)</option>
                      <option value="MO">Macau SAR China (+853)</option>
                      <option value="MK">Macedonia (+389)</option>
                      <option value="MG">Madagascar (+261)</option>
                      <option value="MW">Malawi (+265)</option>
                      <option value="MY">Malaysia (+60)</option>
                      <option value="MV">Maldives (+960)</option>
                      <option value="ML">Mali (+223)</option>
                      <option value="MT">Malta (+356)</option>
                      <option value="MH">Marshall Islands (+692)</option>
                      <option value="MQ">Martinique (+596)</option>
                      <option value="MR">Mauritania (+222)</option>
                      <option value="MU">Mauritius (+230)</option>
                      <option value="YT">Mayotte (+262)</option>
                      <option value="MX">Mexico (+52)</option>
                      <option value="FM">Micronesia (+691)</option>
                      <option value="MD">Moldova (+373)</option>
                      <option value="MC">Monaco (+377)</option>
                      <option value="MN">Mongolia (+976)</option>
                      <option value="ME">Montenegro (+382)</option>
                      <option value="MS">Montserrat (+1)</option>
                      <option value="MA">Morocco (+212)</option>
                      <option value="MZ">Mozambique (+258)</option>
                      <option value="MM">Myanmar (Burma) (+95)</option>
                      <option value="NA">Namibia (+264)</option>
                      <option value="NR">Nauru (+674)</option>
                      <option value="NP">Nepal (+977)</option>
                      <option value="NL">Netherlands (+31)</option>
                      <option value="NC">New Caledonia (+687)</option>
                      <option value="NZ">New Zealand (+64)</option>
                      <option value="NI">Nicaragua (+505)</option>
                      <option value="NE">Niger (+227)</option>
                      <option value="NG">Nigeria (+234)</option>
                      <option value="NU">Niue (+683)</option>
                      <option value="NF">Norfolk Island (+672)</option>
                      <option value="KP">North Korea (+850)</option>
                      <option value="MP">Northern Mariana Islands (+1)</option>
                      <option value="NO">Norway (+47)</option>
                      <option value="OM">Oman (+968)</option>
                      <option selected="selected" value="PK">Pakistan (+92)</option>
                      <option value="PW">Palau (+680)</option>
                      <option value="PS">Palestinian Territories (+970)</option>
                      <option value="PA">Panama (+507)</option>
                      <option value="PG">Papua New Guinea (+675)</option>
                      <option value="PY">Paraguay (+595)</option>
                      <option value="PE">Peru (+51)</option>
                      <option value="PH">Philippines (+63)</option>
                      <option value="PL">Poland (+48)</option>
                      <option value="PT">Portugal (+351)</option>
                      <option value="PR">Puerto Rico (+1)</option>
                      <option value="QA">Qatar (+974)</option>
                      <option value="RE">Réunion (+262)</option>
                      <option value="RO">Romania (+40)</option>
                      <option value="RU">Russia (+7)</option>
                      <option value="RW">Rwanda (+250)</option>
                      <option value="WS">Samoa (+685)</option>
                      <option value="SM">San Marino (+378)</option>
                      <option value="ST">São Tomé &amp; Príncipe (+239)</option>
                      <option value="SA">Saudi Arabia (+966)</option>
                      <option value="SN">Senegal (+221)</option>
                      <option value="RS">Serbia (+381)</option>
                      <option value="SC">Seychelles (+248)</option>
                      <option value="SL">Sierra Leone (+232)</option>
                      <option value="SG">Singapore (+65)</option>
                      <option value="SK">Slovakia (+421)</option>
                      <option value="SI">Slovenia (+386)</option>
                      <option value="SB">Solomon Islands (+677)</option>
                      <option value="SO">Somalia (+252)</option>
                      <option value="ZA">South Africa (+27)</option>
                      <option value="KR">South Korea (+82)</option>
                      <option value="ES">Spain (+34)</option>
                      <option value="LK">Sri Lanka (+94)</option>
                      <option value="SH">St. Helena (+290)</option>
                      <option value="KN">St. Kitts &amp; Nevis (+1)</option>
                      <option value="LC">St. Lucia (+1)</option>
                      <option value="PM">St. Pierre &amp; Miquelon (+508)</option>
                      <option value="VC">St. Vincent &amp; Grenadines (+1)</option>
                      <option value="SD">Sudan (+249)</option>
                      <option value="SR">Suriname (+597)</option>
                      <option value="SJ">Svalbard &amp; Jan Mayen (+47)</option>
                      <option value="SZ">Swaziland (+268)</option>
                      <option value="SE">Sweden (+46)</option>
                      <option value="CH">Switzerland (+41)</option>
                      <option value="SY">Syria (+963)</option>
                      <option value="TW">Taiwan (+886)</option>
                      <option value="TJ">Tajikistan (+992)</option>
                      <option value="TZ">Tanzania (+255)</option>
                      <option value="TH">Thailand (+66)</option>
                      <option value="TG">Togo (+228)</option>
                      <option value="TK">Tokelau (+690)</option>
                      <option value="TO">Tonga (+676)</option>
                      <option value="TT">Trinidad &amp; Tobago (+1)</option>
                      <option value="TN">Tunisia (+216)</option>
                      <option value="TR">Turkey (+90)</option>
                      <option value="TM">Turkmenistan (+993)</option>
                      <option value="TC">Turks &amp; Caicos Islands (+1)</option>
                      <option value="TV">Tuvalu (+688)</option>
                      <option value="VI">U.S. Virgin Islands (+1)</option>
                      <option value="UG">Uganda (+256)</option>
                      <option value="UA">Ukraine (+380)</option>
                      <option value="AE">United Arab Emirates (+971)</option>
                      <option value="GB">United Kingdom (+44)</option>
                      <option value="US">United States (+1)</option>
                      <option value="UY">Uruguay (+598)</option>
                      <option value="UZ">Uzbekistan (+998)</option>
                      <option value="VU">Vanuatu (+678)</option>
                      <option value="VA">Vatican City (+39)</option>
                      <option value="VE">Venezuela (+58)</option>
                      <option value="VN">Vietnam (+84)</option>
                      <option value="WF">Wallis &amp; Futuna (+681)</option>
                      <option value="EH">Western Sahara (+212)</option>
                      <option value="YE">Yemen (+967)</option>
                      <option value="ZM">Zambia (+260)</option>
                      <option value="ZW">Zimbabwe (+263)</option>
                    </select></div>
                  <div class="cobalt-TextField"><input type="tel" class="cobalt-TextField__Input"
                      value="3134005794" name="user[phone_number_national]" id="user_phone_number_national"/>
                  </div>
                </div>
              </div>
              <fieldset class="cobalt-Fieldset c-border-0" style={{marginTop:"0 !important"}}>
                <div class="notificationGroupConfig c-mb-sm">
                  <div class="notificationGroupConfig__Description">
                    <strong class="c-text-body-md">Overlo offers</strong>
                    <p class="c-text-body-md c-text-subdued">Stay informed on Overlo news and marketing
                      offers.</p>
                  </div>
                  <div class="notificationGroupConfig__Action">
                    <div
                      class="cobalt-ToggleSwitchField cobalt-ToggleSwitchField--large cobalt-ToggleSwitchField--standalone"
                      data-form-checkmark-method="notif_sms"><label
                        class="cobalt-ToggleSwitchField__LabelWrapper"><input name="user[notif_sms]"
                          type="hidden" value="0" autocomplete="off"/><input
                          class="cobalt-ToggleSwitchField__Input" type="checkbox" value="1"
                          name="user[notif_sms]" id="user_notif_sms"/><span
                          class="cobalt-ToggleSwitchField__ToggleSwitch"><span
                            class="cobalt-ToggleSwitchField__ToggleSwitchSlider"></span></span></label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <p class="strong c-text-body-md">Push notification settings</p>
              <p>To get push notifications in the event of a request or booking, download <a
                  href="/mobile">the Overlo app</a>.<br></br>To activate or deactivate these notifications, go
                to the "settings" section on your cell phone.</p>
              <fieldset class="cobalt-Fieldset">
                <input type="submit" name="commit" value="Update"
                  class="cob-Button cob-Button__default cob-Button__default--primary"/>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

);
};

export default Notification;
