import React from "react";

const DurationCondition = ({carDetail}) => {
  return (
    <div class="cobalt-layout-section">
    <div class="car_owner_guidelines_wrapper">
      <div class="car_owner_restrictions">
        <div class="car_owner_restrictions__restriction">
          <div class="car_restrictions_grid">
            <div>
              <div style={{color:"#7b728e"}} class="c-text-body-md c-text-subdued">
                Minimum rental duration
              </div>
              <p class="c-text-body-md">{carDetail?.
min_rental_duration
}</p>
            </div>
            <div>
              <div style={{color:"#7b728e"}} class="c-text-body-md c-text-subdued">
                Maximum rental duration
              </div>
              <p class="c-text-body-md">{carDetail?.
                max_rental_duration}</p>
            </div>
          </div>
          <div class="car_restrictions_grid">
            <div>
              <div style={{color:"#7b728e"}} class="c-text-body-md c-text-subdued">
                Minimum advance notice
              </div>
              <p class="c-text-body-md">{carDetail?.min_advance_notice
              }</p>
            </div>
            <div>
              <div style={{color:"#7b728e"}} class="c-text-body-md c-text-subdued">
                Availability range
              </div>
              <p class="c-text-body-md">{carDetail?.
availability_range

              }</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  


);
};

export default DurationCondition;
