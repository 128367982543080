import React, { useContext } from 'react'
import "./NumberOfSeats.css"
import { DataContext } from '../ContextApi';

export default function NumberOfSeats() {
    const { filterValue, setFilterValue } = useContext(DataContext);
    const handleIncrement = () => {
        setFilterValue(prevValue => ({
            ...prevValue,
            numberSeats: prevValue.numberSeats + 1
        }));
    };

    const handleDecrement = () => {
        if (filterValue.numberSeats > 0) {
            setFilterValue(prevValue => ({
                ...prevValue,
                numberSeats: prevValue.numberSeats - 1
            }));
        }
    };
    return (
        <div className='Numberofseats_main_div'>
            <div className='NumberOfSeats_text'>NUMBER OF SEATS</div>
            <div className='MinimumSeats_div'>
                <div>Minimum seats</div>
                <div className='Counting_div'>
                    <p className='Counting_btn' onClick={handleDecrement}>-</p>
                    <div className='Count_number'>{filterValue.numberSeats}</div>
                    <p className='Counting_btn' onClick={handleIncrement}>+</p>               
                </div>
            </div>
        </div>
    )
}
