import React, { useRef, useState, useEffect } from "react";
import location from "../../../image/svg/location.svg";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";

const ImageSection = ({ carDetail, scrollToReviews }) => {
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const [updatedCarDetail, setUpdatedCarDetail] = useState({
    ...carDetail,
    photos: [
      carDetail?.image_1,
      carDetail?.image_2,
      carDetail?.image_3,
      carDetail?.image_4,
      carDetail?.image_5,
    ].filter(Boolean), // Exclude any undefined or null values
  });

  const scrollerRef = useRef(null);

  const handleScroll = (direction) => {
    const scroller = scrollerRef.current;
    if (scroller) {
      const scrollAmount = 500; // Adjust as needed
      const scrollLeft = scroller.scrollLeft;
      if (direction === "left") {
        scroller.scrollTo({
          left: scrollLeft - scrollAmount,
          behavior: "smooth",
        });
      } else if (direction === "right") {
        scroller.scrollTo({
          left: scrollLeft + scrollAmount,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const scroller = scrollerRef.current;

    const updateButtonVisibility = () => {
      if (scroller) {
        setShowLeftButton(scroller.scrollLeft > 0);
        setShowRightButton(
          scroller.scrollWidth > scroller.scrollLeft + scroller.clientWidth
        );
      }
    };

    const handleImageLoad = () => {
      updateButtonVisibility();
    };

    // Initial check
    updateButtonVisibility();

    // Add event listener to update button visibility on scroll
    scroller.addEventListener("scroll", updateButtonVisibility);

    // Add event listener to each image to update visibility when they load
    const images = Array.from(scroller.querySelectorAll("img"));
    images.forEach((img) => img.addEventListener("load", handleImageLoad));

    // Cleanup: remove event listeners
    return () => {
      scroller.removeEventListener("scroll", updateButtonVisibility);
      images.forEach((img) => img.removeEventListener("load", handleImageLoad));
    };
  }, [updatedCarDetail.photos.length]); // Depend on photos length for updates

  return (
    <div className="cobalt-Card cobalt-Card--responsive c-mb-md">
      <div className="car_carousel_large_screen">
        <>
          {showLeftButton && (
            <button
              name="button"
              type="button"
              className="car_carousel_large_screen__scroll_button js_carousel_large_screen__scroll_button cobalt-Icon cobalt-Icon--contained cobalt-Icon--size20 cobalt-Icon--colorBase car_carousel_large_screen__scroll_button--framed"
              data-direction="left"
              onClick={() => handleScroll("left")}
            >
              <ArrowLeftIcon />
            </button>
          )}
          {showRightButton && (
            <button
              name="button"
              type="button"
              className="car_carousel_large_screen__scroll_button js_carousel_large_screen__scroll_button cobalt-Icon cobalt-Icon--contained cobalt-Icon--size20 cobalt-Icon--colorBase car_carousel_large_screen__scroll_button--framed"
              data-direction="right"
              onClick={() => handleScroll("right")}
            >
              <ArrowRightIcon />
            </button>
          )}
        </>
        <div className="car_carousel_large_screen__scroller">
          <div
            style={{ transition: "transform 0.5s ease" }}
            ref={scrollerRef}
            className="car_carousel_large_screen__photos js_carousel_large_screen__photos"
          >
            {updatedCarDetail?.photos?.length > 0 ? (
              updatedCarDetail?.photos?.map((val) => (
                <img
                  alt="Car"
                  className="car_carousel_large_screen__photo js_carousel_large_screen__photo car_carousel_large_screen__photo--framed"
                  srcSet={val}
                  src={val}
                  key={val}
                />
              ))
            ) : (
              <img
                alt="No image available"
                className="car_carousel_large_screen__photo js_carousel_large_screen__photo car_carousel_large_screen__photo--framed"
                srcSet="https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                src="https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
              />
            )}
          </div>
        </div>
      </div>

      <div className="car_carousel_small_screen_placeholder js_carousel_small_screen_placeholder js_carousel_small_screen_placeholder__photo">
        {updatedCarDetail?.photos?.map((val) => (
          <img
            alt="Car"
            className="car_carousel_small_screen_placeholder__photo"
            srcSet={val}
            src={val}
            key={val}
          />
        ))}
        <div className="car_carousel_small_screen_placeholder__more_photos_count">
          <div className="cobalt-Icon cobalt-Icon--colorInversed c-mr-2xs">
            <img src={location} alt="location" />
          </div>
          {updatedCarDetail?.photos?.length}
        </div>
      </div>

      <div className="car_preview_car_info_container">
        <div className="car_info js_car_info c-mb-2xs">
          <div className="car_info__header">
            <h1 className="car_info_header__title js_car_name">
              {carDetail?.vehicle_make}
            </h1>
            <div className="car_info_header__description">
              <div className="c-text-body-md c-flex c-items-center c-gap-2xs">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="cobalt-Icon cobalt-Icon--size20 cobalt-Icon--colorBase c-m-none"
                >
                  <img src={location} alt="location" />
                </div>
                <span className="car_info_header__attributes">
                  {carDetail?.milage}
                  <span className="car_info_header__dot_separator"></span>
                  {carDetail?.vehicle_year}
                  <span className="car_info_header__dot_separator"></span>
                  {carDetail?.vehicle_number_of_seats} seats
                </span>
              </div>
              <button
                onClick={scrollToReviews}
                className="unstyled car_card__ratings car_card__ratings--clickable js_car_card__ratings"
                type="button"
              >
                <div className="cobalt-rating">
                  <div className="cobalt-rating-icons">
                    {Array.from({ length: 4 }).map((_, index) => (
                      <span
                        key={index}
                        className="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16"
                      >
                        <svg
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6 0L7.62 4.91H12L8.69 7.91L10.35 12L6 9.22L1.65 12L3.31 7.91L0 4.91H4.38L6 0Z" />
                        </svg>
                      </span>
                    ))}
                    <div className="cobalt-rating__label">
                      <span itemProp="ratingValue"> {carDetail?.rating} </span> (
                      <span itemProp="ratingCount">
                        {" "}
                        € {carDetail?.per_day_price} /day
                      </span>
                      )
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
