import React, { useState, useRef } from "react";
import checkmart from "../image/svg/checkmark.svg";
import happyface from "../image/svg/happyface.svg";
import dropDown from "../image/svg/dropdownblack.svg";
import dropDownClose from "../image/svg/closeDropDown.svg";
import user_photo from "../image/png/user_photo.jpeg";
import car from "../image/png/car.jpeg";
import photo_image from "../image/png/photo-img.jpeg";
import Header_image1 from "../image/svg/firstImage.svg";
import Header_image2 from "../image/svg/firstImage2.svg";
import Header_image3 from "../image/svg/firstImage3.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const testimonials = [
  {
    id: "ducas-sandero-2010",
    avatar: "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "Ducas SANDERO 2010",
    date: "le 8 septembre 2020",
    rating: "4",
    comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: "peugeot-308-cc-2010",
    avatar: "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "Peugeot 308 CC 2010",
    date: "le 7 juillet 2014",
    rating: "5",
    comment: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem.",
  },
  {
    id: "fiat-500-2016",
    avatar: "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "FIAT 500 2016",
    date: "le 7 juin 2014",
    rating: "4",
    comment: "Accusantium doloremque laudantium totam rem.",
  },
  {
    id: "fiat-500-20167",
    avatar: "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "FIAT 500 2016",
    date: "le 7 juin 2014",
    rating: "4",
    comment: "Accusantium doloremque laudantium totam rem.",
  },
  {
    id: "fiat-500-201673",
    avatar: "https://images.turo.com/media/driver/zw1eMtdsR0iOtx6sWFZiPg.120x120.jpg", // Replace with actual path to image
    name: "FIAT 500 2016",
    date: "le 7 juin 2014",
    rating: "5",
    comment: "Accusantium doloremque laudantium totam rem.",
  },
];
const DiscoverNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [faqItems, setFaqItems] = useState({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
    item9: false,
    item10: false,
  });
  function mapRatingToArray(rating) {
    const num = Number(rating);

    const resultArray = Array.from({ length: num }, (_, index) => index + 1);

    return resultArray;
  }
  const toggleItem = (item) => {
    setFaqItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };
  const testimonialsRef = useRef(null);

  const scrollPrevious = () => {
    if (testimonialsRef.current) {
      testimonialsRef.current.scrollBy({
        left: -300, 
        behavior: "smooth",
      });
    }
  };

  const scrollNext = () => {
    if (testimonialsRef.current) {
      testimonialsRef.current.scrollBy({
        left: 300, 
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{ position: "relative", zIndex: "3" }}
      class="below-hero c-pt-sm"
    >
      <div class="mkg-section-b-spacing c-mt-xl md:c-pt-2xl">
        <div class="bleed-container bleed-container--bleedSmall Overlo-usp2 js_how_it_works_section">
          <div class="bleed-wrapper">
            <div class="Overlo-usp2__image-container">
              <div
                class="Overlo-usp2__image js_image-swapper image-swapper"
                data-duration-in-ms="8000"
              >
                <div
                  class="image-swapper__image--showing"
                  style={{ backgroundImage: `url(${user_photo})` }}
                ></div>
              </div>
            </div>
            <div class="Overlo-usp2__card c-bg-primary c-px-sm c-py-lg mkg-rounded-md sm:c-px-xl">
              <div class="Overlo-usp2__content c-flex c-flex-col c-gap-md c-items-start sm:c-py-lg sm:c-py-xl">
                <div class="Overlo-usp2__title mkg-text-title-lg">
                  {t("discover_the_new")}
                  <br />
                  <em> {t("rent_car")}</em>
                </div>
                <p class="Overlo-usp2__description c-text-body-lg">
                  {t("choose_from_thousands")}
                </p>

                <div class="c-flex c-gap-sm">
                  <div class="c-w-md">
                    <img
                      alt="illustration"
                      width="24"
                      height="24"
                      class=""
                      src={happyface}
                    />
                  </div>
                  <div class="c-flex c-flex-col c-gap-xs">
                    <div class="c-text-title-sm">{t("price_by_hour")}</div>
                    <p>{t("trip_liability")}</p>
                  </div>
                </div>
                <div class="c-flex c-gap-sm">
                  <div class="c-w-md">
                    <img
                      alt="icon"
                      width="24"
                      height="24"
                      class=""
                      src={checkmart}
                    />
                  </div>
                  <div class="c-flex c-flex-col c-gap-xs">
                    <div class="c-text-title-sm"> {t("no_waiting")}</div>
                    <p>{t("book_a_car")}</p>
                  </div>
                </div>
                <div class="c-flex c-gap-sm">
                  <div class="c-w-md">
                    <div class="cobalt-bg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M23.017 6.448A11 11 0 0 0 13 0a1 1 0 1 0 0 2 9 9 0 0 1 8.196 5.276 1 1 0 1 0 1.82-.828Zm-3.643 1.656A7 7 0 0 0 13 4a1 1 0 1 0 0 2 5 5 0 0 1 4.554 2.932 1 1 0 0 0 1.82-.828m-2.635 3.919A1.5 1.5 0 1 1 17 15h-.005c.005.35.005.686.005 1v4.997A1 1 0 0 1 15.99 22h-1.98A1 1 0 0 1 13 20.997V20H6v.997A1 1 0 0 1 4.99 22H3.01A1 1 0 0 1 2 20.997V16c0-.314 0-.65.005-1H2a1.5 1.5 0 1 1 .261-2.977C2.674 9.878 3.742 8 6.5 8h6c2.758 0 3.826 1.878 4.239 4.023m-1.834 1.284C14.662 10.927 14.018 10 12.5 10h-6c-1.517 0-2.16.928-2.406 3.307a15 15 0 0 0-.056.693h10.924c-.014-.236-.032-.46-.056-.693ZM6.25 17a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M14 18.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="c-flex c-flex-col c-gap-xs">
                    <div class="c-text-title-sm">{t("unlock_a_car")}</div>
                    <p>{t("our_secure_overlo")}</p>
                  </div>
                </div>
                <a
                  class="cob-Button cob-Button__default cob-Button--large mkg-button mkg-button--outlined c-w-full sm:c-w-auto"
                  onClick={() => navigate("how-it-works")}
                >
                  {" "}
                  {t("see_how_it_works")}
                  <div class="c-w-md c-ml-xs">
                    <div class="cobalt-bg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M11.043 7.457 14.586 11H6v2h8.586l-3.543 3.543 1.414 1.414L18.414 12l-5.957-5.957z"
                          fill="#090068"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11m-2 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
                          clip-rule="evenodd"
                          fill="#090068"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home_internal_linking_section">
        <section class="c-mx-sm">
          <div class="home_section__container home_seo_car_types_section">
            <div class="c-text-title-lg">{t("car_for_every")}</div>
            <div class="cobalt-horizontal-list md:cobalt-horizontal-list--fit c-py-md c--mx-sm md:c-mx-none">
              <div class="cobalt-horizontal-list__padding-block c-pl-sm md:c-hidden"></div>
              <a class="link_no_style" onClick={() => navigate(`/type/pickup`)}>
                <div class="tile tile--card">
                  <div
                    class="tile__image-container tile__image-container--carType"
                    title="pickup"
                    style={{ backgroundImage: `url(${car})` }}
                  ></div>
                  <div class="tile__title">{t("pickup")}</div>
                </div>{" "}
              </a>
              <a
                class="link_no_style"
                onClick={() => navigate(`/type/commercial`)}
              >
                <div class="tile tile--card">
                  <div
                    class="tile__image-container tile__image-container--carType"
                    title="utility"
                    style={{ backgroundImage: `url(${car})` }}
                  ></div>
                  <div class="tile__title">{t("commercial")}</div>
                </div>{" "}
              </a>
              <a class="link_no_style" onClick={() => navigate(`/type/family`)}>
                <div class="tile tile--card">
                  <div
                    class="tile__image-container tile__image-container--carType"
                    title="family"
                    style={{ backgroundImage: `url(${car})` }}
                  ></div>
                  <div class="tile__title">{t("family_car")}</div>
                </div>{" "}
              </a>
              <a class="link_no_style" onClick={() => navigate(`/type/suv`)}>
                <div class="tile tile--card">
                  <div
                    class="tile__image-container tile__image-container--carType"
                    title="suv"
                    style={{ backgroundImage: `url(${car})` }}
                  ></div>
                  <div class="tile__title">{t("suv")}</div>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div class="mkg-section-y-spacing">
        <div class="bleed-container bleed-container--bleedSmall owner-marketing-banner">
          <div
            class="bleed-wrapper mkg-bg-owner mkg-rounded-md"
            style={{
              justifyContent: "flex-start",
              minHeight: "450px",
              backgroundColor: "#143E8F",
            }}
          >
            <div class="c-flex c-flex-col c-gap-md c-p-sm c-pt-md c-text-inversed sm:c-flex-row sm:c-p-md lg:c-p-xl">
              <div class="c-flex c-flex-col c-gap-lg c-flex-1 c-justify-between">
                <div class="c-flex c-flex-col c-gap-md c-text-center sm:c-text-left">
                  <h2 class="mkg-text-title-xl c-text-inversed">
                    {t("own_a_car")}
                  </h2>
                  <div class="owner-marketing-banner__description">
                    {t("earn_up_to")}
                  </div>
                </div>
                <div class="c-flex c-flex-col sm:c-flex-row">
                  <a
                    class="cob-Button cob-Button__default cob-Button--large cob-Button__default--primary mkg-button"
                    onClick={() => navigate("/rent-your-car")}
                  >
                    List my car
                    <div class="c-w-md c-ml-xs">
                      <div
                        class="cobalt-bg-icon"
                        data-style="background-color:var(--c-icon-inversed);--u:url(https://cobalt-assets.getaround.com/icons/arrow_right_circle.svg?compress=true);-webkit-mask-image: var(--u);mask-image: var(--u);-webkit-mask-size: contain;mask-size: contain;"
                        style={{
                          backgroundColor: "var(--c-icon-inversed)",
                          "--u":
                            "url(https://cobalt-assets.getaround.com/icons/arrow_right_circle.svg?compress=true)",
                          WebkitMaskImage: "var(--u)",
                          maskImage: "var(--u)",
                          WebkitMaskSize: "contain",
                          maskSize: "contain",
                        }}
                      ></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="c-flex-1">
                <div
                  class="owner-marketing-banner__illustration c-w-full c-rounded-xl"
                  style={{ backgroundImage: `url(${photo_image}) ` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <section class="home_section news-snippets_section js_news-snippets">
          <div class="home_section__container ">
            <div style={{ marginTop: "33px" }} class="c-text-title-lg c-mb-md">
              Services
            </div>
            <div class="cobalt-horizontal-list md:cobalt-horizontal-list--fit c-pb-sm c--mx-sm md:c-mx-none">
              <div class="cobalt-horizontal-list__padding-block c-pl-sm md:c-hidden"></div>
              <a
                class="link_no_style tile tile--card"
                target="_blank"
                rel="noopener"
                href=""
              >
                <div
                  class="tile__image-container"
                  style={{
                    paddingTop: "66.6667%",
                    backgroundImage: `url(${Header_image1})`,
                  }}
                ></div>
                <div class="tile__content c-p-sm c-bg-white c-flex-1">
                  <div class="cobalt-Tag c-mb-xs c-text-accent">
                    #Rental car
                  </div>
                  {/* <div class="c-text-title-sm">Rental car</div> */}
                  <p>
                    Buying, insuring, and maintaining your car is expensive —
                    but you can change that
                  </p>
                </div>{" "}
              </a>
              <a
                class="link_no_style tile tile--card"
                target="_blank"
                rel="noopener"
                href=""
              >
                <div
                  class="tile__image-container"
                  style={{
                    paddingTop: "66.6667%",
                    backgroundImage: `url(${Header_image2})`,
                  }}
                ></div>
                <div class="tile__content c-p-sm c-bg-white c-flex-1">
                  <div class="cobalt-Tag c-mb-xs c-text-accent">
                    #Airport Deposit
                  </div>
                  {/* <div class="c-text-title-sm">Airport Deposit</div> */}
                  <p>
                    A direct trip can take 7 hours. There's so much to see in
                    California,
                  </p>
                </div>{" "}
              </a>
              <a
                class="link_no_style tile tile--card"
                target="_blank"
                rel="noopener"
                href=""
              >
                <div
                  class="tile__image-container"
                  style={{
                    paddingTop: "66.6667%",
                    backgroundImage: `url(${Header_image3})`,
                  }}
                ></div>
                <div class="tile__content c-p-sm c-bg-white c-flex-1">
                  <div class="cobalt-Tag c-mb-xs c-text-accent">
                    #Private Driver
                  </div>
                  {/* <div class="c-text-title-sm">Private Driver</div> */}
                  <p>Coming to Paris? We've gathered all the information</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        {/* <div style={{ textAlign: "center" }} class="c-text-title-lg ">
        Feedback
      </div> */}
      </div>
      <section class="home_section home_section--flat">
        <div class="home_section__container">
          <div
            style={{
              textAlign: "center",
             
            }}
            class="c-text-title-lg "
          >
Feedback          </div>
        </div>
      </section>

     
      <section class="home_section news-snippets_section js_news-snippets">
      <div class="home_section__container ">
      
      <div
        style={{
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
        }}
        class="seo-pages-1xhj18k eg8xb532"
      >
        <button
          onClick={scrollPrevious}
          style={{ background: "none", border: "none", cursor: "pointer" }}
          aria-label="previous"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            fill="none"
            viewBox="0 0 16 16"
            class="seo-pages-1o6k71p"
            role="img"
            version="1.1"
          >
            <path
              fill="#121214"
              fill-rule="evenodd"
              d="M9.522 14a.75.75 0 0 1-.555-.245l-4.773-5.25a.754.754 0 0 1 0-1.01l4.773-5.25a.75.75 0 0 1 1.11 1.01L5.764 8l4.315 4.745A.75.75 0 0 1 9.522 14"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <button
          onClick={scrollNext}
          style={{ background: "none", border: "none", cursor: "pointer" }}
          aria-label="next"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            fill="none"
            viewBox="0 0 16 16"
            class="seo-pages-1o6k71p"
            role="img"
            version="1.1"
          >
            <path
              fill="#121214"
              fill-rule="evenodd"
              d="M6.48 14a.75.75 0 0 0 .555-.245l4.773-5.25a.754.754 0 0 0 0-1.01l-4.773-5.25a.75.75 0 0 0-1.11 1.01L10.239 8l-4.314 4.745A.75.75 0 0 0 6.48 14"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div ref={testimonialsRef} className="testimonials  ">
        {testimonials.map((testimonial) => (
          
          <div key={testimonial.id} className="testimonial-card">
            <img
              src={testimonial.avatar}
              alt={testimonial.name}
              className="avatar"
            />
            <h3>{testimonial.name}</h3>
            <p className="date">{testimonial.date}</p>
            <div class="rating">
              <div class="star_ratings">
                <div class="cobalt-rating-icons">
                  {mapRatingToArray(testimonial?.rating).map((val, index) => (
                    <span
                      key={index}
                      class="cobalt-Icon cobalt-Icon--colorInfoAlt cobalt-Icon--size16"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                        ></path>
                      </svg>
                    </span>
                  ))}
                </div>
              </div>
              <div
                class="c-mb-sm"
                itemprop="reviewRating"
                itemscope=""
                itemtype="http://schema.org/Rating"
              >
                <meta content="5.0" itemprop="ratingValue" />
              </div>{" "}
              <p className="comment">{testimonial.comment}</p>
            </div>
          </div>
        ))}
      </div>
      </div>
      </section>
      <section class="home_section home_section--flat">
        <div class="home_section__container">
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
              marginBottom: "27px",
            }}
            class="c-text-title-lg "
          >
            FAQ
          </div>
        </div>
      </section>

      <section className="home_section home_section--flat">
        <div className="home_section__container">
          <div className="faq expandable">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item1")}
                className={`faqItem ${faqItems.item1 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What is overlo?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item1 ? dropDownClose : dropDown}
                />
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item1 ? "block" : "none" }}
                >
                  <p>
                    Getaround est une plateforme leader mondial de l’autopartage
                    en boucle qui opère en France depuis 2009. Nous mettons en
                    relation des propriétaires de véhicules (professionnels ou
                    particuliers) avec des locataires.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8  ",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item2")}
                className={`faqItem ${faqItems.item2 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What is Getaround for Work?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item2 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item2 ? "block" : "none" }}
                >
                  <p>
                    Getaround for Work est un service d’autopartage dédié aux
                    entreprises ou associations de toutes tailles.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item3")}
                className={`faqItem ${faqItems.item3 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What is Getaround Connect?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item3 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item3 ? "block" : "none" }}
                >
                  <p>
                    Notre solution Getaround Connect vous permet de
                    déverrouiller le véhicule directement depuis votre
                    smartphone via un parcours 100% digital. Pas besoin de
                    rencontrer le propriétaire de la voiture.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item4")}
                className={`faqItem ${faqItems.item4 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  Do I need to create a new Overlo account?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item4 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item4 ? "block" : "none" }}
                >
                  <p>
                    La création d'un nouveau compte n'est pas nécessaire. Nous
                    créons l'espace entreprise à partir d'un compte Getaround
                    déjà existant.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item5")}
                className={`faqItem ${faqItems.item5 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  Are there any fees associated with using Getaround for Work?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item5 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item5 ? "block" : "none" }}
                >
                  <p>
                    L'ouverture et la gestion de votre compte est 100% gratuit.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item6")}
                className={`faqItem ${faqItems.item6 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  In which countries/cities is Getaround for Work available?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item6 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item6 ? "block" : "none" }}
                >
                  <p>
                    Getaround for Work est disponible partout en France
                    métropolitaine.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  borderBottom: "1px solid #E7E7E8 ",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item7")}
                className={`faqItem ${faqItems.item7 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  My company is headquartered in a foreign country but operates
                  in France?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item7 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item7 ? "block" : "none" }}
                >
                  <p>
                    Non, votre entreprise doit être enregistrée auprès de
                    l’administration française et posséder un numéro SIRET.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  flexWrap: "wrap",
                  borderTop: "1px solid #E7E7E8 ",
                  alignItems: "center",
                  paddingTop: "10px",
                  borderBottom: "1px solid #E7E7E8 ",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => toggleItem("item8")}
                className={`faqItem ${faqItems.item8 ? "open" : "closed"}`}
              >
                <h3
                  className="color weight-text-h faqItem__question link"
                  style={{ cursor: "pointer" }}
                >
                  What types of vehicles are available on Getaround for Work?
                </h3>
                <img
                  alt="illustration"
                  class=""
                  src={faqItems.item8 ? dropDownClose : dropDown}
                />{" "}
                <div
                  className="faqItem__answer"
                  style={{ display: faqItems.item8 ? "block" : "none" }}
                >
                  <p>
                    Getaround for Work propose un large choix de véhicules, de
                    la petite citadine à l'utilitaire de 12m3, pour répondre aux
                    besoins des déplacements professionnels des entreprises.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="home_section home_section--flat">
        <div class="home_section__container">
          <div class="homepage-custom-content c-py-xl">
            <div className="questions-container">
              <div className="question">
                <p>Quand louer une voiture ?</p>
                <span onClick={()=>setOpen(!open)} className="arrow-down">&#x25BC;</span>
              </div>
              <div className="question">
                <p>A quel prix peut-on obtenir une location de voiture ?</p>
                <span  className="arrow-down">&#x25BC;</span>
              </div>
            </div>
            {open&&
            <div className="answer">

            <p>A quel prix peut-on obtenir une location de voiture ?</p>
            

            </div>

            }

          </div>
        </div>
      </section> */}
    </div>
  );
};

export default DiscoverNew;
