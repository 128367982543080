import { CircularProgress } from "@material-ui/core";
import React from "react";

const Spinner = () => {
  return(
    
    <div style={{ textAlign: "center", marginTop: "10px" }}>
          <CircularProgress style={{ color: "#FFB400" }} />
        </div>
  )
};

export default Spinner;
