import React from "react";
import tick from "../../../image/svg/tick.svg"
const Insurance = () => {
  return (
    <div class="cobalt-layout-card">
    <div class="cobalt-layout-card__item">
      <div class="car_insurance c-flex c-flex-col sm:c-flex-row sm:c-gap-sm">
        <div class="car_insurance__illustration"></div>
        <div>
          <div class="car_insurance__logo"></div>
          <p class="c-text-body-md">
            Insurance price will be calculated at checkout
          </p>
          <a
            class="c-inline-block c-font-bold c-mt-xs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find out more
          </a>
        </div>
      </div>
    </div>

    <div class="cobalt-layout-card__item">
      <h3 class="c-font-bold">Required conditions</h3>
    </div>
    <div class="cobalt-layout-card__item">
      <ul>
        <li class="cobalt-BulletListItem">
          <span class="cobalt-BulletListItem__Icon">
            <div class="cobalt-Icon cobalt-Icon--colorSuccess">
              <img src={tick}  alt="tick"/>
            </div>
          </span>
          <div class="cobalt-BulletListItem__Content">
            <p class="c-text-body-md">19 years old minimum</p>
          </div>
        </li>
        <li class="cobalt-BulletListItem">
          <span class="cobalt-BulletListItem__Icon">
            <div class="cobalt-Icon cobalt-Icon--colorSuccess">
            <img src={tick}  alt="tick"/>

            </div>
          </span>
          <div class="cobalt-BulletListItem__Content">
            <p class="c-text-body-md">
              Stay in the following countries:
            </p>
          </div>
        </li>
        <p class="car_bullet_list_below_content c-mb-sm c--mt-xs">
          Senegal
        </p>
      </ul>
    </div>
  </div>  

);
};

export default Insurance;
