import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindowF,
} from "@react-google-maps/api";
import star from "../image/svg/SVG.svg";
import { DataContext } from "../ContextApi";
import Spinner from "../ReusableComponent/Spinner";
import placeholderCarFont from "../image/png/placeholder_car_front.jpg";
import OverAllSection from "./Pages/CarDetails/OverallSection";

const libraries = ["places"];

const SearchCar = ({ searchVehicles }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const { loader } = useContext(DataContext);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCK1M71JxoP14Pgs2UOMTd0USEvjg-6UAM", // Remember to replace this with your actual API key
    libraries,
  });

  const onMarkerClick = useCallback((vehicle) => {
    setSelectedVehicle(vehicle);
  }, []);
  
  const onCloseClick = useCallback(() => {
    setSelectedVehicle(null);
  }, []);
  const mapRef = useRef(null);

  const [mapCenter, setMapCenter] = useState({ lat: 31.5204, lng: 74.3587 });
  useEffect(() => {
    if (searchVehicles.length > 0) {
      setMapCenter({
        lat: Number(searchVehicles[0].latitude),
        lng: Number(searchVehicles[0].longitude),
      });
    }
  }, [searchVehicles]);
  const onLoad = (map) => {
    mapRef.current = map;
  };
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setCenter(mapCenter);
      mapRef.current.setZoom(11); // Reset zoom level if needed
    }
  }, [mapCenter]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;
  const createCustomMarkerIcon = (vehicle) => {
    const iconUrl =
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg width="80" height="45" viewBox="0 0 110 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="45" rx="20" fill="#FFC947" stroke="#FFFFFF" stroke-width="3"/>
          <g transform="translate(0, -6)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3925 16.6371C27.8075 16.6371 27.8075 16.6371 27.54 16.9046L26.7362 17.7084C26.4675 17.9759 26.3338 18.1096 26.3338 18.2434C26.3338 18.3771 26.4675 18.5109 26.7362 18.7796L27.8062 19.8496L28.8775 18.7784L29.9487 19.8496L31.045 18.7546L32.115 19.8259L33.1625 18.7784L34.2338 19.8496L34.7662 19.8471C35.3037 21.4559 37.1788 22.2596 40.3913 22.2596C41.9988 22.2596 43.3375 21.4559 43.3375 18.2434C43.3375 15.0309 41.9987 14.2271 40.39 14.2246C37.1775 14.2209 35.3037 15.0296 34.7687 16.6371H28.3925ZM42.175 18.1759C42.175 18.3466 42.1414 18.5156 42.076 18.6734C42.0107 18.8311 41.915 18.9744 41.7942 19.0951C41.6735 19.2158 41.5302 19.3116 41.3725 19.3769C41.2148 19.4422 41.0457 19.4759 40.875 19.4759C40.7043 19.4759 40.5352 19.4422 40.3775 19.3769C40.2198 19.3116 40.0765 19.2158 39.9558 19.0951C39.835 18.9744 39.7393 18.8311 39.674 18.6734C39.6086 18.5156 39.575 18.3466 39.575 18.1759C39.575 17.8311 39.712 17.5004 39.9558 17.2566C40.1996 17.0128 40.5302 16.8759 40.875 16.8759C41.2198 16.8759 41.5504 17.0128 41.7942 17.2566C42.038 17.5004 42.175 17.8311 42.175 18.1759ZM16.5 37.1384H21.9538V23.5009H16.5V37.1384ZM40.1188 30.9384H29.11C28.3138 30.9386 27.5226 30.812 26.7662 30.5634L25.1575 30.0334L25.5925 28.8684C27.125 29.4184 28.74 29.6996 30.3675 29.6996H31.6175C32.4737 29.6996 33.1712 29.0421 33.1712 28.2371C33.1712 27.6296 32.7638 27.0834 32.1588 26.8609L22.6037 23.5009H19.0263V34.6821L25.22 37.0021C26.4855 37.4759 27.8339 37.6882 29.1837 37.6263C30.5336 37.5644 31.8569 37.2296 33.0738 36.6421L39.75 33.4171C39.7375 32.0546 38.5788 30.9384 37.1188 30.9384Z" fill="black"/>
          </g>
          <text x="67%" y="55%" dominant-baseline="middle" text-anchor="middle" font-family="BrownProMarketing, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif" font-weight="700" font-size="22" fill="black">€ ${vehicle?.price === 0 ? vehicle?.low_price : vehicle?.price}</text>
        </svg>
      `);
    return {
      url: iconUrl,
      scaledSize: new window.google.maps.Size(80, 50), // Adjust the size as needed
      anchor: new window.google.maps.Point(40, 50), // Adjust the anchor point
    };
  };
  

  console.log("selectedVehicle", selectedVehicle);

  return (
    <div class="js_search_main_container">
      <div class="c-w-full c-flex">
        <div class="search-results-container c-flex-1 c-w-full c-px-md">
          <div class="js_picks_results_container picks_results_container map--revamp-2021">
            <div class="row">
              <div
                class="js_react-list-view col-xs-12 js_search_view"
                data-view="list"
              >
                {loader ? (
                  <Spinner />
                ) : (
                  <>
                    <div class="selected-address-results c-pt-md c-px-md c--mx-xs">
                      <div class="c-inline-flex c-p-xs c-mb-xs c--ml-xs c--mt-xs c-text-accentInteractive c-cursor-pointer c-flex c-items-center c-gap-2xs c-font-bold c-cursor-pointer">
                        <div class="c-w-md">
                          <span class="cobalt-Icon cobalt-Icon--arrowLeft cobalt-Icon--colorAccent">
                            <svg
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="m11.351 5 1.302 1.519-4.948 4.24h11.297v2H7.705L12.653 17l-1.302 1.518-6.935-5.944c-.484-.35-.596-1.119-.065-1.574l7-6Z"
                              ></path>
                            </svg>
                          </span>
                        </div>
                        Show all cars
                      </div>
                      <div class="c-flex c-gap-md c-items-start">
                        <div class="c-flex-1 c-text-body-lg">
                          <div class="c-font-bold">
                            4 other cars available for delivery at
                          </div>
                          <div class="c-mb-md"></div>
                        </div>
                      </div>
                      <div class="picks_results"></div>
                    </div>
                    <div class="search-results">
                      <div class="c-flex c-gap-md c-items-start c-pt-md c-pb-sm c-mb-2xs">
                        <div class="c-flex-1">
                          <div class="c-text-body-lg c-font-bold">
                            {searchVehicles?.length} results out of{" "}
                            {searchVehicles?.length}
                          </div>
                        </div>
                      </div>
                      <div class="picks_results">
                        {searchVehicles.map((val, index) => (
                          <div
                            onMouseLeave={() => setSelectedVehicle(null)}
                            onMouseEnter={() => setSelectedVehicle(val)}
                            key={index}
                            class="pick_result"
                            onClick={() => {
                              setOpen(true);
                              setId(val?.vehicle_id);
                              // navigate(`/car-detail/${val?.vehicle_id}`);
                            }}
                          >
                            <div
                              class="car_card_search-result js_picks_car_card"
                              title="Nissan Altima 2.5"
                            >
                              <div
                                className="car_card__header"
                                style={{
                                  backgroundImage: `url(${
                                    val?.image_1
                                      ? val?.image_1
                                      : placeholderCarFont
                                  })`,
                                }}
                              >
                                <div class="car_card__badges-container c-flex c-justify-end">
                                  <div class="car_card__badge">
                                    Overlo Connect
                                  </div>
                                </div>
                              </div>
                              <div class="car_card_search-result__body c-flex c-items-start c-gap-xs font-family">
                                <div class="car_card__infos">
                                  <div class="car_card__title">
                                    {val?.car_model}
                                  </div>
                                  {val?.vehicle_location && (
                                    <div
                                      class="car_card__delivery"
                                      data-spec="1833"
                                    >
                                      <span class="cobalt-Icon cobalt-Icon--train cobalt-Icon--colorBase cobalt-Icon--size16">
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12 4c-3.5 0-7 .447-7 3.579v8.5c0 1.73 1.371 3.131 3.063 3.131L6.75 20.553V21h1.951l1.75-1.79h3.299L15.5 21h1.75v-.447l-1.313-1.343c1.693 0 3.063-1.402 3.063-3.13v-8.5C19 4.447 15.867 4 12 4ZM8.062 17.421c-.724 0-1.312-.6-1.312-1.342 0-.741.588-1.342 1.313-1.342.724 0 1.312.6 1.312 1.342 0 .741-.588 1.342-1.313 1.342Zm3.063-6.263H6.75V7.579h4.375v3.579Zm1.75 0V7.579h4.375v3.579h-4.375Zm3.063 6.263c-.725 0-1.313-.6-1.313-1.342 0-.741.588-1.342 1.313-1.342.724 0 1.312.6 1.312 1.342 0 .741-.588 1.342-1.313 1.342Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <div class="car_card__delivery-label">
                                        {val?.vehicle_location}
                                      </div>
                                    </div>
                                  )}
                                  <div class="car_card__meta">
                                    <div class="car_card__badge">
                                      {val?.pickup_type}
                                    </div>
                                    <div class="cobalt-rating">
                                      <div class="cobalt-rating-icons">
                                        <span class="cobalt-Icon cobalt-Icon--star cobalt-Icon--colorInfoAlt cobalt-Icon--size16">
                                          <svg
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M7.712 20.856c-.862.474-1.87-.29-1.705-1.294l.838-4.967-3.489-3.552c-.697-.71-.312-1.947.651-2.094l4.797-.73 2.142-4.535a1.154 1.154 0 0 1 2.108 0l2.177 4.543 4.761.722c.964.147 1.35 1.384.652 2.094l-3.467 3.545.817 4.974c.164 1.003-.843 1.768-1.706 1.294l-4.262-2.34-4.314 2.34Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </div>
                                      <div class="cobalt-rating__label">
                                        No reviews
                                      </div>
                                    </div>
                                    <div class="car-distance">
                                      <span class="cobalt-Icon cobalt-Icon--pin cobalt-Icon--colorBase cobalt-Icon--size16">
                                        <svg
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M12.825 21.325C13.076 21.073 19 15.092 19 10c0-3.86-3.14-7-7-7s-7 3.14-7 7c0 5.091 5.922 11.073 6.175 11.325a1.165 1.165 0 0 0 1.65 0ZM15.5 10a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                          ></path>
                                        </svg>
                                      </span>
                                      <div>
                                        {val.vehicle_milage} {val.milage_unit}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="car_card__pricing">
                                  <div class="car_card__pricing-value">
                                    {val?.currency}{" "}
                                    {val?.price === 0
                                      ? val?.low_price
                                      : val?.price}
                                  </div>
                                  <div class="rental-duration c-px-xs">
                                    <span>
                                      {" "}
                                      {val?.price === 0
                                        ? "/hour"
                                        : val?.duration}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* <div class="c-flex c-justify-center c-pb-md">
                    <button class="search-results__load-more-button cob-Button cob-Button__default">
                      <span class="cob-Button__content">Load more</span>
                    </button>
                  </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div class="map-column">
          <div class="map-container c-bg-neutralAlt">
            <div class="js_picks_results_container js_picks_results_container--map picks_results_container map--revamp-2021 c-w-full c-h-full">
              <div class="loading-container">
                <div class="loading-spinner"></div>
              </div>
              <div
                class="search-switch-view-container stickit-bottom js_search-switch-view-container"
                hidden="true"
              >
                <button
                  class="cob-Button cob-Button__default js_search_view_switch"
                  data-view="list"
                  style={{ display: "none" }}
                >
                  <span class="cob-Button__Icon cob-Button__Icon--left">
                    <span class="cobalt-Icon">
                      <svg width="24" height="24">
                        {/* <use xlink:href="#svg_icons-list"></use> */}
                      </svg>
                    </span>
                  </span>
                  <span>
                    <span class="visible-xs">List</span>
                    <span class="hidden-xs">Show list</span>
                  </span>
                </button>
              </div>
              <div class="js_picks_map_view picks_map_container js_search_view">
                <div class="js_react-map" style={{ height: "100vh" }}>
                  <div class="c-w-full c-h-full">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <GoogleMap
                        mapContainerStyle={{
                          height: "100%",
                          width: "100%",
                        }}
                        zoom={10}
                        center={mapCenter}
                        onLoad={onLoad}
                      >
                        {searchVehicles?.map((val, index) => (
                          <Marker
                            position={{
                              lat: Number(val?.latitude),
                              lng: Number(val?.longitude),
                            }}
                            key={val?.vehicle_id || index} // Ensure a unique key
                            title={`${val?.make_name} ${val?.car_model}`}
                            onClick={() => onMarkerClick(val)}
                            icon={createCustomMarkerIcon(val)}
                          />
                        ))}

                        {selectedVehicle && (
                          <InfoWindowF
                            position={{
                              lat: Number(selectedVehicle?.latitude),
                              lng: Number(selectedVehicle?.longitude),
                            }}
                            onCloseClick={onCloseClick}
                          >
                            <div>
                              <div
                                className="image-background"
                                onClick={() => {
                              setOpen(true);
                              setId(selectedVehicle?.vehicle_id);
                              // navigate(`/car-detail/${val?.vehicle_id}`);
                            }}
                                style={{
                                  backgroundImage: `url(${
                                    selectedVehicle?.image_1
                                      ? selectedVehicle?.image_1
                                      : placeholderCarFont
                                  })`,
                                  width: "200px",
                                  cursor:"pointer"
                                 
                                }}
                              ></div>
                              <div className="main">
                                <p
                                  style={{
                                    fontWeight: "700",
                                  }}
                                >
                                  {selectedVehicle?.make_name}{" "}
                                  {selectedVehicle?.car_model}
                                </p>

                                <div style={{ display: "flex", gap: "5px" }}>
                                  <img src={star} alt="star" />{" "}
                                  <p
                                    style={{
                                      fontSize: "15px",
                                      color: "#7B728E",
                                    }}
                                  >
                                    {" "}
                                    {/* {selectedVehicle?.rating} */} No Review
                                  </p>
                                </div>
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <p
                                    style={{
                                      fontWeight: "700",
                                    }}
                                  >
                                    {selectedVehicle?.currency}{" "}
                                    {selectedVehicle?.price === 0
                                      ? selectedVehicle?.low_price
                                      : selectedVehicle?.price}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#7B728E",
                                    }}
                                  >
                                    {" "}
                                    ({" "}
                                    {selectedVehicle?.price === 0
                                      ? "/hour"
                                      : selectedVehicle?.duration}
                                    )
                                  </p>
                                </div>
                              </div>
                            </div>
                          </InfoWindowF>
                        )}
                      </GoogleMap>
                      {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d864931.4046074317!2d73.6559361625!3d32.13306921280899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1720711256419!5m2!1sen!2s"
                        width="100%"
                        height="450"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="I love snacks"
        key={vertical + horizontal}
      />
    </Box> */}
      {open && (
        <div>
          <OverAllSection open={open} setOpen={setOpen} id={id} />
        </div>
      )}
    </div>
  );
};

export default SearchCar;
