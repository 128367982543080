import React from "react";
import character_with4 from "../../../../image/png/character_with4.svg";
const MaintainCertification = () => {
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Is your car well-maintained?</div>
        <div class="c-mb-lg">
          <img
            class="cobalt-illustration-image "
            role="presentation"
            alt="getaround_technician"
            width="160"
            lazyload="false"
            src={character_with4}
          />
        </div>
        <ul class="cobalt-BulletList c-mb-lg">
          <li class="cobalt-BulletListItem c-mb-md">
            <span class="cobalt-BulletListItem__Icon">
              <div class="cobalt-Icon cobalt-Icon--size24 cobalt-Icon--colorSuccess">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </span>
            <div class="cobalt-BulletListItem__Content">
              <p class="c-text-body-md">My vehicle is well maintained</p>
            </div>
          </li>
          <li class="cobalt-BulletListItem c-mb-md">
            <span class="cobalt-BulletListItem__Icon">
              <div class="cobalt-Icon cobalt-Icon--size24 cobalt-Icon--colorSuccess">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </span>
            <div class="cobalt-BulletListItem__Content">
              <p class="c-text-body-md">
                My vehicle has an up-to-date roadworthiness test
              </p>
            </div>
          </li>
        </ul>

        <div class="cobalt-Note cobalt-Note--fullWidth">
          We require well-maintained and quality vehicles for the safety of
          drivers.
        </div>
      </div>
   
    </>
  );
};

export default MaintainCertification;
