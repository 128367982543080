import React from "react";
import character_with5 from "../../../../image/png/character_with5.svg"
const PriceIntroduction = () => {
  return(
    <div >
<div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
                    <div class="c-text-section-heading c-mb-xs">
                      List my car
                    </div>
                    <div class="c-text-title-md c-mb-lg">
                      Next up, let's work out your prices
                    </div>
                    <div class="c-mb-lg c-flex c-justify-center">
                      <img class="cobalt-illustration-image " role="presentation" alt="character_sitting_on_car_trunk_euros"
                        width="188" lazyload="false"
                        src={character_with5}/>
                    </div>
                    <p class="c-text-subdued">
                      Defining the prices for renting your car is an essential step in managing your listing.
                    </p>
                  </div>
               

    </div>

  )
};

export default PriceIntroduction;
