import React, { useState ,useEffect, useContext} from "react";
import { changeEmail, getUserDetail } from "../../../../api";
import { DataContext } from "../../../../ContextApi";

const AccountSetting = () => {
  const [accountSetting,setAccountSetting]=useState({email:"",password:""})
  const {openDialog}=useContext(DataContext)
  useEffect(() => {
    getUserDetail(setAccountSetting)
   }, []);
   const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setAccountSetting((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
     ...accountSetting

     
    };
    changeEmail(payload,openDialog)
    console.log("Form submitted with:", payload);
  };
  return (
    <div class="col-sm-9 col-xs-12">
    <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
      <div class="cobalt-Card__Section">
        <div class="tab_panes_container">
          <div class="tab_pane active tab_account_settings">
            <fieldset class="cobalt-Fieldset">
              <legend>Change your email</legend>
              <form class="new_user" id="edit_account_update_email_form" autocomplete="off"
                onSubmit={handleSubmit}><input type="hidden" name="_method"
                  value="patch" autocomplete="off"/><input type="hidden" name="authenticity_token"
                  value=""
                  autocomplete="off"/>
                <div class="cobalt-FormField" data-form-field-method="email"><label
                    class="cobalt-FormField__Label" for="user_email">New email</label>
                  <div class="cobalt-TextField"><input required title="New email is required"
                      class="cobalt-TextField__Input" onChange={handleInputChange}  value={accountSetting?.email} type="text" name="email" id="user_email"/></div>
                </div>
                <div class="cobalt-FormField cobalt-FormField--withHint"
                  data-form-field-method="current_password" data-form-field-hint-status="hint"><label
                    class="cobalt-FormField__Label" for="user_current_password">Overlo password</label>
                  <div class="cobalt-TextField"><input title="Your password is required."
                      required="required" class="cobalt-TextField__Input" type="password"
                      name="password" onChange={handleInputChange}  value={accountSetting?.password} id="user_current_password"/></div>
                  <div class="cobalt-Hint"><span class="cobalt-Hint__Icon"><span
                        class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16"><svg
                          viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z">
                          </path>
                        </svg>
                      </span></span><span class="cobalt-Hint__Message"><a class="forget_password"
                        data-modalid="form_reset_password" href="#form_reset_password"
                        data-modal-inited="true">Forgot your password?</a></span></div>
                </div>
                <input type="submit" name="commit" value="Change your email"
                  class="cob-Button cob-Button__default cob-Button__default--primary"/>
              </form>
            </fieldset>

            <fieldset class="cobalt-Fieldset">
              <legend>Change your password</legend>
              <form class="new_user" id="edit_account_update_password_form" autocomplete="off"
                action="/sign-up" accept-charset="UTF-8" method="post"><input type="hidden" name="_method"
                  value="patch" autocomplete="off"/><input type="hidden" name="authenticity_token"
                  value=""
                  autocomplete="off"/>
                <div class="cobalt-FormField cobalt-FormField--withHint"
                  data-form-field-method="current_password" data-form-field-hint-status="hint"><label
                    class="cobalt-FormField__Label" for="user_current_password">Old password</label>
                  <div class="cobalt-TextField"><input title="Old password is required." required="required"
                      class="cobalt-TextField__Input" type="password" name="user[current_password]"
                      id="user_current_password"/></div>
                  <div class="cobalt-Hint"><span class="cobalt-Hint__Icon"><span
                        class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16"><svg
                          viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z">
                          </path>
                        </svg>
                      </span></span><span class="cobalt-Hint__Message"><a class="forget_password"
                        data-modalid="form_reset_password" href="#form_reset_password"
                        data-modal-inited="true">Forgot your password?</a></span></div>
                </div>
                <div class="cobalt-FormField" data-form-field-method="password"><label
                    class="cobalt-FormField__Label" for="user_password">New password</label>
                  <div class="cobalt-TextField"><input title="New password is required."
                      class="js_password_input cobalt-TextField__Input" required="required" type="password"
                      name="user[password]" id="user_password"/></div>
                </div>
                <svg style={{display:"none"}} xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <symbol id="svg_icons-cross" class="svg_from_sprite" viewBox="0 0 24 24">
                      
                      <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                        <path
                          d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M14.1494949,12.0281746 L16.9779221,9.19974747 C17.3684464,8.80922318 17.3684464,8.1760582 16.9779221,7.78553391 L16.2708153,7.07842712 C15.880291,6.68790283 15.247126,6.68790283 14.8566017,7.07842712 L12.0281746,9.90685425 L9.19974747,7.07842712 C8.80922318,6.68790283 8.1760582,6.68790283 7.78553391,7.07842712 L7.07842712,7.78553391 C6.68790283,8.1760582 6.68790283,8.80922318 7.07842712,9.19974747 L9.90685425,12.0281746 L7.07842712,14.8566017 C6.68790283,15.247126 6.68790283,15.880291 7.07842712,16.2708153 L7.78553391,16.9779221 C8.1760582,17.3684464 8.80922318,17.3684464 9.19974747,16.9779221 L12.0281746,14.1494949 L14.8566017,16.9779221 C15.247126,17.3684464 15.880291,17.3684464 16.2708153,16.9779221 L16.9779221,16.2708153 C17.3684464,15.880291 17.3684464,15.247126 16.9779221,14.8566017 L14.1494949,12.0281746 L14.1494949,12.0281746 Z"
                          fill="#b4bbc6"></path>
                      </g>
                    </symbol>
                    <symbol id="svg_icons-check" class="svg_from_sprite" viewBox="0 0 24 24">
                      
                      <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                        <path
                          d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                          fill="#b4bbc6"></path>
                      </g>
                    </symbol>
                  </defs>
                </svg>
                <div class="js_password_strength_indicator"></div>

                <div class="cobalt-FormField cobalt-FormField--withHint"
                  data-form-field-method="password_confirmation" data-form-field-hint-status="hint"><label
                    class="cobalt-FormField__Label" for="user_password_confirmation">Confirm
                    password</label>
                  <div class="cobalt-TextField"><input title="Old password confirmation is required."
                      required="required" class="cobalt-TextField__Input" type="password"
                      name="user[password_confirmation]" id="user_password_confirmation"/></div>
                  <div class="cobalt-Hint"><span class="cobalt-Hint__Icon"><span
                        class="cobalt-Icon cobalt-Icon--colorSubdued cobalt-Icon--size16"><svg
                          viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 9h2V7h-2v2zm1 11c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-18C6.477 2 2 6.477 2 12A10 10 0 1 0 12 2zm-1 15h2v-6h-2v6z">
                          </path>
                        </svg>
                      </span></span><span class="cobalt-Hint__Message">Enter password again</span></div>
                </div>
                <input type="submit" name="commit" value="Change your password"
                  class="cob-Button cob-Button__default cob-Button__default--primary"/>
              </form>
            </fieldset>

            <fieldset class="cobalt-Fieldset">
              <legend>Delete your account</legend>
              <div id="delete_account_confirmation_popin" class="c-hidden" data-closeable="true">
                <div class="js_cobalt-inline-modal__title">Caution
                </div>
                <div class="js_cobalt-inline-modal__body" style={{minHeight:"inherit"}}>
                  <p>This action is irreversible. If you’re a car owner, please make sure that you don’t
                    have any <a href="/dashboard/payments?role=owner">upcoming transfers</a> and please
                    export your payment history. Are you sure you want to delete your account?</p>
                </div>
                <div class="js_cobalt-inline-modal__footer"><button name="button" type="submit"
                    class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth c-mb-sm js_cobalt-inline-modal__close">Cancel</button>
                  <button name="button" type="submit"
                    class="cob-Button cob-Button__default cob-Button__default--destructive cob-Button--fullWidth js_cobalt-inline-modal__confirm">Delete
                    your account</button>
                </div>
              </div>
              <form class="edit_user" id="edit_account_delete_form" autocomplete="off"
                data-confirmmodalid="delete_account_confirmation_popin"
                action="/dashboard/profile?profile_tab=account_settings" accept-charset="UTF-8"
                method="post" data-modal-inited="true"><input type="hidden" name="_method" value="delete"
                  autocomplete="off"/><input type="hidden" name="authenticity_token"
                  value=""
                  autocomplete="off"/><input type="submit" name="commit" value="Delete your account"
                  class="cob-Button cob-Button__default cob-Button__default cob-Button__default--primary cob-Button__default--destructive"/>
              </form>
            </fieldset>

            <div id="form_reset_password" class="c-hidden" data-closeable="true">
              <div class="js_cobalt-inline-modal__title">Forgotten password?
              </div>
              <div class="js_cobalt-inline-modal__body" style={{minHeight:"inherit"}}>An email with
                instructions to reset your password is going to be sent to elhadj.ba@outlook.com
              </div>
              <div class="js_cobalt-inline-modal__footer">
                <form class="button_to" method="post" action="/dashboard/profile/reset_password"><input
                    class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth"
                    type="submit" value="Send"/><input type="hidden" name="authenticity_token"
                    value=""
                    autocomplete="off"/></form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>  


)
};

export default AccountSetting;
