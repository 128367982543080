import * as React from "react";

import  logo from "../image/png/logo.svg"
export default function Footer() {
  return (
<div class="c-w-full mkg-bg-primary lg:c-pb-lg">
      <div class="bleed-container">
        <div class="footer-wrapper--2024" data-version="full">
          <footer class="footers js_footers footer--full">
            <div class="c-bg-primary mkg-rounded-md">
              <div
                class="c-flex c-flex-col c-gap-lg c-py-lg c-px-sm c-text-body-md sm:c-px-md lg:c-px-xl"
              >
                <div class="c-flex c-justify-between">
                  <img
                    class="footer__Overlo-logo"
                    alt="Overlo logo"
                    width="110"
                    height="46"
                    src={logo}
                  />
                </div>
                <div class="row main_footer__section-container">
                  <div
                    class="main_footer__section col-md-6 col-sm-12 col-xs-12"
                  >
                    <div class="main_footer__title">About us</div>
                    <p>
                      Overlo makes sharing cars and trucks simple through its
                      proprietary cloud and in-car Connect® technology. Overlo’s
                      on-demand technology enables a contactless experience — no
                      waiting in line at a car rental facility, manually
                      completing paperwork, or meeting anyone to collect or drop
                      off car keys.
                    </p>
                  </div>
                  <div
                    class="main_footer__section main_footer__section--help_links col-md-2 col-sm-12 col-xs-12"
                  >
                    <div class="main_footer__title">Learn more</div>
                    <ul class="main_footer__links_container">
                      <li>
                        <a class="main_footer__link" href="/how-it-works"
                          >How does it work?</a
                        >
                      </li>
                      <li>
                        <a
                          noblank="true"
                          class="main_footer__link"
                          href="/trust"
                          >A trusted service</a
                        >
                      </li>
                      <li>
                        <a class="main_footer__link" href="/mobile"
                          >Overlo app</a
                        >
                      </li>
                      <li>
                        <a
                          class="main_footer__link"
                          href="/contact"
                          rel="nofollow"
                          target="_blank"
                          >Contact</a
                        >
                      </li>
                      <li>
                        <a class="main_footer__link" href="/blog/">Blog</a>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="main_footer__section main_footer__section--discover col-md-2 col-sm-12 col-xs-12"
                  >
                    <div class="main_footer__title">Resources</div>
                    <ul class="main_footer__links_container">
                      <li>
                        <a class="main_footer__link" href="/community/host"
                          >Owner resources</a
                        >
                      </li>
                      <li>
                        <a class="main_footer__link" href="/professionals"
                          >Pros</a
                        >
                      </li>
                      <li>
                        <a noblank="true" class="main_footer__link" href="/help"
                          >Help center</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div
                    class="main_footer__section col-md-2 col-sm-12 col-xs-12"
                  >
                    <div class="main_footer__title">Partners</div>
                    <ul class="main_footer__links_container">
                      <li>
                        <a class="main_footer__link" href="partners"
                          >Partner with us</a
                        >
                      </li>
                      <li>
                        <a class="main_footer__link" href="drive-with-uber"
                          >Drive with Uber</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="c-flex c-justify-center c-items-center c-gap-sm c-mt-sm md:c-mt-md"
                >
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    class="corporate_footer__social_logo--twitter"
                    aria-label="X former Twitter"
                    href=""
                  >
                    <div class="cobalt-bg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M19.93 8.062q.039.157.039.508 0 2.813-1.367 5.43-1.407 2.774-3.946 4.414-2.773 1.836-6.367 1.836-3.438 0-6.289-1.836.43.04.977.04 2.85 0 5.117-1.759-1.367 0-2.403-.8a4.1 4.1 0 0 1-1.425-2.012q.39.04.742.04.546 0 1.094-.118a4.13 4.13 0 0 1-2.344-1.446 3.93 3.93 0 0 1-.938-2.578v-.078q.86.508 1.836.547a4.55 4.55 0 0 1-1.328-1.484 3.94 3.94 0 0 1-.508-1.973q0-1.074.586-2.05a11.3 11.3 0 0 0 3.73 3.046 11.35 11.35 0 0 0 4.708 1.25 5.7 5.7 0 0 1-.078-.937q0-1.095.546-2.05.548-.958 1.485-1.505A3.96 3.96 0 0 1 15.828 4q.898 0 1.66.352c.502.23.958.547 1.348.937a8.4 8.4 0 0 0 2.617-.977q-.468 1.446-1.797 2.266A8.8 8.8 0 0 0 22 5.914a9 9 0 0 1-2.07 2.148"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    class="corporate_footer__social_logo--facebook"
                    aria-label="Facebook"
                    href=""
                  >
                    <div class="cobalt-bg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.438 22v-7.03h-2.54v-2.909h2.54V9.844c0-2.522 1.493-3.915 3.777-3.915 1.094 0 2.239.197 2.239.197v2.476h-1.262c-1.243 0-1.63.777-1.63 1.572v1.887h2.774l-.443 2.91h-2.33V22c4.78-.755 8.437-4.917 8.437-9.939C22 6.504 17.523 2 12 2S2 6.504 2 12.061c0 5.022 3.657 9.184 8.438 9.939"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    class="corporate_footer__social_logo--youtube"
                    aria-label="Youtube"
                    href=""
                  >
                    <div class="cobalt-bg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M21.597 7.198q.256.99.366 3.003L22 12.033l-.037 1.831q-.11 2.052-.366 3.04a2.55 2.55 0 0 1-.66 1.1 2.54 2.54 0 0 1-1.135.659q-.952.256-4.469.366L12 19.066l-3.333-.037q-3.517-.11-4.47-.366a2.54 2.54 0 0 1-1.135-.66 2.55 2.55 0 0 1-.66-1.098q-.255-.99-.365-3.04L2 12.032q0-.806.037-1.832.11-2.014.366-3.003a2.54 2.54 0 0 1 .66-1.136 2.54 2.54 0 0 1 1.135-.66q.952-.255 4.469-.365L12 5l3.333.037q3.517.11 4.47.366.658.183 1.135.66.476.475.66 1.135zM9.95 15.037l5.238-3.004L9.95 9.066z"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    class="corporate_footer__social_logo--instagram"
                    aria-label="Instagram"
                    href=""
                  >
                    <div class="cobalt-bg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.75 7.258q1.21 0 2.246.605 1.035.605 1.64 1.64.606 1.037.606 2.247t-.605 2.246a4.5 4.5 0 0 1-1.64 1.64 4.37 4.37 0 0 1-2.247.606 4.37 4.37 0 0 1-2.246-.605 4.5 4.5 0 0 1-1.64-1.64 4.37 4.37 0 0 1-.606-2.247q0-1.21.605-2.246.605-1.035 1.64-1.64a4.37 4.37 0 0 1 2.247-.606m0 7.422q1.21 0 2.07-.86t.86-2.07-.86-2.07a2.82 2.82 0 0 0-2.07-.86q-1.21 0-2.07.86a2.82 2.82 0 0 0-.86 2.07q0 1.21.86 2.07t2.07.86m5.742-7.618a1.22 1.22 0 0 1-.332.743.95.95 0 0 1-.723.312q-.429 0-.742-.312a1.01 1.01 0 0 1-.312-.743q0-.429.312-.742.313-.312.742-.312.43 0 .743.312.312.313.312.742m2.969 1.055q.039 1.094.039 3.633c0 2.539-.02 2.91-.059 3.652q-.059 1.113-.332 1.934a4.55 4.55 0 0 1-1.074 1.7 4.55 4.55 0 0 1-1.7 1.073q-.819.274-1.933.332-1.114.06-3.652.059-2.54 0-3.652-.059-1.114-.058-1.934-.37a4.16 4.16 0 0 1-1.7-1.036 4.55 4.55 0 0 1-1.073-1.7q-.274-.819-.332-1.933-.06-1.114-.059-3.652 0-2.54.059-3.652.059-1.114.332-1.934a4.55 4.55 0 0 1 1.074-1.7 4.55 4.55 0 0 1 1.7-1.073q.819-.274 1.933-.332Q9.211 2.999 11.75 3q2.54 0 3.652.059t1.934.332a4.55 4.55 0 0 1 1.7 1.074q.721.723 1.073 1.7.274.82.352 1.952m-1.875 8.79q.234-.665.312-2.11.04-.86.04-2.422v-1.25a55 55 0 0 0-.04-2.422q-.078-1.485-.312-2.11a2.82 2.82 0 0 0-1.68-1.679q-.625-.234-2.11-.312a57 57 0 0 0-2.421-.04h-1.25a57 57 0 0 0-2.422.04q-1.445.078-2.11.312a2.82 2.82 0 0 0-1.679 1.68q-.234.625-.312 2.11a57 57 0 0 0-.04 2.421v1.25q0 1.563.04 2.422.078 1.445.312 2.11.508 1.21 1.68 1.679.664.234 2.11.312.859.04 2.421.04h1.25a55 55 0 0 0 2.422-.04q1.485-.078 2.11-.312 1.21-.509 1.679-1.68z"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="corporate_footer__container container sm:c-flex-row-reverse"
            >
              <div
                class="corporate_footer__copyright_links_container c-items-center"
              >
                <ul class="corporate_footer__links c-mb-sm md:c-mb-none">
                  <li class="corporate_footer__link">
                    <a class="" href="terms" rel="nofollow" target="_blank"
                      >Legal terms</a
                    >
                  </li>
                  <li class="corporate_footer__link">
                    <a class="" href="privacy" rel="nofollow" target="_blank"
                      >Privacy policy</a
                    >
                  </li>
                  <li class="corporate_footer__link">
                    <a class="" href="cookies" rel="nofollow" target="_blank"
                      >Cookies</a
                    >
                  </li>
                  <li class="corporate_footer__link">
                    <a href="press">Press</a>
                  </li>
                  <li class="corporate_footer__link">
                    <a href="careers">Careers</a>
                  </li>
                  <li class="corporate_footer__link">
                    <a class="" href="" rel="nofollow" target="_blank"
                      >Investor relations</a
                    >
                  </li>
                </ul>
                <div
                  class="corporate_footer__copyright c-text-subdued c-text-center"
                >
                  © 2010-2024 - All rights reserved
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}