import React, { useState, useEffect, useContext, useRef } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Spinner from "../../../ReusableComponent/Spinner";
import { getPaymentHistory } from "../../../api";
import { DataContext } from "../../../ContextApi";
import character from "../../../image/png/character_with2.svg";

const Payments = () => {
  const [loader, setLoader] = useState(false);
  const { openDialog } = useContext(DataContext);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    setLoader(true);
    getPaymentHistory(setLoader, setPaymentHistory, openDialog);
  }, [openDialog]);

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      month: "short",
      day: "numeric",
      year: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = date
      .toLocaleString("en-US", options)
      .replace(",", "") // Remove comma after day
      .replace("AM", "am") // Convert AM to am
      .replace("PM", "pm"); // Convert PM to pm

    return formattedDate;
  }

  const exportToPDF = () => {
    const doc = new jsPDF();

    // Header and footer setup
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      const addHeader = () => {
        const headerTextLeft = "Overlo"; // Text for the left side
        const headerTextCenter = "Payment History Report"; // Text for the center
      
        // Set text color and font for the left header
        doc.setTextColor("#ffb400"); // Yellow text color
        doc.setFontSize(20); // Larger font size for the left text
      
        // Draw the left-aligned text
        doc.text(headerTextLeft, 10, 10); // Position the text on the left
      
        // Set text color and font for the centered header
        doc.setTextColor(0, 0, 0); // Black text color
        doc.setFontSize(12); // Smaller font size for the centered text
      
        // Draw the centered text on the next line below the left text
        doc.text(headerTextCenter, pageWidth / 2, 10, { align: "center" });
      };
      

    const addFooter = (pageNumber, totalPages) => {
      doc.setFontSize(10);
      const footerYPosition = pageHeight - 10; // Adjust footer position to avoid overlap
      doc.text(
        `Page ${pageNumber} of ${totalPages}`,
        pageWidth / 2,
        footerYPosition,
        { align: "center" }
      );
    };

    const addHeaderAndFooter = (data) => {
      const pageCount = doc.internal.getNumberOfPages();

      // Adjust table to allow space for footer
      data.settings.margin.bottom = 30;

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        addHeader();
        addFooter(i, pageCount);
      }
    };

    // Add table to PDF
    doc.autoTable({
      html: tableRef.current,
      startY: 15, // Start below the header
      margin: { bottom: 30 }, // Extra margin to avoid footer overlap
      styles: { fontSize: 10, cellPadding: 3, overflow: "linebreak" },
      didDrawPage: addHeaderAndFooter,
    });

    doc.save("payments.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.table_to_sheet(tableRef.current);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payments");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "payments.xlsx");
  };

  return (
    <>
      {paymentHistory?.result?.length > 0 ? (
        <div className="container dashboard_container" id="dashboard">
          <div className="row flex_row_center_above_xs ">
            <div className="col-xs-12 ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div className="c-text-title-md">Payments</div>
                <div>
                  <button className="cob-Button cob-Button__default cob-Button__default--primary c-mb-sm" onClick={exportToPDF} style={{marginRight: "10px"}}>Export to PDF</button>
                  <button className="cob-Button cob-Button__default cob-Button__default--primary c-mb-sm" onClick={exportToExcel}>Export to Excel</button>
                </div>
              </div>
            </div>
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <div className="row">
              <div className="col-xs-12 no-outer-gutter-xs">
                <div className="cobalt-Card cobalt-Card--responsive c-overflow-hidden c-mb-lg">
                  <div className="cobalt-Card__Section cobalt-Card__Section--divided">
                    <table className="cobalt-Table" ref={tableRef}>
                      <thead>
                        <tr>
                          <th>Detail</th>
                          <th>Pick Up</th>
                          <th>Return</th>
                          <th>Charge Amount</th>
                          {paymentHistory?.company_id === -1 ? (
                            <th>Rental Share</th>
                          ) : paymentHistory?.company_id !== -1 &&
                            paymentHistory?.company_type === 2 ? (
                            <th>Rental Share</th>
                          ) : (
                            ""
                          )}
                          {paymentHistory?.company_id === -1 ? (
                            <th>Overlo Share</th>
                          ) : paymentHistory?.company_id !== -1 &&
                            paymentHistory?.company_type === 2 ? (
                            <th>Overlo Share</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {paymentHistory?.result?.map((val, index) => (
                          <tr key={index}>
                            <td>
                              {val?.car_make} {val?.car_model}
                            </td>
                            <td>{formatDate(val?.pickup_datetime)} </td>
                            <td>{formatDate(val?.dropoff_datetime)} </td>
                            <td>{val?.total_price} € </td>
                            {paymentHistory?.company_id === -1 ? (
                              <td>{val?.rentals_price} €</td>
                            ) : paymentHistory?.company_id !== -1 &&
                              paymentHistory?.company_type === 2 ? (
                              <td>{val?.rentals_price} €</td>
                            ) : (
                              ""
                            )}
                            {paymentHistory?.company_id === -1 ? (
                              <td>{val?.commission} € </td>
                            ) : paymentHistory?.company_id !== -1 &&
                              paymentHistory?.company_type === 2 ? (
                              <td>{val?.commission} €</td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="container dashboard_container" id="dashboard">
          <div className="row">
            <div className="col-xs-12 no-outer-gutter-xs">
              <div className="cobalt-Card">
                <div className="cobalt-EmptyState">
                  <img
                    className="cobalt-illustration-image cobalt-EmptyState__Image"
                    role="presentation"
                    alt="hand_with_credit_card"
                    width="188"
                    lazyload="false"
                    src={character}
                  />
                  <h2 className="cobalt-EmptyState__Title">
                    You don't have a transaction yet on Overlo.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Payments;
