import React, { useContext } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const EnergyTransmition = () => {
  const { rentYourCar, setSelectDataCaseThree, selectDataCaseThree } =
    useContext(DataContext);
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md c-mb-lg">Add more details</div>
        <div class="c-mb-sm">
          <div class="cobalt-FormField" data-form-field-method="energy">
            <label class="cobalt-FormField__Label" for="car_energy">
              Fuel
            </label>
            <div class="cobalt-SelectField">
              <select
                class="cobalt-SelectField__Input"
                name="fuel_type"
                value={selectDataCaseThree.fuel_type}
                onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseThree,
                    selectDataCaseThree,
                    "caseThree"
                  )
                }
              >
                {rentYourCar?.fuel_type?.map((val) => (
                  <option value={val.fuel_type_id}>{val.fuel_type}</option>
                ))}
              </select>
            </div>
          </div>
          <div class="cobalt-FormField">
            <label class="cobalt-FormField__Label" for="car_transmission">
              Gearbox
            </label>
            {rentYourCar?.transmission?.map((val) => (
            <div
              class="cobalt-CheckmarkField"
              data-form-checkmark-method="transmission"
            >
              <label class="cobalt-CheckmarkField__LabelWrapper">
                <input
                  class="cobalt-CheckmarkField__Input"
                  type="radio"
                  value={val?.transmission_id}
                  checked={selectDataCaseThree.transmission_id === val.transmission_id}
                  name="transmission_id"
                  onChange={(e) =>
                  handleChange(
                    e,
                    setSelectDataCaseThree,
                    selectDataCaseThree,
                    "caseThree",
                    "radio"
                  )
                }
                  id="car_transmission_1"
                />
                <span class="cobalt-CheckmarkField__Checkmark"></span>
                <span class="cobalt-CheckmarkField__Label">{val?.transmission_type                }</span>
              </label>
            </div>
            ))}
          </div>
        </div>
        <div class="cobalt-Note cobalt-Note--fullWidth">
          This information helps drivers find the best fit for them.
        </div>
      </div>
    </>
  );
};

export default EnergyTransmition;
