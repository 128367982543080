import React from "react";

const Referral = () => {
  return (
<div class="col-sm-9 col-xs-12">
                <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
                  <div class="cobalt-Card__Section">
                    <div class="tab_panes_container">
                      <div class="tab_pane active tab_referral">
                        <div class="referrer__container">

                          <div class="referrer__description_container">
                            <h2 class="c-text-title-md c-mb-md">
                              Refer a friend and earn $20 credit
                            </h2>
                            <div class="referrer__description_container__inner">
                              <ul class="c-mb-md">
                                <li class="cobalt-BulletListItem">
                                  <span class="cobalt-BulletListItem__Icon">
                                    <span class="cobalt-Icon cobalt-Icon--colorSuccess">
                                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                                          fill-rule="evenodd"></path>
                                      </svg>

                                    </span>
                                  </span>
                                  <div class="cobalt-BulletListItem__Content">
                                    Your friend gets <strong>$20</strong> towards their first Overlo trip
                                  </div>
                                </li>
                                <li class="cobalt-BulletListItem">
                                  <span class="cobalt-BulletListItem__Icon">
                                    <span class="cobalt-Icon cobalt-Icon--colorSuccess">
                                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M10.039794,16.4579825 L10.3868138,16.8048824 C10.7382605,17.1562077 11.3079394,17.1562077 11.6593861,16.8048824 L12.0064058,16.4579825 L18.3862862,10.0803058 C18.7379046,9.72880879 18.7379046,9.15879074 18.3862862,8.80729375 L17.6922467,8.11349398 C17.3408,7.76216867 16.771121,7.76216867 16.4196743,8.11349398 L11.0398301,13.5249288 L8.08032565,10.5664466 C7.72887895,10.2151213 7.15920001,10.2151213 6.80775331,10.5664466 L6.11371383,11.2602463 C5.76209539,11.6117433 5.76209539,12.1817614 6.11371383,12.5332584 L10.039794,16.4579825 Z"
                                          fill-rule="evenodd"></path>
                                      </svg>

                                    </span>
                                  </span>
                                  <div class="cobalt-BulletListItem__Content">
                                    You get <strong>$20</strong> once they've completed their trip
                                  </div>
                                </li>
                              </ul>
                              <p class="c-mb-md c-text-body-md c-text-subdued">
                                You can earn credits for referring up to 2 friends per month.
                              </p>
                              <div class="cobalt-buttons-group">
                                <div class="cobalt-buttons-group__item">
                                  <div class="popin js_popin mfp-hide js_popin_invite_friends"><button name="button"
                                      type="button" title="Close" class="mfp-close"></button>
                                    <div class="popin_content">
                                      <div class="referrer_popin__container js_referral_link_share">
                                        <div class="cobalt-Card__Section">
                                          <div class="c-text-title-sm c-mb-sm">Share your personal link</div>
                                          <div class="cobalt-ComposedFormField c-mb-md">
                                            <div class="cobalt-TextField">
                                              <input class="cobalt-TextField__Input js_sharing_url" readonly=""
                                                type="text" value=""/>
                                            </div>
                                            <button
                                              class="cob-Button cob-Button__ghost cob-Button__ghost--standalone js_copy_url" >
                                              <span class="cob-Button__Icon cob-Button__Icon--left">
                                                <div class="cobalt-Icon">
                                                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                      d="M10.832 13.652c.41.39.41 1.03 0 1.42-.39.39-1.03.39-1.42 0a5.003 5.003 0 0 1 0-7.07l3.54-3.54a5.003 5.003 0 0 1 7.07 0 5.003 5.003 0 0 1 0 7.07l-1.49 1.49c.01-.82-.12-1.64-.4-2.42l.47-.48a2.982 2.982 0 0 0 0-4.24 2.982 2.982 0 0 0-4.24 0l-3.53 3.53a2.982 2.982 0 0 0 0 4.24zm2.82-4.24c.39-.39 1.03-.39 1.42 0a5.003 5.003 0 0 1 0 7.07l-3.54 3.54a5.003 5.003 0 0 1-7.07 0 5.003 5.003 0 0 1 0-7.07l1.49-1.49c-.01.82.12 1.64.4 2.43l-.47.47a2.982 2.982 0 0 0 0 4.24 2.982 2.982 0 0 0 4.24 0l3.53-3.53a2.982 2.982 0 0 0 0-4.24.973.973 0 0 1 0-1.42z">
                                                    </path>
                                                  </svg>

                                                </div>
                                              </span>
                                              <span>Copy link</span>
                                            </button>
                                          </div>
                                          <div class="referrer_popin__link_sharing">
                                            <div class="c-text-section-heading c-mr-xs">
                                              Share using
                                            </div>
                                            <div>
                                              <button
                                                class="cob-Button cob-Button__default brand-button--facebook js_facebook_share">
                                                <div class="cobalt-Icon cobalt-Icon--colorInversed">
                                                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M10.438 22v-7.03h-2.54v-2.909h2.54V9.844c0-2.522 1.493-3.915 3.777-3.915 1.094 0 2.239.197 2.239.197v2.476h-1.262c-1.243 0-1.63.777-1.63 1.572v1.887h2.774l-.443 2.91h-2.33V22c4.78-.755 8.437-4.917 8.437-9.939C22 6.504 17.523 2 12 2S2 6.504 2 12.061c0 5.022 3.657 9.184 8.438 9.939Z">
                                                    </path>
                                                  </svg>
                                                </div>
                                              </button>
                                              <button
                                                class="cob-Button cob-Button__default brand-button--whatsapp js_whatsapp_share">
                                                <div class="cobalt-Icon cobalt-Icon--colorInversed">
                                                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                      d="M13.413 13.275c.29-.365.484-.547.58-.547.097 0 .46.163 1.088.49.63.328.962.525 1 .592a.476.476 0 0 1 .022.168c0 .245-.064.528-.19.848-.12.29-.383.534-.792.73-.41.198-.79.297-1.139.297-.424 0-1.13-.231-2.12-.692a6.37 6.37 0 0 1-1.898-1.317c-.535-.543-1.086-1.232-1.652-2.065-.535-.796-.8-1.518-.792-2.165v-.09c.022-.677.298-1.264.826-1.763a.844.844 0 0 1 .58-.245c.045 0 .112.005.201.016.09.012.16.017.212.017.142 0 .24.024.296.073.056.048.114.15.173.307.06.148.182.476.368.982s.28.785.28.837c0 .156-.129.37-.386.642-.256.271-.385.444-.385.518a.32.32 0 0 0 .056.168c.253.543.633 1.053 1.138 1.529.417.394.979.77 1.686 1.127.089.052.17.078.245.078.112 0 .313-.178.603-.535Zm-1.685 5.368a6.819 6.819 0 0 0 2.717-.558 7.069 7.069 0 0 0 2.238-1.496 7.069 7.069 0 0 0 1.496-2.237 6.82 6.82 0 0 0 .558-2.718 6.82 6.82 0 0 0-.558-2.718 7.069 7.069 0 0 0-1.496-2.237 7.068 7.068 0 0 0-2.238-1.496 6.82 6.82 0 0 0-2.717-.558 6.82 6.82 0 0 0-2.718.558 7.068 7.068 0 0 0-2.238 1.496 7.068 7.068 0 0 0-1.495 2.237 6.82 6.82 0 0 0-.558 2.718c0 1.51.446 2.88 1.339 4.107l-.882 2.6 2.701-.859a6.863 6.863 0 0 0 3.85 1.16l.001.001ZM8.458 3.888a8.217 8.217 0 0 1 3.27-.67c1.138 0 2.226.224 3.264.67a8.5 8.5 0 0 1 2.684 1.797 8.5 8.5 0 0 1 1.797 2.684 8.177 8.177 0 0 1 .67 3.265 8.176 8.176 0 0 1-.67 3.264 8.498 8.498 0 0 1-1.797 2.685 8.5 8.5 0 0 1-2.684 1.796 8.176 8.176 0 0 1-3.264.67c-1.451 0-2.81-.35-4.074-1.049L3 20.496l1.518-4.52a8.214 8.214 0 0 1-1.205-4.342c0-1.138.223-2.227.67-3.265a8.5 8.5 0 0 1 1.796-2.684 8.516 8.516 0 0 1 2.679-1.797Z">
                                                    </path>
                                                  </svg>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="cobalt-Card__Section cobalt-Card__Section--divided cobalt-Card__Section--subdued">
                                          <div class="c-text-title-sm c-mb-sm">Or invite friends by email</div>
                                          <p class="c-text-body-md c-text-subdued c-mb-sm">Enter their email addresses,
                                            separated by commas</p>
                                          <form class="js_classic_invitation c-mb-sm" id="new_email_sharing"
                                            action="/email_friends" accept-charset="UTF-8" data-remote="true"
                                            method="post"><input value="referral_invitation" autocomplete="off"
                                              type="hidden" name="email_sharing[email_template]"
                                              id="email_sharing_email_template"/>
                                            <div class="cobalt-ComposedFormField composed_form_field_button--aligned">
                                              <div class="cobalt-FormField" data-form-field-method="emails">
                                                <div class="cobalt-TextField"><input required="required"
                                                    placeholder="batman@gothamcity.com, m.mcfly@thefuture.com"
                                                    class="js_emails_input cobalt-TextField__Input" type="text"
                                                    name="email_sharing[emails]" id="email_sharing_emails"/></div>
                                              </div>
                                              <input type="submit" name="commit" value="Invite"
                                                class="cob-Button cob-Button__default cob-Button__default--primary"
                                                data-disable="true"/>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    class="cob-Button cob-Button__default cob-Button__default--primary js_invite_friends_cta">
                                    <div class="cob-Button__Icon cob-Button__Icon--left referrer__invite_friends_cta">
                                      <span class="cobalt-Icon cobalt-Icon--colorInversed"><svg viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path clip-rule="evenodd"
                                            d="M16.238 7.394h4.368c.206 0 .394.166.394.386v2.207c0 .22-.188.386-.394.386h-7.969V7.449h-1.274v2.924h-7.97A.392.392 0 013 9.987V7.78c0-.22.188-.386.394-.386h4.369c-.638-.092-1.05-.386-1.294-.643-.394-.386-.6-.938-.6-1.508C5.869 4.158 6.675 3 8.156 3c1.069 0 2.213.588 3.413 1.765.072.08.145.156.216.23.075.078.147.154.215.23.131-.147.281-.313.431-.46C13.631 3.588 14.775 3 15.844 3c.675 0 1.275.257 1.706.717.375.405.581.956.581 1.526 0 .57-.206 1.103-.6 1.508-.244.257-.656.551-1.294.643zm-9.094-2.17c0 .46.281.938 1.087.938h2.888c-.806-.9-1.969-1.912-2.981-1.912-.788 0-.994.607-.994.975zm5.719.938c.806-.882 1.968-1.912 2.98-1.912.788 0 .995.607.995.975a.945.945 0 01-.244.643c-.188.202-.469.294-.844.294z"
                                            fill-rule="evenodd"></path>
                                          <path
                                            d="M4.088 20.485c0 .276.243.515.525.515h6.768v-9.395H4.087zM12.656 21h6.75a.531.531 0 00.525-.515v-8.88h-7.275z">
                                          </path>
                                        </svg></span>
                                    </div>
                                    <span>Invite friends</span>

                                  </button>
                                </div>
                                <div class="cobalt-buttons-group__item">
                                  <a class="cob-Button cob-Button__ghost cob-Button__ghost--standalone"
                                    href="/help/articles/72f32c35a12c">Learn more about how referral credits work</a>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>  
)
};

export default Referral;
