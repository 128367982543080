import React from "react";
import character from "../../../../image/png/character_with2.svg"
const PaymentMethod = () => {
  return (
<div class="col-sm-9 col-xs-12">
                <div class="cobalt-Card cobalt-Card--responsive c-mb-sm">
                  <div class="cobalt-Card__Section">
                    <div class="tab_panes_container">
                      <div class="tab_pane active tab_saved_payment_methods">
                        <div class="js_saved-payment-methods-app">
                          <div>
                            <h2 class="c-text-title-md">Payment methods</h2>
                            <div class="c-mt-lg">
                              <div class="cobalt-EmptyState"><img class="cobalt-EmptyState__Image" role="presentation"
                                  alt=""
                                  src={character}
                                  height="188" width="188"/>
                                <h2 class="cobalt-EmptyState__Title">You haven't saved any payment method yet.</h2>
                                <div class="cobalt-EmptyState__Body">You can only add a new payment method when you make
                                  a booking.</div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>  


)
}
export default PaymentMethod;
